import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./index.module.css";

import { storage } from "../../../../../utils/Storage";

import { IRoleType } from "../../../../../models/role";

const CopyWriteFooter: React.FC = () => {
  const roleType = storage.getSessionToken("roleType");
  return (
    <Grid
      item
      className={style.container}
      sx={{
        backgroundColor:
          roleType === IRoleType.PATIENT ? "var(--gray-200)" : "white",
      }}
    >
      <Typography component={"p"}>
        © 2024 The Brain Spine app. All Rights Reserved
      </Typography>
    </Grid>
  );
};

export default CopyWriteFooter;
