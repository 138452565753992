import { INotifyEnum, notify } from "../toaster";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { storage } from "../Storage";
import { addToCartApi } from "../apis/patient/tests";
import useGetCartDetails from "./useGetCartDetails";
import { setStepsTest } from "../../store/slices/patient/tests";

type Props = {};

const useClearCart = () => {
  const token = storage.getSessionToken("token");
  const { getCartDetails, cartDetails } = useGetCartDetails();
  const dispatch = useAppDispatch();
  const cartList = cartDetails?.diagnosticTestIds;
  const diagnosticTestList = useAppSelector((state) => state.tests);
  const { centreDetails } = diagnosticTestList;
  const [selectedTest, setSelectedTest] = useState<string[]>([]);

  const onClearCart = async () => {
    try {
      const clearCartRes = await addToCartApi({
        diagnosticId: cartDetails?.diagnosticId,
        diagnosticTestIds: cartList,
        bookingTimestamp: cartDetails?.bookingTimestamp,
        action: "CLEAR",
      });
      if ("result" in clearCartRes) {
        notify(INotifyEnum.SUCCESS, clearCartRes?.message);
        dispatch(setStepsTest(0));
        getCartDetails();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onCartFun = async (testId: string, action: string) => {
    let updatedCart: string[] = [];

    const updateCart = new Promise<string[]>((resolve) => {
      setSelectedTest((prevCart) => {
        if (prevCart.includes(testId)) {
          updatedCart = prevCart.filter((id) => id !== testId);
        } else {
          updatedCart = [...prevCart, testId];
        }
        resolve(updatedCart);
        return updatedCart;
      });
    });

    updatedCart = await updateCart;

    try {
      const addToCartResponse = await addToCartApi({
        diagnosticId: centreDetails?.user_id || cartDetails?.diagnosticId,
        diagnosticTestIds: updatedCart,
        bookingTimestamp:
          parseInt(centreDetails?.selectedDate) ||
          cartDetails?.bookingTimestamp,
        action: action,
      });

      if ("result" in addToCartResponse) {
        notify(INotifyEnum.SUCCESS, addToCartResponse?.message);
        getCartDetails();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    } else {
      getCartDetails();
    }
  }, []);

  useEffect(() => {
    if (cartList) {
      setSelectedTest(cartList);
    }
  }, [cartList]);

  return { onClearCart, selectedTest, onCartFun };
};

export default useClearCart;
