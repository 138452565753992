import React, { useCallback } from "react";
import { Grid, Typography } from "@mui/material";

import GetImages from "../../../../components/GetImages";
import Modal from "../../../../components/Modal";
import Dialog from "../../../../components/Dialog";

import style from "../index.module.css";

import { IClinic } from "../../../../models/ApiRequestResponse/patient";

import { openClinicMapLocation } from "../../../../utils";
import useIsMobile from "../../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../../utils/hooks/useIsTablet";

interface IProps {
  isOpen: boolean;
  closeModalHandler: () => void;
  clinics: IClinic[];
}
const ViewClinics: React.FC<IProps> = (props) => {
  const { isOpen, closeModalHandler, clinics } = props;

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const ViewDoctorClinicsScreen = useCallback(
    () => (
      <Grid item className={style.viewMoreClinicContainer}>
        <Typography component={"h4"}>Select a clinic</Typography>
        {clinics.map((clinic, index) => (
          <Grid item className={style.clinicInfoWrapper} key={clinic.id}>
            <Grid item className={style.clinicDetailContainer}>
              <Grid item>
                <Typography component={"h6"} className={style.clinicName}>
                  {clinic.name}
                </Typography>
                <Typography component={"p"} className={style.priceBranch}>
                  ₹{clinic.consultation_fee} • {clinic.address}, {clinic.city}-
                  {clinic.pincode}
                </Typography>
              </Grid>
              <Grid
                item
                className={style.directionIcon}
                onClick={() =>
                  openClinicMapLocation(clinic.latitude, clinic.longitude)
                }
              >
                <GetImages
                  name="LocationAccessSmallIcon"
                  width="20"
                  height="20"
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    ),
    []
  );
  return (
    <>
      {isMobile || isTablet ? (
        <Modal
          isOpen={isOpen}
          closeHandler={closeModalHandler}
          backgroundColor="white"
          lineBottomBackground="white"
        >
          {ViewDoctorClinicsScreen()}
        </Modal>
      ) : (
        <Dialog
          fullWidth
          maxWidth="xs"
          isOpen={isOpen}
          closeHandler={closeModalHandler}
        >
          {ViewDoctorClinicsScreen()}
        </Dialog>
      )}
    </>
  );
};

export default ViewClinics;
