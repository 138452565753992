import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import style from "./index.module.css";

import GetImages from "../GetImages";

import { IAddClinicTeamMemberEnums } from "../../models";
import { IDoctorAssociatedClinicMemberProfile } from "../../models/ApiRequestResponse/doctor";

import { transformClasses } from "../../utils";
import { clinicTeamList } from "../../utils/common";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setAddToCallMember } from "../../store/slices/videoAppointment";

interface IProps {
  type: IAddClinicTeamMemberEnums;
}
const AddClinicTeamPatientCard: React.FC<IProps> = (props) => {
  const { type } = props;

  const [list, setList] = useState<IDoctorAssociatedClinicMemberProfile[]>([]);

  const dispatch = useAppDispatch();
  const { clinicTeams, searchedMember } = useAppSelector(
    (state) => state.doctorProfile
  );
  const { addToCallMember } = useAppSelector((state) => state.videoAppointment);

  const getMemberClinicTeamList = () => {
    switch (type) {
      case IAddClinicTeamMemberEnums.STATIC:
        setList(clinicTeamList);
        return;
      case IAddClinicTeamMemberEnums.ASSOCIATED_CLINICAL_TEAM:
        setList(clinicTeams);
        return;
      case IAddClinicTeamMemberEnums.SEARCHED_MEMEBER:
        setList(searchedMember);
        return;
      default:
        return null;
    }
  };

  const resetCardSelection = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setAddToCallMember(null));
  };

  useEffect(() => {
    getMemberClinicTeamList();
  }, [type, clinicTeams, searchedMember]);
  return (
    <Grid item className={style.container}>
      {list.map((team, index) => (
        <Grid
          item
          key={`${team.name}-${index}`}
          className={transformClasses(
            style.card,
            addToCallMember?.userId === team.userId ? style.selected : ""
          )}
          onClick={() => dispatch(setAddToCallMember(team))}
        >
          {addToCallMember?.userId === team.userId && (
            <Grid
              item
              className={style.removeSelection}
              onClick={resetCardSelection}
            >
              <GetImages name="RemoveSelectionIcon" width="12" height="12" />
            </Grid>
          )}
          <img src={team.profilePhoto} alt={team.name} width={40} height={40} />
          <Grid item className={style.name}>
            <Typography component={"p"}>{team.name}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default AddClinicTeamPatientCard;
