import React, { useCallback } from "react";
import { Grid, Typography } from "@mui/material";

import GetImages from "../../GetImages";

import style from "./index.module.css";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  removeMedicalRecordIds,
  saveSelectedOrder,
} from "../../../store/slices/pharmacyClinicDiagnostic";

import useHandlePharmacyOrderUpdate from "../../../utils/hooks/useHandlePharmacyOrderUpdate";
import { getMedicalReportDocumentName } from "../../../utils";

const Reports: React.FC = () => {
  const { order, medicalRecordIds } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );

  const { handlePharmacyOrderUpdate } = useHandlePharmacyOrderUpdate();
  const dispatch = useAppDispatch();

  const removeReport = useCallback(
    (reportId: string) => {
      dispatch(removeMedicalRecordIds(reportId));
      handlePharmacyOrderUpdate(
        order?.status,
        null,
        medicalRecordIds.filter((id) => id !== reportId)
      );
      dispatch(
        saveSelectedOrder({
          ...order,
          reports: order?.reports?.filter(
            (report: any) => report?.id !== reportId
          ),
        })
      );
    },
    [medicalRecordIds]
  );

  return (
    <Grid item className={style.container}>
      <Typography component={"h4"} className={style.title}>
        Reports
      </Typography>
      <Grid item className={style.testReportContainer}>
        {order?.reports?.map((report: any, index: number) => (
          <Grid item className={style.pdfContainer} key={`${report}-${index}`}>
            <GetImages name="PDFGreyIcon" width="24" height="24" />
            <Typography component={"p"}>
              {getMedicalReportDocumentName(report?.url!)}
            </Typography>
            <Grid
              item
              className={style.delete}
              onClick={() => removeReport(report?.id)}
            >
              <GetImages
                name="DiagnosticTestRecordDeleteIcon"
                width="12"
                height="10"
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Reports;
