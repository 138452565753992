import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { randomCardColorGenerator } from "../../../utils";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAssessmentHealthQuestionList,
  resetAssessmentState,
} from "../../../store/slices/patient/healthAssessment";

interface IProps {
  name: string;
  imageUrl: string;
  uniqueId?: string;
}
const HealthSymptomsCard: React.FC<IProps> = (props) => {
  const { name, imageUrl, uniqueId } = props;
  const {
    detail: { symptoms },
  } = useAppSelector((state) => state.home);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const appendQueryParam = () => {
    if (!uniqueId) {
      const specialityType = symptoms
        .find((item) => item.name === name)
        ?.speciality.at(0)?.speciality.speciality;
      if (specialityType) {
        navigate(`/find-doctor?type=${specialityType}`);
      } else {
        navigate(`/find-doctor`);
      }
    } else {
      const params = new URLSearchParams(searchParams);
      params.set("assessmentId", uniqueId!);
      params.set("type", name);
      setSearchParams(params);
      dispatch(resetAssessmentState());
      dispatch(
        fetchAssessmentHealthQuestionList({
          healthAssessmentId: uniqueId!,
        })
      );
    }
  };

  return (
    <Grid
      item
      sx={{
        backgroundColor: randomCardColorGenerator(),
        textAlign: "center",
        cursor: "pointer",
        borderRadius: {
          xs: "0.8rem",
          sm: "0.8rem",
          md: "1.6rem",
          lg: "1.6rem",
        },
        padding: {
          xs: "0.4rem 0.4rem 0.8rem 0.2rem",
          sm: "0.4rem 0.4rem 0.8rem 0.2rem",
          md: "1.2rem 1.6rem 1.6rem 1.6rem",
          lg: "1.2rem 1.6rem 1.6rem 1.6rem",
        },
        "& > p": {
          fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.4rem", lg: "1.4rem" },
          marginTop: "0.4rem'",
          color: "var(--black-900)",
          textAlign: "center",
          fontWeight: "400",
          lineHeight: "normal",
          textTransform: "capitalize",
        },
      }}
      onClick={appendQueryParam}
    >
      <Box
        component="img"
        src={imageUrl}
        alt="no image"
        sx={{
          width: {
            xs: "4rem",
            sm: "4rem",
            md: "9.6rem",
            lg: "9.6rem",
            xl: "9.6rem",
          },
          height: {
            xs: "4rem",
            sm: "4rem",
            md: "5rem",
            lg: "9.6rem",
            xl: "9.6rem",
          },
        }}
      />
      <Typography component={"p"}>{name}</Typography>
    </Grid>
  );
};

export default HealthSymptomsCard;
