import React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import style from "./index.module.css";

import SectionHeader from "../SectionHeader";
import BlogCard from "./BlogCard";
import ScrollContainer from "../ScrollContainer";
import SectionContainer from "../SectionContainer";

import { IHealthHubBlogsResult } from "../../../../models/ApiRequestResponse/patient";
type BlogListSectionProps = {
  data: IHealthHubBlogsResult;
  isFavouriteCategory?: boolean;
  isRelated?: boolean;
};

const BlogListSection: React.FC<BlogListSectionProps> = ({
  data,
  isFavouriteCategory,
  isRelated,
}) => {
  const navigate = useNavigate();

  const intl = useIntl();

  const handleNavigate = () => {
    navigate("/health-hub/blog-list", {
      state: {
        id: data.id,
      },
    });
  };

  return (
    <SectionContainer isSection={!isRelated}>
      <Grid
        className={style.outerContainer}
        sx={{
          padding: { xs: "0 0.5rem", sm: "0.2rem 0" },
          ml: { xs: "1rem", sm: "4rem", md: "11rem" },
          maxWidth: { xs: "95%", sm: "100%" },
        }}
      >
        <Grid
          className={style.headerContainer}
          sx={{ width: { xs: "100%", sm: "95%" } }}
        >
          <SectionHeader
            title={isFavouriteCategory ? "Favourites Blogs" : data.name}
          />
          <Typography
            onClick={handleNavigate}
            variant="body1"
            className={style.viewAllText}
          >
            {intl.formatMessage({
              id: "view_all",
              defaultMessage: "View All",
            })}
          </Typography>
        </Grid>
        <ScrollContainer>
          {data.data.map((blog) => (
            <BlogCard key={blog.id} blog={blog} />
          ))}
        </ScrollContainer>
      </Grid>
    </SectionContainer>
  );
};

export default BlogListSection;
