import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import Modal from "../../Modal";

import style from "./index.module.css";

import Select from "../../Select";
import Button from "../../Button";
import GetImages from "../../GetImages";

import { IBed } from "../../../models/ApiRequestResponse/doctor";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchHospitalRoomsListing } from "../../../store/slices/doctor/ipDashboard";

import {
  calculateDayFromTargetDate,
  transformDateToDDMMYYYY,
} from "../../../utils";
import useUpdateAdmitPatient from "../../../utils/hooks/useUpdateAdmitPatient";

interface IRoomNumber {
  roomId: string;
  roomBedId: string;
}
interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  closeHandlerParentModal: () => void;
}
const ShiftBed: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler, closeHandlerParentModal } = props;

  const [bedNumber, setBedNumber] = useState<IBed[]>([]);

  const { updateAdmitPatientHandler } = useUpdateAdmitPatient();

  const { rooms, patientDetail, selectedHospitalId } = useAppSelector(
    (state) => state.ipDashboard
  );
  const dispatch = useAppDispatch();
  const { id, name, admission_date, room_bed_id, hospital_room_id } =
    patientDetail;

  const [roomNumber, setRoomNumber] = useState<IRoomNumber>({
    roomId: hospital_room_id || "",
    roomBedId: "",
  });

  const icuWardSelectionHandler = (roomId: string) => {
    const room = rooms?.allRooms?.filter(
      (room, index) => room.id === roomId
    )[0];
    setRoomNumber({
      ...roomNumber,
      roomId: room.id,
      ...(hospital_room_id?.length !== 0 &&
        room_bed_id?.length !== 0 && { roomBedId: room_bed_id }),
    });
    setBedNumber(room?.beds);
  };

  const roomBedNumberHandler = (roomBedId: string) =>
    setRoomNumber({ ...roomNumber, roomBedId });

  const shiftBedHandler = () => {
    updateAdmitPatientHandler({
      id,
      hospital_room_id: roomNumber?.roomId,
      room_bed_id: roomNumber?.roomBedId,
    });
    closeHandler();
    closeHandlerParentModal();
  };

  const getAvailableRooms = () => {
    dispatch(
      fetchHospitalRoomsListing({
        hospitalId: selectedHospitalId,
        available: true,
      })
    );
  };

  useEffect(() => {
    if (roomNumber?.roomId?.length !== 0) {
      icuWardSelectionHandler(roomNumber.roomId);
    }
  }, [hospital_room_id, rooms]);
  return (
    <Modal isOpen={isOpen} closeHandler={closeHandler}>
      <Grid item className={style.container}>
        <Grid item className={style.header} onClick={closeHandler}>
          <ArrowBackIosNewIcon fontSize={"inherit"} />{" "}
          <Typography component={"p"}>Shift Bed</Typography>
        </Grid>
        <Grid item className={style.patientDetail}>
          <Grid item>
            <Typography component={"p"} className={style.name}>
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component={"p"} className={style.admittedDateDuration}>
              <GetImages name="GreenDotIcon" width="8" height="6" />
              {transformDateToDDMMYYYY(admission_date)}(
              {calculateDayFromTargetDate(admission_date)})
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={style.icuWardContainer}>
          <Grid item>
            <Select
              value={roomNumber?.roomId}
              label="ICU/ Ward"
              data={rooms?.allRooms?.map((room) => ({
                key: room.name,
                value: room.id,
              }))}
              receivedSelectedValue={icuWardSelectionHandler}
            />
          </Grid>
          <Grid item className={style.changeBed}>
            <Select
              value={roomNumber?.roomBedId}
              label="Bed Number"
              data={bedNumber.map((bed, index) => ({
                key: `Bed ${bed.number}`,
                value: bed.id,
              }))}
              onOpen={getAvailableRooms}
              receivedSelectedValue={roomBedNumberHandler}
            />
          </Grid>
        </Grid>
        <Button btnTrigger={shiftBedHandler}>Save</Button>
      </Grid>
    </Modal>
  );
};

export default ShiftBed;
