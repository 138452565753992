import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { universalSearchStyles } from "./style";

import GetImages from "../../../components/GetImages";
import ChipSelect from "../../../components/ChipSelect";
import IPDashboardCard from "../../../components/IPDashboard/IPDashboardCard";
import AppointmentDetailsView from "../../../components/AppointmentDetails/AppointmentDetailsView";
import DiagnosticList from "../../../components/DiagnosticList";
import FullScreenDialog from "../../../components/FullScreenDialog";
import DiagnosticTestList from "../../../components/DiagnosticTestList";
import OrderModals from "../../PharmacyClinicDiagnostic/OrderModals";
import HealthHubSearchList from "../../Patient/HealthHub/HealthHubSearchList";
import FindDoctorCard from "../../../components/FindDoctorCard";
import MedicineCard from "../../../components/AllOrders/MedicineCard";
import AppointmentCard from "../../../components/AllOrders/AppointmentCard";
import TestBookingCard from "../../../components/AllOrders/TestBookingCard";
import OrderCard from "../../PharmacyClinicDiagnostic/OrderCard";
import { InputField } from "../../../components/InputField";

import {
  IOrderDateRangeParams,
  IUniversalSearchEnum,
  IUniversalSearchResultCounts,
} from "../../../models/doctor";
import { IChipVariant } from "../../../models/button";
import { IAppointments } from "../../../models/ApiRequestResponse/doctor";
import { IRoleType } from "../../../models/role";
import { IPharmacyDiagnosticOrderDetail } from "../../../models/ApiRequestResponse/diagnostic";

import useDebounce from "../../../utils/hooks/useDebouncer";
import {
  getDateFormat,
  getLocation,
  patientChipSearchList,
  pharmacyChipSearchList,
  doctorChipSearchList,
  universalSearchType,
  diagnosticChipSearchList,
} from "../../../utils";
import { storage } from "../../../utils/Storage";
import { INotifyEnum, notify } from "../../../utils/toaster";
import usePagination from "../../../utils/hooks/usePagination";
import useOrderLists from "../../../utils/hooks/useOrderLists";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDoctorHospitalListing,
  fetchDoctorHospitalPatientListing,
  resetAdmitPatients,
} from "../../../store/slices/doctor/ipDashboard";
import {
  fetchSeachedAppointmentList,
  resetSearchAppointments,
} from "../../../store/slices/doctor/schedule";
import {
  fetchDiagnosticTestOrdersList,
  fetchMoreOrders,
  fetchPharmacyOrdersList,
  saveSelectedOrder,
} from "../../../store/slices/pharmacyClinicDiagnostic";
import {
  fetchDiagnosticCenterList,
  fetchDiagnosticTestList,
} from "../../../store/slices/patient/tests";
import { setIsShowUniversalSearch } from "../../../store/slices/config";
import {
  resetHealthHubSearch,
  searchHealthHubData,
} from "../../../store/slices/patient/healthHub";
import { fetchFindDoctor } from "../../../store/slices/patient/findDoctor";
import { fetchAllOrdersList } from "../../../store/slices/patient/home";

const UniversalSearch: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState<
    IUniversalSearchEnum | string
  >("");
  const [search, setSearch] = useState<string>("");

  const dispatch = useAppDispatch();
  const { selectedHospitalId } = useAppSelector((state) => state.ipDashboard);
  const { searchAppointmentsList } = useAppSelector((state) => state.schedule);
  const { list: findDoctorList } = useAppSelector((state) => state.findDoctor);
  const { allOrders } = useAppSelector((state) => state.home);
  const { list: pharmacyOrderSearchList } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );
  const { centerList, centreDetails, testList } = useAppSelector(
    (state) => state.tests
  );
  const { searchData } = useAppSelector((state) => state.healthHub);
  const { hospitalPatients } = useAppSelector((state) => state.ipDashboard);

  const { appointmentList, medicineOrderList, testBookingList } =
    useOrderLists(allOrders);

  const calculateSearchResultCount = () => {
    const healthHubResultCount =
      (searchData?.result?.playlist?.length || 0) +
      (searchData?.result?.video?.length || 0);
    return {
      [IUniversalSearchEnum.APPOINTMENTS]: searchAppointmentsList?.length,
      [IUniversalSearchEnum.PHARMACY]: pharmacyOrderSearchList?.length,
      [IUniversalSearchEnum.FIND_DOCTORS]: findDoctorList?.length,
      [IUniversalSearchEnum.TEST]: testList?.length,
      [IUniversalSearchEnum.DIAGNOSTIC]: centerList?.length,
      [IUniversalSearchEnum.HEALTH_HUB]: healthHubResultCount ?? 0,
      [IUniversalSearchEnum.CLINICAL_TEAM]: 0,
      [IUniversalSearchEnum.CLINIC]: searchAppointmentsList?.length,
      [IUniversalSearchEnum.ORDERS]: allOrders?.length,
      [IUniversalSearchEnum.IN_PATIENTS]: hospitalPatients?.length,
      [IUniversalSearchEnum.DIAGNOSTIC_CENTER]: pharmacyOrderSearchList?.length,
    };
  };

  const searchResultCounter = calculateSearchResultCount();
  const [searchResultCounts, setSearchResultCount] =
    useState<IUniversalSearchResultCounts | null>(searchResultCounter);

  const previousResultCountRef = useRef<any>(null);

  const today = new Date();
  const day = getDateFormat(today.toDateString(), "ddd");
  const debouncedSearch = useDebounce(search, 500);
  const roleType = storage.getSessionToken("roleType");
  const locationVal = getLocation();
  const [openModal, setOpenModal] = useState(false);
  const { handleFetchNext, handleResetOffset } =
    usePagination<IOrderDateRangeParams>({
      fetchFn: fetchPharmacyOrdersList,
      fetchNextFn: fetchMoreOrders,
    });

  const {
    handleFetchNext: handleFetchHealthHubNextData,
    handleFetch: handleFetchHealthHubData,
    offset,
  } = usePagination<any>({
    fetchFn: searchHealthHubData,
    fetchNextFn: searchHealthHubData,
  });

  const closeUniversalSearchHandler = () => {
    handleResetOffset();
    dispatch(saveSelectedOrder(null));
    dispatch(setIsShowUniversalSearch(false));
    setSearchResultCount(null);
  };

  const patientAppointmentsScreen = useCallback(() => {
    return (
      <Box
        sx={{
          ...universalSearchStyles.cardContainer,
          ...universalSearchStyles.pharmacyOrDiagnosesCardContainer,
        }}
      >
        {searchAppointmentsList?.map(
          (appointments: IAppointments, index: number) => (
            <AppointmentDetailsView
              key={index}
              {...appointments}
              isMobile={false}
            />
          )
        )}
      </Box>
    );
  }, [searchAppointmentsList]);

  const universalSearchHandler = () => {
    switch (selectedValue) {
      case IUniversalSearchEnum.APPOINTMENTS:
      case IUniversalSearchEnum.CLINIC:
        dispatch(fetchSeachedAppointmentList(search));
        return;
      case IUniversalSearchEnum.DIAGNOSTIC_CENTER:
        dispatch(fetchDiagnosticTestOrdersList({ keyword: search }));
        return;
      case IUniversalSearchEnum.IN_PATIENTS:
        dispatch(
          fetchDoctorHospitalPatientListing({
            hospitalId: selectedHospitalId,
            keyword: search,
          })
        );
        return;
      case IUniversalSearchEnum.CLINICAL_TEAM:
        return null;
      case IUniversalSearchEnum.PHARMACY:
        return dispatch(
          fetchPharmacyOrdersList({
            isPending: false,
            keyword: search,
          })
        );
      case IUniversalSearchEnum.FIND_DOCTORS:
        const locationAttriutes = `&latitude=${locationVal?.latitude}&longitude=${locationVal?.longitude}`;
        dispatch(fetchFindDoctor(`${locationAttriutes}&search=${search}`));
        return;
      case IUniversalSearchEnum.ORDERS:
        dispatch(fetchAllOrdersList({ orderType: "", keyword: search }));
        return;
      case IUniversalSearchEnum.DIAGNOSTIC:
        const requestDiagnosticData = {
          latitude: locationVal?.latitude || 28.610802,
          longitude: locationVal?.longitude || 77.371909,
          day: day,
          search: search,
        };
        dispatch(fetchDiagnosticCenterList(requestDiagnosticData));
        return;
      case IUniversalSearchEnum.TEST:
        if (centreDetails?.user_id) {
          const requestTestData = {
            diagnosticId: centreDetails && centreDetails?.user_id,
            search: search,
          };
          dispatch(fetchDiagnosticTestList(requestTestData));
        }
        return;
      case IUniversalSearchEnum.HEALTH_HUB:
        handleFetchHealthHubData({ searchTerm: search, offset: 1 });
        return;
      default:
        return null;
    }
  };

  const universalSearchResetHandler = () => {
    switch (selectedValue) {
      case IUniversalSearchEnum.APPOINTMENTS:
      case IUniversalSearchEnum.CLINIC:
        dispatch(resetSearchAppointments());
        return;
      case IUniversalSearchEnum.IN_PATIENTS:
        dispatch(resetAdmitPatients());
        return;
      case IUniversalSearchEnum.CLINICAL_TEAM:
        return null;
      case IUniversalSearchEnum.DIAGNOSTIC:
        const requestData = {
          latitude: locationVal?.latitude || 28.610802,
          longitude: locationVal?.longitude || 77.371909,
          day: day,
        };
        dispatch(fetchDiagnosticCenterList(requestData));
        return;
      case IUniversalSearchEnum.TEST:
        if (centreDetails?.user_id) {
          const requestTestData = {
            diagnosticId: centreDetails && centreDetails?.user_id,
          };
          dispatch(fetchDiagnosticTestList(requestTestData));
        }
        return;
      case IUniversalSearchEnum.HEALTH_HUB:
        dispatch(resetHealthHubSearch());
        return;
      default:
        return null;
    }
  };

  const searchList = useMemo(() => {
    const handlePharmacyOrDiagnosticOrder = (onLoadMore: () => void) => (
      <Box
        sx={{
          ...universalSearchStyles.cardContainer,
          ...universalSearchStyles.pharmacyOrDiagnosesCardContainer,
        }}
      >
        {pharmacyOrderSearchList?.length > 0
          ? pharmacyOrderSearchList?.map(
              (order: IPharmacyDiagnosticOrderDetail, index: number) => (
                <OrderCard
                  {...order}
                  key={`order-${index}`}
                  onSelect={(order) => {
                    setOpenModal((state) => !state);
                    dispatch(saveSelectedOrder(order));
                  }}
                />
              )
            )
          : null}
      </Box>
    );

    switch (roleType) {
      case IRoleType.DOCTOR:
        switch (selectedValue) {
          case IUniversalSearchEnum.APPOINTMENTS:
            return <>{patientAppointmentsScreen()}</>;
          case IUniversalSearchEnum.IN_PATIENTS:
            return (
              <Box
                sx={{
                  ...universalSearchStyles.cardContainer,
                  ...universalSearchStyles.pharmacyOrDiagnosesCardContainer,
                }}
              >
                <IPDashboardCard />
              </Box>
            );
          default:
            return null;
        }

      case IRoleType.PATIENT:
        switch (selectedValue) {
          case IUniversalSearchEnum.FIND_DOCTORS:
            return (
              <>
                {findDoctorList?.length > 0 ? (
                  <Box sx={universalSearchStyles.cardContainer}>
                    {findDoctorList?.map((list: any, index: number) => (
                      <FindDoctorCard
                        {...list}
                        currentIndex={index}
                        key={index}
                      />
                    ))}
                  </Box>
                ) : null}
              </>
            );
          case IUniversalSearchEnum.ORDERS:
            return (
              <>
                {allOrders?.length > 0 ? (
                  <Grid item xs={12} lg={5}>
                    <Stack
                      justifyContent={"space-around"}
                      p={{ lg: 2 }}
                      maxHeight={"90vh"}
                      overflow={"scroll"}
                      sx={universalSearchStyles.cardContainer}
                    >
                      {medicineOrderList.map((order, index) => (
                        <MedicineCard {...order} key={`order-${index}`} />
                      ))}
                      {appointmentList.map((order, index) => (
                        <AppointmentCard {...order} key={`order-${index}`} />
                      ))}
                      {testBookingList.map((order, index) => (
                        <TestBookingCard {...order} key={`order-${index}`} />
                      ))}
                    </Stack>
                  </Grid>
                ) : null}
              </>
            );
          case IUniversalSearchEnum.DIAGNOSTIC:
            return centerList?.length > 0 ? <DiagnosticList /> : null;

          case IUniversalSearchEnum.HEALTH_HUB:
            return (
              <HealthHubSearchList
                onCardClick={() => dispatch(setIsShowUniversalSearch(false))}
                onLoadMore={() =>
                  handleFetchHealthHubNextData({
                    searchTerm: search,
                    offset: offset + 1,
                  })
                }
              />
            );

          case IUniversalSearchEnum.TEST:
            return centreDetails?.user_id ? (
              <DiagnosticTestList />
            ) : (
              notify(INotifyEnum.ERROR, "Please first select diagnostic center")
            );

          default:
            return null;
        }
      case IRoleType.PHARMACY:
        switch (selectedValue) {
          case IUniversalSearchEnum.PHARMACY:
            return handlePharmacyOrDiagnosticOrder(() => handleFetchNext({}));
          case IUniversalSearchEnum.CLINIC:
            return <>{patientAppointmentsScreen()}</>;
          default:
            return null;
        }
      case IRoleType.DIAGNOSTIC_CENTER:
        return handlePharmacyOrDiagnosticOrder(() => handleFetchNext({}));
      default:
        return null;
    }
  }, [
    roleType,
    searchAppointmentsList,
    centerList,
    selectedValue,
    offset,
    search,
    pharmacyOrderSearchList,
    findDoctorList,
    allOrders,
  ]);

  const chipList = useMemo(() => {
    return roleType === IRoleType.DOCTOR
      ? doctorChipSearchList
      : roleType === IRoleType.PHARMACY
      ? pharmacyChipSearchList
      : roleType === IRoleType.DIAGNOSTIC_CENTER
      ? diagnosticChipSearchList
      : patientChipSearchList;
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      universalSearchHandler();
    }
    if (search.length === 0) {
      universalSearchResetHandler();
    }
  }, [debouncedSearch, selectedValue, search]);

  useEffect(() => {
    if (
      selectedValue === IUniversalSearchEnum.IN_PATIENTS &&
      selectedHospitalId?.length === 0
    ) {
      dispatch(fetchDoctorHospitalListing());
    }
  }, [selectedValue]);

  useEffect(() => {
    const defaultValue =
      roleType === IRoleType.DOCTOR
        ? doctorChipSearchList[0]?.value
        : roleType === IRoleType.PHARMACY
        ? pharmacyChipSearchList[0]?.value
        : roleType === IRoleType.DIAGNOSTIC_CENTER
        ? diagnosticChipSearchList[0]?.value
        : patientChipSearchList[0]?.value;
    setSelectedValue(defaultValue);
  }, [roleType]);

  useEffect(() => {
    if (
      searchAppointmentsList.length > 0 ||
      centerList.length > 0 ||
      allOrders.length > 0 ||
      pharmacyOrderSearchList.length > 0 ||
      findDoctorList.length > 0 ||
      testList.length > 0 ||
      hospitalPatients.length > 0 ||
      searchData?.result?.length > 0 ||
      0
    ) {
      const newResultCount = calculateSearchResultCount();
      if (
        JSON.stringify(newResultCount) !==
        JSON.stringify(previousResultCountRef.current)
      ) {
        setSearchResultCount((prevObject) => ({
          ...prevObject,
          ...newResultCount,
        }));
        previousResultCountRef.current = newResultCount;
      }
    }
  }, [
    searchAppointmentsList,
    centerList,
    allOrders,
    pharmacyOrderSearchList,
    findDoctorList,
    testList,
    hospitalPatients,
    searchData,
  ]);

  return (
    <>
      <FullScreenDialog>
        <Grid item sx={universalSearchStyles.container}>
          {/* header desktop & tablet */}
          <Box sx={universalSearchStyles.searchHeaderContainer}>
            <input
              value={search}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearch(event.target.value)
              }
              placeholder={`Search ${universalSearchType[selectedValue]} here`}
              autoFocus
            />
            <Grid item sx={universalSearchStyles.searchIcon}>
              <GetImages name="NavSearchIcon" width="24" height="24" />
            </Grid>
            <Grid item sx={universalSearchStyles.closeIconContainer}>
              <IconButton
                sx={universalSearchStyles.closeButton}
                onClick={closeUniversalSearchHandler}
              >
                <GetImages
                  name="UniSearchDesktopCloseIcon"
                  width="20"
                  height="20"
                />
              </IconButton>
            </Grid>
          </Box>
          {/* header mobile */}
          <Box sx={universalSearchStyles.searchMobileContainer}>
            <InputField
              fullWidth
              placeholder="Type here"
              value={search}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearch(event.target.value)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {search.length > 0 ? (
                      <CloseIcon
                        sx={universalSearchStyles.searchCloseMobile}
                        onClick={closeUniversalSearchHandler}
                      />
                    ) : (
                      <GetImages name="NavSearchIcon" width="24" height="24" />
                    )}
                  </InputAdornment>
                ),
              }}
              autoFocus
            />
          </Box>
          <Grid item sx={universalSearchStyles.chipSelectContainer}>
            <ChipSelect
              variant={IChipVariant.ROUNDED}
              data={chipList}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              showCarousel
            />
          </Grid>
          <Grid item sx={universalSearchStyles.searchedResultContainer}>
            {searchResultCounts && (
              <Typography
                component={"p"}
                sx={universalSearchStyles.searchResultCount}
              >
                {
                  searchResultCounts[
                    selectedValue as keyof IUniversalSearchResultCounts
                  ]
                }{" "}
                records
              </Typography>
            )}
            {searchList}
          </Grid>
        </Grid>
      </FullScreenDialog>
      <OrderModals isOpen={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default UniversalSearch;
