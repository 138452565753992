import React from "react";
import { Stack, Button, Grid, Typography, Box } from "@mui/material";

import style from "./index.module.css";

import GetImages from "../../../components/GetImages";

import { IPharmacyDiagnosticOrderDetail } from "../../../models/ApiRequestResponse/diagnostic";
import { IOrderStatusEnum } from "../../../models/ApiRequestResponse/pharmacy";

import {
  capitalizeFirstLetter,
  convertTimestampToDDMMM,
  getMedicalReportDocumentName,
} from "../../../utils";

interface IOnSelect {
  onSelect?: (order: IPharmacyDiagnosticOrderDetail) => void;
}
type IProps = IOnSelect & IPharmacyDiagnosticOrderDetail;
const OrderCard: React.FC<IProps> = (props) => {
  const {
    id,
    name,
    tracking_id,
    reports,
    type,
    booking_timestamp,
    status,
    onSelect,
  } = props;
  return (
    <Stack
      component={Button}
      onClick={() => onSelect && onSelect(props)}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      className={style.card}
      key={`${tracking_id}-${id}`}
    >
      <Stack direction={"column"}>
        <Typography variant="h6" className={style.consumerName}>
          {name}
        </Typography>
        {status === IOrderStatusEnum.UPLOADED ? (
          <Grid item>
            <Stack
              direction={"column"}
              alignItems={"center"}
              maxHeight={"10rem"}
              overflow={"hidden"}
              gap={1}
            >
              {reports &&
                reports?.length > 0 &&
                reports?.map((report: any, index: number) => (
                  <Box
                    component={Stack}
                    direction={"row"}
                    alignItems={"center"}
                    borderRadius={"1.8rem"}
                    bgcolor={"var(--gray-50)"}
                    p={1}
                    key={`${report?.id}-${index}`}
                  >
                    <GetImages name="PDFGreyIcon" width="24" height="24" />
                    <Typography component={"p"} noWrap sx={{ width: "15ch" }}>
                      {getMedicalReportDocumentName(report?.url!)}
                    </Typography>
                  </Box>
                ))}
            </Stack>
          </Grid>
        ) : (
          <Typography variant="subtitle2" className={style["text-gray"]}>
            {capitalizeFirstLetter(
              type ??
                `Order Date ${convertTimestampToDDMMM(
                  booking_timestamp,
                  "DD MMM YYYY"
                )}`
            )}
          </Typography>
        )}
      </Stack>
      <Stack direction={"column"}>
        {status !== IOrderStatusEnum.CONFIRMED && (
          <Typography
            component={"p"}
            className={`${style.orderStatus} ${
              status === IOrderStatusEnum.COMPLETED ||
              status === IOrderStatusEnum.UPLOADED
                ? style.success
                : style.rejected
            }`}
          >
            {capitalizeFirstLetter(status ?? "")}
          </Typography>
        )}
        <Typography component={"p"} className={style.orderType}>
          {tracking_id}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default OrderCard;
