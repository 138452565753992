import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ISaveTypePrescription } from "../../models/doctor";
import {
  IModalKeyEnums,
  IPrescriptionPermissionEnums,
  IRolePermissionAccess,
} from "../../models/permission";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setIsVideoMinimizedOrPrescInitiated } from "../../store/slices/videoAppointment";
import { setShowPermissionAlert } from "../../store/slices/permission";
import { setPrescriptionTemplateValues } from "../../store/slices/doctor/prescription";
import {
  createPrescriptionApi,
  saveMedicalRecordApi,
  updatePrescriptionApi,
} from "../apis/doctor/prescription";

import { INotifyEnum, notify } from "../toaster";

import { filteredNullEmptyArrayData, getPermissionAccess } from "..";
import { setAppointmentDetail } from "../../store/slices/doctor/appointment";

const usePrescriptionCreate = () => {
  const [isSavePrescriptionLoading, setIsSavePrescriptionLoading] =
    useState<boolean>(false);
  const [isEndPrescriptionLoading, setIsEndPrescriptionLoading] =
    useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [showSavePrescription, setShowSavePrescription] =
    useState<boolean>(false);
  const [sharePrescription, setSharePrescription] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const prescription = useAppSelector((state) => state.prescription);
  const appointmentDetail = useAppSelector(
    (state) => state.appointment.appointmentDetail
  );
  const { isVideoCallInitiated } = useAppSelector(
    (state) => state.videoAppointment
  );
  const { permission } = useAppSelector((state) => state.rolePermission);

  const openCloseSharePrescriptionModal = () =>
    setSharePrescription(!sharePrescription);

  const openCloseSavePrescriptionModal = () =>
    setShowSavePrescription(!showSavePrescription);

  const handleCreatePrescriptionHandler = async (
    saveTypePrescription: ISaveTypePrescription,
    execute: boolean
  ) => {
    if (
      saveTypePrescription === ISaveTypePrescription.SAVE_PRESCRIPTION &&
      !showSavePrescription
    ) {
      openCloseSavePrescriptionModal();
      return;
    }
    if (saveTypePrescription === ISaveTypePrescription.SAVE_PRESCRIPTION) {
      setIsSavePrescriptionLoading(true);
    } else {
      setIsEndPrescriptionLoading(true);
    }
    const medicine = prescription?.create?.medicine?.map(({ id, ...rest }) => ({
      ...rest,
    }));
    const test = prescription?.create?.test?.map(({ id, ...rest }) => ({
      ...rest,
    }));

    if (appointmentDetail?.activePrescription) {
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.PRESCRIPTION,
        IPrescriptionPermissionEnums.UPDATE_PRESCRIPTION,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      const {
        id,
        doctorId,
        memberId,
        patientId,
        bookingId,
        prescription_id,
        ...updatedPrescription
      } = prescription?.create || {};
      const prescriptionPayload =
        filteredNullEmptyArrayData(updatedPrescription);
      const updatePrescriptionResponse = await updatePrescriptionApi(
        prescription?.create?.id!,
        {
          ...prescriptionPayload,
          medicine,
          test,
          saveType: saveTypePrescription,
        }
      );
      if ("result" in updatePrescriptionResponse) {
        notify(INotifyEnum.SUCCESS, updatePrescriptionResponse?.message);
        if (saveTypePrescription === ISaveTypePrescription.SAVE_PRESCRIPTION) {
          setIsSaved(true);
          openCloseSavePrescriptionModal();
          if (!isVideoCallInitiated) {
            navigate("/schedule");
          } else {
            dispatch(setIsVideoMinimizedOrPrescInitiated(true));
          }
        } else {
          openCloseSharePrescriptionModal();
          setPdfUrl(updatePrescriptionResponse?.result?.url!);
        }
      } else {
        notify(INotifyEnum.ERROR, updatePrescriptionResponse?.message);
        setIsSaved(false);
      }
      setIsSavePrescriptionLoading(false);
      setIsEndPrescriptionLoading(false);
    } else {
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.PRESCRIPTION,
        IPrescriptionPermissionEnums.SAVE_PRESCRIPTION_MEDICAL_RECORD,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      if (
        saveTypePrescription === ISaveTypePrescription.SAVE_PRESCRIPTION &&
        !execute
      ) {
        openCloseSavePrescriptionModal();
        return;
      }

      const { id, doctorId, patientId, memberId, ...prescriptionData } =
        prescription?.create;
      const prescriptionDataPayload =
        filteredNullEmptyArrayData(prescriptionData);
      const createPrescriptionResponse = await createPrescriptionApi({
        ...prescriptionDataPayload,
        medicine,
        test,
        saveType: saveTypePrescription,
        bookingId: appointmentDetail?.id,
      });
      if ("result" in createPrescriptionResponse) {
        notify(INotifyEnum.SUCCESS, createPrescriptionResponse?.message);

        if (saveTypePrescription === ISaveTypePrescription.SAVE_PRESCRIPTION) {
          setIsSaved(true);
          openCloseSavePrescriptionModal();
          if (!isVideoCallInitiated) {
            navigate("/schedule");
          } else {
            dispatch(setIsVideoMinimizedOrPrescInitiated(true));
          }
        } else {
          openCloseSharePrescriptionModal();
          setPdfUrl(createPrescriptionResponse?.result?.url!);
        }
      } else {
        notify(INotifyEnum.ERROR, createPrescriptionResponse?.message);
        setIsSaved(false);
      }
      setIsSavePrescriptionLoading(false);
      setIsEndPrescriptionLoading(false);
    }
    dispatch(setPrescriptionTemplateValues({}));
    if (saveTypePrescription === ISaveTypePrescription.SAVE_PRESCRIPTION) {
      dispatch(setAppointmentDetail({}));
    }
  };

  const createPrescriptionByUploadHandler = async (
    healthCategoryId: string,
    url: string,
    handleClose?: () => void
  ) => {
    const createPrescriptionResponse = await saveMedicalRecordApi({
      healthCategoryId,
      url,
      bookingId: appointmentDetail?.id,
    });
    if ("result" in createPrescriptionResponse) {
      setPdfUrl(createPrescriptionResponse?.result?.url);
      openCloseSharePrescriptionModal();
      handleClose && handleClose();
      notify(INotifyEnum.SUCCESS, createPrescriptionResponse?.message);
      if (isVideoCallInitiated) {
        dispatch(setIsVideoMinimizedOrPrescInitiated(true));
      }
    } else {
      notify(INotifyEnum.ERROR, createPrescriptionResponse?.message);
    }
  };

  return {
    isSavePrescriptionLoading,
    isEndPrescriptionLoading,
    pdfUrl,
    sharePrescription,
    showSavePrescription,
    openCloseSavePrescriptionModal,
    handleCreatePrescriptionHandler,
    openCloseSharePrescriptionModal,
    createPrescriptionByUploadHandler,
    isSaved,
  };
};

export default usePrescriptionCreate;
