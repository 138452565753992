import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import GetImages from "../../../components/GetImages";
import GenericCardHeader from "../../../components/GenericCardHeader";
import Button from "../../../components/Button";
import ChangePatient from "../../../components/ChangePatient";
import AddEditProfile from "../../../components/AddEditProfile";
import GenericSuccessAlert from "../../../components/GenericSuccessAlert";
import Header from "../../../components/Header";
import FullScreenDialog from "../../../components/FullScreenDialog";
import Dialog from "../../../components/Dialog";

import style from "./index.module.css";

import {
  IDoctorAvailabilityPlatform,
  IDoctorConsultationSelection,
  IDoctorProfileStatus,
  IDoctorScheduleConsultationType,
} from "../../../models/doctor";
import { IFilterType } from "../../../models/patient";

import { fetchPatientMembers } from "../../../store/slices/patient/member";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchConfiguration } from "../../../store/slices/config";
import { checkoutDetail } from "../../../store/slices/patient/findDoctor";

import { INotifyEnum, notify } from "../../../utils/toaster";
import { reserveAppointmentFromPatient } from "../../../utils/apis/patient/doctor";
import { storage } from "../../../utils/Storage";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useCreatePatientMember from "../../../utils/hooks/useCreatePatientMember";
import useIsTablet from "../../../utils/hooks/useIsTablet";

interface IOpenModal {
  memberList: boolean;
  success: boolean;
}

interface IProps {
  isOpen?: boolean;
  closeHandler?: () => void;
  type?: IFilterType;
}
const Checkout: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler, type } = props;
  const [paymentMode, setPaymentMode] = useState<string | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<IOpenModal>({
    memberList: false,
    success: false,
  });
  const [slotNo, setSlotNo] = useState<number>(0);

  const navigate = useNavigate();

  const {
    profileDetail,
    isAddMemberModalOpen,
    isGenderOpen,
    openCloseGenderModal,
    createPatientMember,
    openCloseAddMemberModal,
    profileDetailHandler,
    saveGenderValue,
  } = useCreatePatientMember();

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const dispatch = useAppDispatch();
  const paymentMethodConfig: any = useAppSelector((state) => state.config);
  const checkoutDetails = useAppSelector(
    (state) => state.findDoctor.checkoutPatientDetail
  );
  const memberList = useAppSelector((state) => state.member);

  const handlePaymentSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMode((event.target as HTMLInputElement).value);
  };

  const closeModal = () => {
    const isModalOpen: any = {};
    for (let key in isOpenModal) {
      isModalOpen[key] = false;
    }
    setIsOpenModal(isModalOpen);
  };

  const fetchPatientList = async () => {
    await dispatch(fetchPatientMembers());
  };

  const handleReserveAppointment = async () => {
    const reserveAppointmentPatientResponse =
      await reserveAppointmentFromPatient({
        doctorId: checkoutDetails?.doctorId,
        memberId: checkoutDetails?.memberId,
        slotId: checkoutDetails?.slotId,
        consultationType:
          checkoutDetails?.type === IDoctorAvailabilityPlatform.VIDEO_CALL
            ? IDoctorScheduleConsultationType.VIDEO_CALL
            : IDoctorScheduleConsultationType.IN_CLINIC,
        paymentMethodId: +paymentMode!,
        paymentAmount: checkoutDetails?.fee,
        ...(checkoutDetails?.type === IDoctorAvailabilityPlatform.IN_CLINIC && {
          clinicId: checkoutDetails?.clinicId,
        }),
      });
    if ("result" in reserveAppointmentPatientResponse) {
      notify(INotifyEnum.SUCCESS, reserveAppointmentPatientResponse.message);
      setIsOpenModal({ ...isOpenModal, success: true });
      setSlotNo(reserveAppointmentPatientResponse.result.slotNo);
      storage.setSessionToken(
        "bookingId",
        reserveAppointmentPatientResponse.result.bookingId
      );
    } else {
      notify(INotifyEnum.ERROR, reserveAppointmentPatientResponse.message);
    }
  };

  const checkoutScreen = () => (
    <Grid item className={style.container}>
      <Grid item>
        <Header heading="Checkout" />
        <Grid item className={style.doctorDetailContainer}>
          <GenericCardHeader
            image={checkoutDetails?.doctorImage}
            name={checkoutDetails?.doctorName}
            bio={checkoutDetails?.bio}
            languages={checkoutDetails?.languages}
            status={IDoctorProfileStatus.VERIFIED}
          />
        </Grid>
        <Grid item className={style.consultationDetailContainer}>
          <Typography component={"p"}>Consultation Details</Typography>
          <Grid item className={style.innerContainer}>
            <Grid item className={style.consultationDetailItem}>
              <Grid item>
                <GetImages
                  name="ScheduleSelectedSmallIcon"
                  width="20"
                  height="16"
                />
              </Grid>
              <Typography component={"p"}>
                {checkoutDetails?.dateSlotTime}
              </Typography>
            </Grid>
            <Grid item className={style.consultationDetailItem}>
              <Grid item>
                <GetImages
                  name={
                    checkoutDetails?.type ===
                    IDoctorAvailabilityPlatform.VIDEO_CALL
                      ? "VideoIcon"
                      : "ClinicSmallIcon"
                  }
                  width="20"
                  height="16"
                />
              </Grid>
              <Typography component={"p"}>
                {checkoutDetails?.type ===
                IDoctorAvailabilityPlatform.VIDEO_CALL
                  ? IDoctorConsultationSelection.VIDEO_CONSULTATION
                  : checkoutDetails?.clinicName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={style.patientPayableContainerSelected}>
          <Typography component={"p"}>Patient Details</Typography>
          <Grid item className={style.patientDetail}>
            <Typography component={"p"} className={style.patientName}>
              {checkoutDetails?.name} (Main)
            </Typography>
            <Typography
              component={"p"}
              className={style.changePatient}
              onClick={() =>
                setIsOpenModal({
                  ...isOpenModal,
                  memberList: !isOpenModal.memberList,
                })
              }
            >
              Change
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={style.patientPayableContainer}>
          <Grid item className={style.totalPayableContainer}>
            <Typography component={"p"}>Total Payable</Typography>
            <Typography component={"p"}>₹ {checkoutDetails?.fee}</Typography>
          </Grid>
          <Grid item className={style.paymentModeSelection}>
            <RadioGroup value={paymentMode} onChange={handlePaymentSelection}>
              {paymentMethodConfig?.config[type!]?.map(
                (item: any, index: number) => (
                  <FormControlLabel
                    key={index}
                    value={item.id}
                    control={
                      <Radio
                        checkedIcon={
                          <GetImages
                            name="TickOrangeIcon"
                            width="20"
                            height="20"
                          />
                        }
                        icon={
                          <GetImages
                            name="RadioBtnIcon"
                            width="20"
                            height="20"
                          />
                        }
                      />
                    }
                    label={
                      <Typography
                        component={"p"}
                        className={style.paymentModeLabel}
                      >
                        {item.frontendText}
                      </Typography>
                    }
                  />
                )
              )}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item className={style.buttonContainer}>
          <Button disabled={!paymentMode} btnTrigger={handleReserveAppointment}>
            Reserve
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  useEffect(() => {
    fetchPatientList();
    if (type) {
      dispatch(fetchConfiguration(type));
    }
  }, [type]);

  useEffect(() => {
    const memberInfo = memberList.list.filter(
      (member, index) => member.is_default
    )[0];

    dispatch(
      checkoutDetail({
        ...checkoutDetails,
        name: memberInfo?.name,
      })
    );
  }, [memberList]);

  return (
    <>
      {isMobile || isTablet ? (
        <FullScreenDialog lineBackgroundColor="white">
          {checkoutScreen()}
        </FullScreenDialog>
      ) : (
        <Dialog
          fullWidth
          maxWidth="xs"
          isOpen={isOpen}
          closeHandler={closeHandler}
        >
          {checkoutScreen()}
        </Dialog>
      )}
      {isOpenModal.memberList && (
        <ChangePatient
          isOpen={isOpenModal.memberList}
          closeHandler={closeModal}
          handleAddMember={openCloseAddMemberModal}
        />
      )}
      {isAddMemberModalOpen && (
        <AddEditProfile
          isOpen={isAddMemberModalOpen}
          closeHandler={openCloseAddMemberModal}
          title="Add new Profile"
          age={profileDetail.age}
          gender={profileDetail.gender}
          name={profileDetail.name}
          isGenderOpen={isGenderOpen}
          saveGender={saveGenderValue}
          saveProfile={createPatientMember}
          openCloseGenderModal={openCloseGenderModal}
          profileDetailHandler={profileDetailHandler}
        />
      )}
      {isOpenModal.success && (
        <GenericSuccessAlert
          type={checkoutDetails?.type}
          slotNumber={slotNo}
          date={checkoutDetails?.dateSlotTime}
          name={checkoutDetails?.doctorName}
          bio={checkoutDetails?.bio}
          isOpen={isOpenModal.success}
          closeScheduleModal={() => navigate("/home")}
          btnLabel="goto_homepage"
        />
      )}
    </>
  );
};

export default Checkout;
