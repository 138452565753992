import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";

import {
  IAddPatient,
  IAppointmentCreation,
  IAppointmetEnums,
  IPatientExistOrNotModule,
} from "../../models/doctor";
import {
  IAppointmentPermissionEnums,
  IModalKeyEnums,
  IRolePermissionAccess,
  IUserPermssionEnums,
} from "../../models/permission";
import { IOtpVerify } from "../../models/onboarding";
import { IRoleType } from "../../models/role";

import GetImages from "../../components/GetImages";

import useMobileCheckPatientCreate from "./useMobileCheckPatientCreate";
import useAppointmentDateFilter from "./useAppointmentDateFilter";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  resetPatientDetailErrorMessages,
  setModuleName,
} from "../../store/slices/doctor/checkMobilePatientExistOrNot";
import { setShowPermissionAlert } from "../../store/slices/permission";
import { setAddPatient } from "../../store/slices/doctor/topNavigation";
import {
  fetchScheduleAppointmentList,
  resetScheduleSelection,
  setIsSelectionOn,
} from "../../store/slices/doctor/schedule";

import { getPermissionAccess } from "..";

import { IAPIStatusCode } from "../apis/APIEndpointConfig";

import style from "./styles/index.module.css";

import useIsMobile from "./useIsMobile";

import { storage } from "../Storage";

const useMakeAppointment = () => {
  const [makeAppointment, setMakeAppointment] = useState<IAppointmentCreation>({
    creation: false,
    mobileCheck: false,
    otp: false,
    patientDetail: false,
    success: false,
    patientAlreadyExist: false,
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [slotNo, setSlotNo] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [showDateRangeSelector, setShowDateRangeSelector] =
    useState<boolean>(false);
  const [showCancelSchedule, setShowCancelSchedule] = useState<boolean>(false);

  const dateSelectorModalRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const { permission } = useAppSelector((state) => state.rolePermission);
  const { checkNumberError, moduleName } = useAppSelector(
    (state) => state.patientExistOrNot
  );
  const scheduleInfo = useAppSelector((state) => state.schedule);
  const { selectedAppointments, selectedSchedules } = useAppSelector(
    (state) => state.schedule.selection
  );

  const roleType = storage.getSessionToken("roleType");

  const {
    checkMobileNumberDetail,
    createPatientAfterVerifyOtp,
    sendOtpToCreatePatient,
    otpId,
    setOtpId,
  } = useMobileCheckPatientCreate({
    mobileNo,
  });
  const { applyDateRangeValues, selectedDate } = useAppointmentDateFilter();

  const { isMobile } = useIsMobile();

  const openCloseAppointmentCancelConfirmation = () =>
    setShowCancelSchedule(!showCancelSchedule);

  const closeScheduleModal = () => {
    const schedulesValues: any = {};
    for (let key in makeAppointment) {
      schedulesValues[key] = false;
    }
    setMakeAppointment(schedulesValues);
  };

  const openScheduleModalHandler = (type: IAppointmetEnums, value?: string) => {
    const schedulesValues: any = {};
    for (let key in makeAppointment) {
      schedulesValues[key] = false;
    }
    switch (type) {
      case IAppointmetEnums.CREATION:
        setMakeAppointment({
          ...schedulesValues,
          creation: true,
        });
        return;
      case IAppointmetEnums.MOBILE_CHECK:
        setMakeAppointment({
          ...schedulesValues,
          mobileCheck: true,
        });
        return;
      case IAppointmetEnums.PATIENT_DETAIL:
        checkMobileNumberDetail(value!);
        return;
      case IAppointmetEnums.OTP:
        setMakeAppointment({
          ...schedulesValues,
          otp: true,
          patientDetail: false,
        });
        return;
      case IAppointmetEnums.SUCCESS:
        setMakeAppointment({
          ...schedulesValues,
          success: true,
        });
        setSlotNo(value!);
        return;
      default:
        closeScheduleModal();
        return;
    }
  };

  const navigateToMobileVerify = () => {
    dispatch(resetPatientDetailErrorMessages());
    setMakeAppointment({
      ...makeAppointment,
      otp: false,
      mobileCheck: true,
    });
  };

  const handleCreatePatientVerifyOTP = (value: IOtpVerify) => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.USER,
      IUserPermssionEnums.OTP_TO_CREATE_PATIENT_BY_DOCTOR,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }

    createPatientAfterVerifyOtp(value.otp);
    setMakeAppointment({
      ...makeAppointment,
      mobileCheck: false,
      patientDetail: true,
    });
  };

  const createAppointmentHandler = () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.APPOINTMENT,
      IAppointmentPermissionEnums.CREATE_APPOINTMENT,
      permission
    );

    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    setMakeAppointment({
      ...makeAppointment,
      creation: true,
    });
    dispatch(setModuleName(IPatientExistOrNotModule.APPOINTMENT));
  };

  const clearEnterPatientStates = () => {
    closeScheduleModal();
    setSlotNo("");
    setMobileNo("");
    dispatch(resetPatientDetailErrorMessages());
    dispatch(
      setAddPatient({
        field: IAddPatient.APPOINTMENT,
        value: false,
      })
    );
    if (roleType === IRoleType.PHARMACY) {
      dispatch(fetchScheduleAppointmentList({}));
    }
  };

  const backMakeAppointmentHandler = (appointmentEnum: IAppointmetEnums) => {
    setMakeAppointment({
      ...makeAppointment,
      ...(appointmentEnum === IAppointmetEnums.CREATION
        ? { creation: true, mobileCheck: false }
        : appointmentEnum === IAppointmetEnums.MOBILE_CHECK
        ? { mobileCheck: true, patientDetail: false, otp: false }
        : {}),
    });
    setOtpId("");
    dispatch(resetPatientDetailErrorMessages());
  };

  const handleClickDateRangeOutside = (event: MouseEvent) => {
    if (
      dateSelectorModalRef.current &&
      !dateSelectorModalRef.current.contains(event.target as Node)
    ) {
      setShowDateRangeSelector(false);
    }
  };

  const openCloseDateRangeSelector = () => {
    if (showDateRangeSelector) {
      document.removeEventListener("mousedown", handleClickDateRangeOutside);
    } else {
      document.addEventListener("mousedown", handleClickDateRangeOutside);
    }
    setShowDateRangeSelector(!showDateRangeSelector);
  };

  const submitDateRangeValues = (start: string, end: string) => {
    applyDateRangeValues(start, end);
    openCloseDateRangeSelector();
  };

  const appointmentFilterByDateRange = useCallback(() => {
    return (
      <Grid
        item
        className={style.chooseDateFilter}
        onClick={openCloseDateRangeSelector}
      >
        <GetImages name="CalenderIcon" width="20" height="20" />
        <Typography component={"p"}>{scheduleInfo?.selectedDayText}</Typography>
      </Grid>
    );
  }, [scheduleInfo]);

  const appointmentSelectionCancel = useCallback(() => {
    const cancelAppointmentHandler = () => {
      if (selectedSchedules?.length === 0 && selectedAppointments?.length === 0)
        return;
      openCloseAppointmentCancelConfirmation();
    };
    return (
      <Grid item className={style.selectionContainer}>
        <Typography
          component={"p"}
          className={style.selectText}
          onClick={() =>
            scheduleInfo?.selection?.isSelectionOn
              ? dispatch(resetScheduleSelection())
              : dispatch(setIsSelectionOn())
          }
        >
          {scheduleInfo?.selection?.isSelectionOn ? "Clear" : "Select"}
        </Typography>
        {scheduleInfo?.selection?.isSelectionOn && !isMobile && (
          <Grid item className={style.cancelAppointmentEvent}>
            <GetImages name="CancelIcon" width="28" height="24" />
            <Typography component={"p"} onClick={cancelAppointmentHandler}>
              {" "}
              Cancel Selected
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }, [
    scheduleInfo?.selection?.isSelectionOn,
    selectedSchedules,
    selectedAppointments,
  ]);

  useEffect(() => {
    if (moduleName === IPatientExistOrNotModule.APPOINTMENT) {
      if (checkNumberError?.statusCode === IAPIStatusCode.BAD_REQUEST) {
        openScheduleModalHandler(IAppointmetEnums.OTP);
        sendOtpToCreatePatient();
        return;
      }
      if (checkNumberError?.statusCode === IAPIStatusCode.SUCCESS) {
        setMakeAppointment({
          ...makeAppointment,
          mobileCheck: false,
          patientDetail: true,
          patientAlreadyExist: true,
        });
        return;
      }
    }
  }, [checkNumberError, moduleName]);

  return {
    selectedDate,
    makeAppointment,
    setMakeAppointment,
    slotNo,
    openScheduleModalHandler,
    mobileNo,
    setMobileNo,
    navigateToMobileVerify,
    handleCreatePatientVerifyOTP,
    createAppointmentHandler,
    sendOtpToCreatePatient,
    otpId,
    clearEnterPatientStates,
    selectedTab,
    setSelectedTab,
    backMakeAppointmentHandler,
    appointmentFilterByDateRange,
    showDateRangeSelector,
    submitDateRangeValues,
    appointmentSelectionCancel,
    showCancelSchedule,
    openCloseAppointmentCancelConfirmation,
    dateSelectorModalRef,
  };
};

export default useMakeAppointment;
