import React from "react";
import { Grid, RadioGroup } from "@mui/material";

import style from "./index.module.css";

import MemberDetailTile from "./MemberDetailTile";

import { IPatientMemberDetailEnums } from "../../models/patient";

interface IProps {
  type: IPatientMemberDetailEnums;
  memberId: string;
  name: string;
  age: number;
  selectedMember: string;
  handleSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const MemberDetail: React.FC<IProps> = (props) => {
  const { type, age, memberId, name, selectedMember, handleSelection } = props;

  return (
    <RadioGroup
      value={selectedMember}
      onChange={handleSelection}
      className={style.subContainer}
    >
      <Grid item className={style.cardSelectionContainer}>
        <MemberDetailTile
          type={type}
          memberId={memberId}
          name={name}
          age={age}
        />
      </Grid>
    </RadioGroup>
  );
};

export default MemberDetail;
