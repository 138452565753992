import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import ProfileStatusBadge from "../../../components/ProfileStatusBadge";
import GetImages from "../../../components/GetImages";
import TagChip from "../../../components/TagChip";
import DoctorReviews from "../../../components/DoctorReviews";
import Loader from "../../../components/Loader";
import ViewClinics from "./ViewClinics";
import DoctorFeedBack from "../../../components/DoctorFeedBack";
import Breadcrumb from "../../../components/Breadcrumb";
import MakeAppointment from "../../../components/DoctorProfile/MakeAppointment";

import style from "./index.module.css";

import FirstVideoThumbNail from "../../../assets/videoReviews/1.png";
import SecondVideoThumbNail from "../../../assets/videoReviews/2.png";
import ThirdVideoThumbNail from "../../../assets/videoReviews/3.png";
import FourthVideoThumbNail from "../../../assets/videoReviews/4.png";

import { IDoctorProfileStatus } from "../../../models/doctor";

import { doctorDegreeName, doctorSpeakLanguages } from "../../../utils";
import { storage } from "../../../utils/Storage";
import { postReviewToDoctorApi } from "../../../utils/apis/patient/doctor";
import { INotifyEnum, notify } from "../../../utils/toaster";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useMakeAppointment from "../../../utils/hooks/useMakeAppointment";
import useIsTablet from "../../../utils/hooks/useIsTablet";

import { IRoleType } from "../../../models/role";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDoctorProfile,
  fetchDoctorReviewRatingList,
} from "../../../store/slices/doctor/profile";

const doctorProfiles = {
  videoThumbnail: [
    FirstVideoThumbNail,
    SecondVideoThumbNail,
    ThirdVideoThumbNail,
    FourthVideoThumbNail,
  ],
};

const breadcrumbsItems = [
  { label: "Home", link: "/home" },
  { label: "Doctors", link: "/find-doctor" },
];

interface IProps {
  doctorID?: string;
}
const DoctorProfile: React.FC<IProps> = (props) => {
  const { doctorID } = props;
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [viewMoreClinics, setViewMoreClinics] = useState<boolean>(false);
  const [isSendFeedbackShow, setSendFeedbackShow] = useState<boolean>(false);
  const [selectedStar, setSelectedStar] = useState<number>(0);
  const [reviewMessage, setReviewMessage] = useState<string>("");

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const {
    openScheduleModalHandler,
    clearEnterPatientStates,
    selectedTab,
    setSelectedTab,
  } = useMakeAppointment();

  const keys = ["roleType", "bookingId"];
  const [roleType, bookingId] = keys.map((keyItem) =>
    storage.getSessionToken(keyItem)
  );

  const navigate = useNavigate();

  const { state } = useLocation();
  const doctorId = state?.doctorId ?? doctorID;

  const backToFindDoctorPage = () => {
    if (roleType === IRoleType.DOCTOR) {
      navigate("/profile", { state: { navItem: 3 } });
      return;
    }
    navigate("/find-doctor", { state: { navItem: 1 } });
  };

  const handleViewMore = () => {
    setViewMoreClinics((state) => !state);
  };

  const dispatch = useAppDispatch();
  const doctorProfileInfo = useAppSelector((state) => state.doctorProfile);
  const doctorProfile = doctorProfileInfo?.detail;
  const firstClinic = doctorProfile?.clinics?.[0];

  const sendFeedbackToDoctorHandler = async () => {
    const payload = {
      doctorId: doctorId!,
      bookingId: bookingId!,
      noOfStars: selectedStar,
      reviewMessage,
    };
    const sendFeedbackToDoctorResponse = await postReviewToDoctorApi(payload);
    if ("result" in sendFeedbackToDoctorResponse) {
      notify(INotifyEnum.SUCCESS, sendFeedbackToDoctorResponse?.message);
      dispatch(fetchDoctorReviewRatingList({ doctorId: doctorId! }));
    } else {
      notify(INotifyEnum.ERROR, sendFeedbackToDoctorResponse?.message);
    }
    setReviewMessage("");
    setSendFeedbackShow(!isSendFeedbackShow);
  };

  useEffect(() => {
    if (roleType === IRoleType.PATIENT || !doctorProfileInfo?.detail) {
      dispatch(fetchDoctorProfile(doctorId));
      dispatch(fetchDoctorReviewRatingList({ doctorId: doctorId! }));
    }
  }, []);

  if (doctorProfileInfo?.isLoading) {
    return <Loader />;
  }

  if (roleType === IRoleType.DOCTOR || roleType === IRoleType.JR_DOCTOR) {
    return (
      <Container maxWidth={"xl"} disableGutters>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12} overflow={"hidden"} borderRadius={{ lg: 2.5 }}>
            <Stack direction={"column"}>
              <Box position={"relative"}>
                <img
                  src={doctorProfile?.clinics[0]?.photo_url}
                  alt="clinicImage"
                  className={style.clinicImage}
                />
                <Box
                  position={"absolute"}
                  top={"80%"}
                  left={"50%"}
                  sx={{ transform: "translateX(-50%)" }}
                >
                  <img
                    className={style.doctorImage}
                    src={doctorProfile?.photo_url}
                    alt="doctorPhoto"
                  />
                  <Box className={style.doctorProfileStatus}>
                    <ProfileStatusBadge
                      status={IDoctorProfileStatus.VERIFIED}
                    />
                  </Box>
                </Box>
              </Box>
              {(isMobile || isTablet) && (
                <Grid
                  item
                  className={style.backNavigation}
                  onClick={backToFindDoctorPage}
                >
                  <GetImages name="LeftArrowIcon" width="16" height="16" />
                </Grid>
              )}
              <Grid item xs={12} className={style.container} sx={{ flex: 1 }}>
                <Grid item className={style.doctorDetailContainer}>
                  <Grid className={style.doctorInfoContainer}>
                    <Typography component={"h4"}>
                      {doctorProfile?.name}
                    </Typography>
                    <Typography component={"h6"}>
                      {doctorProfile?.experience_years} yrs •{" "}
                      {doctorProfile?.speciality} •{" "}
                      {doctorProfile?.qualification_details &&
                        doctorDegreeName(doctorProfile?.qualification_details)}
                    </Typography>
                    <Typography component={"h6"}>
                      {doctorProfile?.language &&
                        doctorSpeakLanguages(doctorProfile?.language)}
                    </Typography>
                    <Typography component={"p"}>
                      {doctorProfile?.clinics[0]?.address}
                    </Typography>
                  </Grid>
                  {doctorProfile && doctorProfile?.clinics.length > 1 && (
                    <Grid item>
                      <Typography
                        component={"p"}
                        className={style.totalClinicCount}
                        onClick={handleViewMore}
                      >
                        {doctorProfile?.clinics.length} more clinics
                      </Typography>
                    </Grid>
                  )}
                  <Grid item className={style.doctorAboutContainer}>
                    <Grid
                      sx={{
                        height: viewMore ? "auto" : "5.5rem",
                      }}
                      className={style.doctorAboutWrapper}
                    >
                      {!viewMore && <Grid className={style.bottomGradient} />}
                      <Typography component={"p"} className={style.doctorAbout}>
                        {doctorProfile?.about_me}
                      </Typography>
                    </Grid>
                    <Grid item className={style.viewMoreContainer}>
                      <GetImages name="EyeSmallIcon" width="20" height="16" />
                      <Typography
                        component={"p"}
                        onClick={() => setViewMore(!viewMore)}
                      >
                        View {viewMore ? "less" : "more"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item className={style.tagsContainer}>
                    <Typography component={"p"}>
                      <FormattedMessage
                        id="profile_help_text"
                        defaultMessage={"I can help you with"}
                      />
                    </Typography>
                    <Grid item className={style.tagItemsContainer}>
                      {doctorProfile?.tags.split(",").map((tagName, index) => (
                        <TagChip
                          tagName={tagName}
                          key={`${tagName}-${index}`}
                        />
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item className={style.reviewCountHeader}>
                      <GetImages name="LikeIcon" width="20" height="16" />
                      <Typography component={"p"}>
                        {doctorProfile?.reviews} Reviews
                      </Typography>
                    </Grid>
                    <Grid item className={style.expertiseContainer}>
                      <Typography component={"p"}>
                        {doctorProfile?.expertise}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {viewMoreClinics && (
                  <ViewClinics
                    isOpen={viewMoreClinics}
                    closeModalHandler={handleViewMore}
                    clinics={doctorProfile?.clinics!}
                  />
                )}
                {isSendFeedbackShow && (
                  <DoctorFeedBack
                    isOpen={isSendFeedbackShow}
                    closeHandler={() =>
                      setSendFeedbackShow(!isSendFeedbackShow)
                    }
                    setSelectedStar={setSelectedStar}
                    reviewMessage={reviewMessage}
                    setReviewMessage={setReviewMessage}
                    submitHandler={sendFeedbackToDoctorHandler}
                  />
                )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth={"xl"} disableGutters={isMobile || isTablet}>
      <Box mb={4} sx={{ display: { xs: "none", md: "block" } }}>
        <Breadcrumb
          items={[
            ...breadcrumbsItems,
            {
              label: doctorProfile?.speciality ?? "",
            },
            {
              label: doctorProfile?.name ?? "",
              isCurrent: true,
            },
          ]}
        />
      </Box>
      <Grid container justifyContent={"space-between"}>
        <Grid
          item
          xs={12}
          md={7}
          lg={8}
          overflow={"hidden"}
          borderRadius={{ lg: 2.5 }}
        >
          <Stack direction={"column"} position={"relative"}>
            <Box position={"relative"}>
              <img
                src={doctorProfile?.clinics[0]?.photo_url}
                alt="clinicImage"
                className={style.clinicImage}
              />
              <Box
                position={"absolute"}
                top={"80%"}
                left={"50%"}
                sx={{ transform: "translateX(-50%)" }}
              >
                <img
                  className={style.doctorImage}
                  src={doctorProfile?.photo_url}
                  alt="doctorPhoto"
                />
                <Box className={style.doctorProfileStatus}>
                  <ProfileStatusBadge status={IDoctorProfileStatus.VERIFIED} />
                </Box>
              </Box>
            </Box>
            {(isMobile || isTablet) && (
              <Grid
                item
                className={style.backNavigation}
                onClick={backToFindDoctorPage}
              >
                <GetImages name="LeftArrowIcon" width="16" height="16" />
              </Grid>
            )}
            <Grid item xs={12} className={style.container} sx={{ flex: 1 }}>
              <Grid item className={style.doctorDetailContainer}>
                <Grid className={style.doctorInfoContainer}>
                  <Typography component={"h4"}>
                    {doctorProfile?.name}
                  </Typography>
                  <Typography component={"h6"}>
                    {doctorProfile?.experience_years} yrs •{" "}
                    {doctorProfile?.speciality} •{" "}
                    {doctorProfile?.qualification_details &&
                      doctorDegreeName(doctorProfile?.qualification_details)}
                  </Typography>
                  <Typography component={"h6"}>
                    {doctorProfile?.language &&
                      doctorSpeakLanguages(doctorProfile?.language)}
                  </Typography>
                  <Typography component={"p"}>
                    {firstClinic?.address}, {firstClinic?.city}-
                    {firstClinic?.pincode}
                  </Typography>
                </Grid>
                {doctorProfile && doctorProfile?.clinics.length > 1 && (
                  <Grid item>
                    <Typography
                      component={"p"}
                      className={style.totalClinicCount}
                      onClick={handleViewMore}
                    >
                      {doctorProfile?.clinics.length} more clinics
                    </Typography>
                  </Grid>
                )}
                <Grid item className={style.doctorAboutContainer}>
                  <Grid
                    sx={{
                      height: viewMore ? "auto" : "55px",
                    }}
                    className={style.doctorAboutWrapper}
                  >
                    {!viewMore && <Grid className={style.bottomGradient} />}
                    <Typography component={"p"} className={style.doctorAbout}>
                      {doctorProfile?.about_me}
                    </Typography>
                  </Grid>
                  <Grid item className={style.viewMoreContainer}>
                    <GetImages name="EyeSmallIcon" width="20" height="16" />
                    <Typography
                      component={"p"}
                      onClick={() => setViewMore(!viewMore)}
                    >
                      View {viewMore ? "less" : "more"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item className={style.tagsContainer}>
                  <Typography component={"p"}>
                    <FormattedMessage
                      id="profile_help_text"
                      defaultMessage={"I can help you with"}
                    />
                  </Typography>
                  <Grid item className={style.tagItemsContainer}>
                    {doctorProfile?.tags.split(",").map((tagName, index) => (
                      <TagChip tagName={tagName} key={`${tagName}-${index}`} />
                    ))}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item className={style.reviewCountHeader}>
                    <GetImages name="LikeIcon" width="20" height="16" />
                    <Typography component={"p"}>
                      {doctorProfile?.reviews} Reviews
                    </Typography>
                  </Grid>
                  <Grid item className={style.expertiseContainer}>
                    <Typography component={"p"}>
                      {doctorProfile?.expertise}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {roleType === IRoleType.PATIENT && (
                <>
                  <Grid item className={style.videoReviewContainer}>
                    <Grid item className={style.videoReviewHeader}>
                      <Typography component={"h6"}>Video Reviews</Typography>
                      <Typography component={"p"}>View All</Typography>
                    </Grid>
                    <Grid item className={style.videoReviewThumbnail}>
                      {doctorProfiles.videoThumbnail.map((thumbnail, index) => (
                        <img src={thumbnail} alt="video Thumb" />
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <DoctorReviews />
                  </Grid>
                  <Typography
                    component={"p"}
                    onClick={() => setSendFeedbackShow(!isSendFeedbackShow)}
                  >
                    Send Feedback
                  </Typography>
                </>
              )}
              {viewMoreClinics && (
                <ViewClinics
                  isOpen={viewMoreClinics}
                  closeModalHandler={handleViewMore}
                  clinics={doctorProfile?.clinics!}
                />
              )}
              {isSendFeedbackShow && (
                <DoctorFeedBack
                  isOpen={isSendFeedbackShow}
                  closeHandler={() => setSendFeedbackShow(!isSendFeedbackShow)}
                  setSelectedStar={setSelectedStar}
                  reviewMessage={reviewMessage}
                  setReviewMessage={setReviewMessage}
                  submitHandler={sendFeedbackToDoctorHandler}
                />
              )}
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={4.5} lg={3.5} display={{ xs: "none", md: "block" }}>
          <Box
            width={"100%"}
            sx={{
              bgcolor: "var(--neutral-100)",
              borderRadius: 2.5,
              padding: 0,
            }}
          >
            <MakeAppointment
              doctorId={doctorId}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              openScheduleModalHandler={openScheduleModalHandler}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DoctorProfile;
