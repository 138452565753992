import { lazy } from "react";
const Onboarding = lazy(() => import("../../views/Onboarding"));

const OnboardingRoutes = [
  {
    path: "/",
    component: <Onboarding />,
  },
];

export default OnboardingRoutes;
