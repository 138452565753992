import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { ICheckoutPatientDetail } from "../../../models/patient";
import {
  IDoctor,
  ISpecialitiesList,
} from "../../../models/ApiRequestResponse/patient";

import {
  getDoctorsSpecialitiesListApi,
  getFindDoctorListApi,
} from "../../../utils/apis/patient/doctor";

import { RootState } from "../../store";

export const fetchDoctorSpecialities = createAsyncThunk(
  "patient/fetchDoctorSpecialities",
  async () => {
    const response = await getDoctorsSpecialitiesListApi();
    return response;
  }
);

export const fetchFindDoctor = createAsyncThunk(
  "patient/fetchFindDoctors",
  async (params: string) => {
    const response = await getFindDoctorListApi(params ?? "");
    return response;
  }
);

const fetchFindDoctorSlice = createSlice({
  name: "fetchFindDoctors",
  initialState: {
    list: [] as IDoctor[],
    checkoutPatientDetail: {} as ICheckoutPatientDetail,
    isLoading: true as boolean,
    specialitiesList: [] as ISpecialitiesList[],
  },
  reducers: {
    checkoutDetail: (state, action) => {
      state.checkoutPatientDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // doctor listing
      .addCase(fetchFindDoctor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFindDoctor.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.list = action.payload.result.doctors;
        }
      })
      .addCase(fetchFindDoctor.rejected, (state) => {
        state.isLoading = false;
      })
      // Specialities listing
      .addCase(fetchDoctorSpecialities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorSpecialities.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.specialitiesList = action.payload.result;
        }
      })
      .addCase(fetchDoctorSpecialities.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

const selectFindDoctor = (state: RootState) => state.findDoctor;

export const specialitiesList = createSelector(
  [selectFindDoctor],
  (findDoctor) => findDoctor.specialitiesList
);

export const { checkoutDetail } = fetchFindDoctorSlice.actions;
export default fetchFindDoctorSlice.reducer;
