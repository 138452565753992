import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import TemplateName from "../../../../components/Prescription/TemplateName";
import Title from "../../../../components/Title";
import { InputField } from "../../../../components/InputField";
import TitleInputValueList from "../../../../components/Prescription/TitleInputValueList";
import Button from "../../../../components/Button";
import GetImages from "../../../../components/GetImages";
import MedicineTestInputSuggestion from "../../../../components/Prescription/MedicineTestInputSuggestion";
import Loader from "../../../../components/Loader";
import SaveCancelTemplate from "../../../../components/Prescription/SaveCancelTemplate";
import Confirmation from "../../../../components/Confirmation";
import PermissionWrapper from "../../../../components/PermissionWrapper";

import style from "./index.module.css";

import {
  IProfileDropdownEnums,
  ITemplateCreateEnum,
  ITitleInputValuesListEnums,
} from "../../../../models/doctor";
import { IPrescriptionTemplateList } from "../../../../models/ApiRequestResponse/doctor";
import {
  IModalKeyEnums,
  IPrescriptionTemplatePermissionEnums,
  IRolePermissionAccess,
} from "../../../../models/permission";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  fetchPrescriptionConfig,
  fetchPrescriptionTemplateList,
  setCreateAndResetPrescriptionTemplateDetail,
  setMakeCopyPrescriptionTemplate,
  setPrescriptionTemplate,
} from "../../../../store/slices/doctor/prescription";
import { setTopBarDropdownItems } from "../../../../store/slices/doctor/topNavigation";
import { setShowPermissionAlert } from "../../../../store/slices/permission";

import useDebounce from "../../../../utils/hooks/useDebouncer";
import {
  createPrescriptionTemplateApi,
  deletePrescriptionTemplateApi,
  updatePrescriptionTemplateApi,
} from "../../../../utils/apis/doctor/prescription";
import { INotifyEnum, notify } from "../../../../utils/toaster";
import {
  topBarDropdownDispatchingEnums,
  getPermissionAccess,
} from "../../../../utils";

const CreateEditTemplate: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { isLoading, template } = useAppSelector((state) => state.prescription);
  const { permission } = useAppSelector((state) => state.rolePermission);
  const { list, create, isCreateTemplate }: any = template;

  const [templateName, setTemplateName] = useState<string>(create?.name ?? "");
  const [deleteTemplateConfirm, setDeleteTemplateConfirm] =
    useState<boolean>(false);

  const templateNameDebounce = useDebounce(templateName, 800);

  const openClosePrescriptionConfirmPopUp = () =>
    setDeleteTemplateConfirm(!deleteTemplateConfirm);

  const callAfterCreateDeleteEditPrescription = () => {
    dispatch(fetchPrescriptionTemplateList());
    dispatch(setCreateAndResetPrescriptionTemplateDetail());
    setTemplateName("");
  };

  const createPrescriptionTemplateHandler = async () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.PRESCRIPTION_TEMPLATE,
      IPrescriptionTemplatePermissionEnums.CREATE_PRESCRIPTION_TEMPLATE,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    const { name, diagnosis, dietAndExercise, medicine, test } = create;
    const createPrescriptionTemplateResponse =
      await createPrescriptionTemplateApi({
        name,
        diagnosis,
        dietAndExercise,
        medicine,
        test,
      });
    if ("result" in createPrescriptionTemplateResponse) {
      callAfterCreateDeleteEditPrescription();
      notify(INotifyEnum.SUCCESS, createPrescriptionTemplateResponse?.message);
    } else {
      notify(INotifyEnum.ERROR, createPrescriptionTemplateResponse?.message);
    }
  };

  const deletePrescriptionTemplateHandler = async () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.PRESCRIPTION_TEMPLATE,
      IPrescriptionTemplatePermissionEnums.DELETE_PRESCRIPTION_TEMPLATE,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    const deletePrescriptionTemplateResponse =
      await deletePrescriptionTemplateApi(create?.id);
    if ("result" in deletePrescriptionTemplateResponse) {
      callAfterCreateDeleteEditPrescription();
      openClosePrescriptionConfirmPopUp();
      notify(INotifyEnum.SUCCESS, deletePrescriptionTemplateResponse?.message);
    } else {
      notify(INotifyEnum.ERROR, deletePrescriptionTemplateResponse?.message);
    }
  };

  const editPrescriptionTemplateHandler = async () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.PRESCRIPTION_TEMPLATE,
      IPrescriptionTemplatePermissionEnums.UPDATE_PRESCRIPTION_TEMPLATE,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    const { name, diagnosis, dietAndExercise, medicine, test } = create;
    const updatePrescriptionTemplateResponse =
      await updatePrescriptionTemplateApi(create?.id, {
        name,
        diagnosis,
        dietAndExercise,
        medicine,
        test,
      });
    if ("result" in updatePrescriptionTemplateResponse) {
      callAfterCreateDeleteEditPrescription();
      notify(INotifyEnum.SUCCESS, updatePrescriptionTemplateResponse?.message);
    } else {
      notify(INotifyEnum.ERROR, updatePrescriptionTemplateResponse?.message);
    }
  };

  useEffect(() => {
    if (templateNameDebounce) {
      dispatch(
        setPrescriptionTemplate({
          identifier: ITemplateCreateEnum.NAME,
          value: templateName,
        })
      );
    }
  }, [templateNameDebounce]);

  useEffect(() => {
    dispatch(fetchPrescriptionTemplateList());
    dispatch(fetchPrescriptionConfig());
  }, []);

  useEffect(() => {
    setTemplateName(create?.name);
  }, [create]);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Grid item className={style.createTemplateContainer}>
        {list?.length > 0 && (
          <Grid item className={style.templateListContainer}>
            <Typography component={"p"} className={style.templateCount}>
              {list?.length}{" "}
              <FormattedMessage id="templates" defaultMessage="Templates" />
            </Typography>
            <Grid item className={style.templateNameList}>
              <PermissionWrapper
                moduleKey={IModalKeyEnums.PRESCRIPTION_TEMPLATE}
                featureKey={
                  IPrescriptionTemplatePermissionEnums.VIEW_PRESCRIPTION_TEMPLATE
                }
              >
                <Grid item>
                  {list?.map(
                    (detail: IPrescriptionTemplateList, index: number) => (
                      <TemplateName
                        {...detail}
                        key={`${detail?.name}-${index}`}
                      />
                    )
                  )}
                </Grid>
              </PermissionWrapper>
              <Grid item className={style.makeNewTemplateBtnContainer}>
                <Button
                  btnTrigger={() =>
                    dispatch(setCreateAndResetPrescriptionTemplateDetail())
                  }
                >
                  <FormattedMessage
                    id="make_new_temp"
                    defaultMessage="Make a new template"
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item className={style.templateCreateFormContainer}>
          <Grid className={style.createTemplateNameActionContainer}>
            <Grid item className={style.createTemplateActions}>
              <Typography component={"h4"}>
                {isCreateTemplate ? (
                  <FormattedMessage
                    id="create_new_temp"
                    defaultMessage="Create New Template"
                  />
                ) : (
                  templateName
                )}
              </Typography>
              {!isCreateTemplate && (
                <Grid item className={style.saveEditMakeCopyActions}>
                  <Button
                    btnTrigger={() =>
                      dispatch(setMakeCopyPrescriptionTemplate())
                    }
                  >
                    <FormattedMessage
                      id="save_as_copy"
                      defaultMessage="Save as a copy"
                    />
                  </Button>
                  <Button btnTrigger={editPrescriptionTemplateHandler}>
                    <FormattedMessage
                      id="save_edits"
                      defaultMessage="Save edits"
                    />
                  </Button>
                  <Grid
                    item
                    className={style.trashIcon}
                    onClick={openClosePrescriptionConfirmPopUp}
                  >
                    <GetImages
                      name="TrashIcon"
                      width="24"
                      height="24"
                      fill="var(--red-600)"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item className={style.templateNameInput}>
              <InputField
                value={templateName || ""}
                placeholder={intl.formatMessage({
                  id: "template_name",
                  defaultMessage: "Template Name",
                })}
                label={intl.formatMessage({
                  id: "template_name",
                  defaultMessage: "Template Name",
                })}
                onChange={(event) => setTemplateName(event?.target?.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item className={style.diagnosisContainer}>
            <Grid item textAlign={"left"} style={{ marginBottom: "1.6rem" }}>
              <Title>
                <FormattedMessage id="details" defaultMessage="Details" />
              </Title>
            </Grid>
            <TitleInputValueList
              title="diagnosis"
              hasActionButton={false}
              buttonLabel={null}
              identifier={ITitleInputValuesListEnums.DIAGNOSIS}
              sliceAttr={"template"}
            />
          </Grid>
          <MedicineTestInputSuggestion />
          <Grid item className={style.dietExerciseContainer}>
            <TitleInputValueList
              title="diet_exercise"
              hasActionButton={false}
              buttonLabel={null}
              identifier={ITitleInputValuesListEnums.DIET_AND_EXERCISE}
              sliceAttr={"template"}
            />
          </Grid>
        </Grid>
      </Grid>
      {isCreateTemplate && (
        <SaveCancelTemplate
          isShowIcon
          saveLabel={"Save Template"}
          cancelHandler={() =>
            dispatch(
              setTopBarDropdownItems(
                topBarDropdownDispatchingEnums[IProfileDropdownEnums.TEMPLATES]
              )
            )
          }
          saveHandler={createPrescriptionTemplateHandler}
        />
      )}
      {deleteTemplateConfirm && (
        <Confirmation
          isOpen={deleteTemplateConfirm}
          closeHandler={openClosePrescriptionConfirmPopUp}
          title="delete_prescription_template"
          alterMessage="delete_temp_message"
          takeActionLabel="yes_delete"
          dontActionLabel="dont_delete"
          actionHandler={deletePrescriptionTemplateHandler}
        />
      )}
    </>
  );
};

export default CreateEditTemplate;
