import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  IScheduleList,
  ICancelScheduleAppointments,
  IAppointments,
} from "../../../models/ApiRequestResponse/doctor";
import { IScheduleAppointmentDateRangeParams } from "../../../models/doctor";

import {
  getScheduleAppointmentListingApi,
  searchAppointmentsApi,
} from "../../../utils/apis/doctor/appointment";

const initialStateScheduleSelection = {
  isSelectionOn: false as boolean,
  scheduleSelection: {} as { [key: string]: boolean },
  appointmentSelection: {} as { [key: string]: boolean },
  selectedSchedules: [] as ICancelScheduleAppointments[],
  selectedAppointments: [] as string[],
};

export const fetchScheduleAppointmentList = createAsyncThunk(
  "doctor/schedule",
  async ({ start, end }: IScheduleAppointmentDateRangeParams) => {
    const response = await getScheduleAppointmentListingApi({ start, end });
    return response;
  }
);

export const fetchSeachedAppointmentList = createAsyncThunk(
  "doctor/search-schedule",
  async (search: string) => {
    const response = await searchAppointmentsApi(search);
    return response;
  }
);

const ScheduleSlice = createSlice({
  name: "schedule",
  initialState: {
    selection: initialStateScheduleSelection,
    scheduleAppointmentList: [] as IScheduleList[],
    filteredScheduleAppointmentList: [] as IScheduleList[],
    searchAppointmentsList: [] as IAppointments[],
    selectedDayText: "Choose a Date",
    isLoading: true as boolean,
  },
  reducers: {
    resetScheduleSelection: (state) => {
      state.selection = initialStateScheduleSelection;
    },
    setIsSelectionOn: (state) => {
      state.selection.isSelectionOn = !state.selection.isSelectionOn;
    },
    setScheduleAppointmentList: (state, action) => {
      state.scheduleAppointmentList = action.payload;
    },
    setFilteredScheduleAppointmentList: (state, action) => {
      state.filteredScheduleAppointmentList = action.payload;
    },
    setScheduleSelection: (state, action) => {
      state.selection.scheduleSelection = {
        ...state.selection.scheduleSelection,
        ...action.payload,
      };
      const checkedSchedules = [];
      for (let scheduleDetailKey in state.selection.scheduleSelection) {
        if (state.selection.scheduleSelection[scheduleDetailKey]) {
          const [scheduleId, date] = scheduleDetailKey.split("&");
          checkedSchedules.push({
            date,
            scheduleIds: [scheduleId],
          });
        }
      }
      state.selection.selectedSchedules = checkedSchedules;
    },
    setAppointmentSelection: (state, action) => {
      state.selection.appointmentSelection = {
        ...state.selection.appointmentSelection,
        ...action.payload,
      };
      const checkedAppointmentsIds = [];
      for (let patientId in state.selection.appointmentSelection) {
        if (state.selection.appointmentSelection[patientId]) {
          checkedAppointmentsIds.push(patientId);
        }
      }
      state.selection.selectedAppointments = checkedAppointmentsIds;
    },
    resetSearchAppointments: (state) => {
      state.searchAppointmentsList = [];
    },
    setSelectedDayText: (state, action) => {
      state.selectedDayText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // schedule appointments list
      .addCase(fetchScheduleAppointmentList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchScheduleAppointmentList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.scheduleAppointmentList = action.payload.result;
        }
      })
      .addCase(fetchScheduleAppointmentList.rejected, (state) => {
        state.isLoading = false;
      })
      // searched appointments list
      .addCase(fetchSeachedAppointmentList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSeachedAppointmentList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.searchAppointmentsList = action.payload.result;
        }
      })
      .addCase(fetchSeachedAppointmentList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  resetSearchAppointments,
  resetScheduleSelection,
  setIsSelectionOn,
  setScheduleSelection,
  setAppointmentSelection,
  setSelectedDayText,
  setScheduleAppointmentList,
  setFilteredScheduleAppointmentList,
} = ScheduleSlice.actions;
export default ScheduleSlice.reducer;
