import React, { useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box, Dialog, IconButton } from "@mui/material";

import Button from "../../../../components/Button";
import { InputField } from "../../../../components/InputField";
import GetImages from "../../../../components/GetImages";
import PaymentDetail from "../../../../components/Pharmacy/PaymentDetail";

import style from "./index.module.css";

import { storage } from "../../../../utils/Storage";

import { IMedicineOrderType } from "../../../../models/ApiRequestResponse/patient";
import {
  IOrderStatusEnum,
  IPharmacyOrderDetailUpdate,
} from "../../../../models/ApiRequestResponse/pharmacy";
import { IRoleType } from "../../../../models/role";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { saveSelectedOrder } from "../../../../store/slices/pharmacyClinicDiagnostic";
import useIsMobile from "../../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../../utils/hooks/useIsTablet";

interface IProps {
  onUpdate: (values: IPharmacyOrderDetailUpdate) => void;
  onBack: () => void;
}

const OrderCompleteUpdate: React.FC<IProps> = ({ onUpdate, onBack }) => {
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const [values, setValues] = useState<IPharmacyOrderDetailUpdate>({
    deliveryPartner: "",
    trackingId: "",
    totalBill: null,
    discount: null,
    totalPayable: null,
  });
  const roleType = storage.getSessionToken("roleType");

  const dispatch = useAppDispatch();
  const { order } = useAppSelector((state) => state.pharmacyClinicDiagnostic);

  const handleUpdate = () => {
    if (order) {
      dispatch(
        saveSelectedOrder({
          ...order,
          status: IOrderStatusEnum.COMPLETED,
          amount: values?.totalBill,
          tracking_id: values?.trackingId,
          delivery_partner: values?.deliveryPartner,
          discountAmount: values?.discount,
          totalPayable: values?.totalPayable,
        })
      );
      onUpdate(values);
      onBack();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const isHomeDelivery = useMemo(
    () => order?.type === IMedicineOrderType.HOME_DELIVERY,
    [order]
  );

  const renderInputs = () => (
    <Stack gap={2} className={style.inputContainer}>
      {roleType === IRoleType.DIAGNOSTIC_CENTER && <PaymentDetail />}
      {isHomeDelivery && (
        <>
          <InputField
            label="Delivery Partner"
            name="deliveryPartner"
            value={values.deliveryPartner}
            onChange={handleChange}
            backgroundColor="secondary"
            className={style.inputBackground}
          />
          <InputField
            label="Tracking ID"
            name="trackingId"
            value={values.trackingId}
            onChange={handleChange}
            backgroundColor="secondary"
            className={style.inputBackground}
          />
        </>
      )}
      {roleType !== IRoleType.DIAGNOSTIC_CENTER && (
        <InputField
          label="Total Bill"
          name="totalBill"
          value={values.totalBill}
          onChange={handleChange}
          backgroundColor="secondary"
          className={style.inputBackground}
        />
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <InputField
          label="Discount"
          name="discount"
          value={values.discount}
          onChange={handleChange}
          backgroundColor="secondary"
          className={style.inputBackground}
        />
        <InputField
          label="Total Payable"
          name="totalPayable"
          value={values.totalPayable}
          onChange={handleChange}
          backgroundColor="secondary"
          className={style.inputBackground}
        />
      </Stack>
    </Stack>
  );

  const renderHeading = () => (
    <IconButton onClick={onBack} sx={{ p: 0, justifyContent: "flex-start" }}>
      <GetImages name="BackBlackIcon" width="26" height="16" />
      <Typography variant="subtitle1" component="p" className={style.heading}>
        {isHomeDelivery
          ? "Update Tracking ID & Payment Details"
          : "Update Payment Details"}
      </Typography>
    </IconButton>
  );

  const renderContent = () => (
    <Stack gap={2}>
      <Stack gap={2} justifyContent="space-between">
        {renderHeading()}
      </Stack>
      {renderInputs()}
      <Button btnTrigger={handleUpdate}>Update</Button>
    </Stack>
  );

  const isWebScreen = useMemo(
    () => !(isMobile || isTablet),
    [isMobile, isTablet]
  );

  if (isWebScreen) {
    return (
      <Dialog
        open={true}
        maxWidth="xs"
        PaperProps={{
          sx: { borderRadius: 2 },
        }}
      >
        <Box display="flex" alignItems="center" p={4}>
          {renderContent()}
        </Box>
      </Dialog>
    );
  }

  return renderContent();
};

export default OrderCompleteUpdate;
