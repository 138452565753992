import { INotifyEnum, notify } from "../toaster";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { storage } from "../Storage";
import { getCartListApi } from "../apis/patient/tests";
import { IGetCartResponse } from "../../models/ApiRequestResponse/patient";
import { setSelectedCartDetails } from "../../store/slices/patient/tests";

const useGetCartDetails = () => {
  const token = storage.getSessionToken("token");

  const dispatch = useAppDispatch();
  const [cartDetails, setCartDetails] = useState<IGetCartResponse>();

  const getCartDetails = async () => {
    const cartDetailRes = await getCartListApi();
    if ("result" in cartDetailRes) {
      setCartDetails(cartDetailRes.result);
      dispatch(setSelectedCartDetails(cartDetailRes.result));
      return;
    }
    notify(INotifyEnum.ERROR, cartDetailRes.message);
  };

  useEffect(() => {
    if (!token) {
      return;
    } else {
      getCartDetails();
    }
  }, [token]);

  return { getCartDetails, cartDetails };
};

export default useGetCartDetails;
