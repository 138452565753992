import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Button as MUIButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import GetImages from "../../../components/GetImages";
import GenericCardHeader from "../../../components/GenericCardHeader";
import Button from "../../../components/Button";
import ChangePatient from "../../../components/ChangePatient";
import AddEditProfile from "../../../components/AddEditProfile";
import Header from "../../../components/Header";

import style from "./index.module.css";

import {
  IDoctorAvailabilityPlatform,
  IDoctorConsultationSelection,
  IDoctorProfileStatus,
  IDoctorScheduleConsultationType,
} from "../../../models/doctor";
import { IFilterType } from "../../../models/patient";

import { fetchPatientMembers } from "../../../store/slices/patient/member";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchConfiguration } from "../../../store/slices/config";
import { checkoutDetail } from "../../../store/slices/patient/findDoctor";

import { INotifyEnum, notify } from "../../../utils/toaster";
import { reserveAppointmentFromPatient } from "../../../utils/apis/patient/doctor";
import { storage } from "../../../utils/Storage";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useCreatePatientMember from "../../../utils/hooks/useCreatePatientMember";

interface IOpenModal {
  memberList: boolean;
  success: boolean;
}
interface IPaymentMethodConfig {
  config: Record<string, Array<{ id: string; frontendText: string }>>;
}

interface IProps {
  isOpen?: boolean;
  closeHandler?: () => void;
  type?: IFilterType;
}
const Checkout: React.FC<IProps> = (props) => {
  const { closeHandler, type } = props;
  const [paymentMode, setPaymentMode] = useState<string | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<IOpenModal>({
    memberList: false,

    success: false,
  });
  const [slotNo, setSlotNo] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();

  const intl = useIntl();
  
  const {
    profileDetail,
    isAddMemberModalOpen,
    isGenderOpen,
    openCloseGenderModal,
    createPatientMember,
    openCloseAddMemberModal,
    profileDetailHandler,
    saveGenderValue,
  } = useCreatePatientMember();

  const { isMobile } = useIsMobile();

  const dispatch = useAppDispatch();
  const paymentMethodConfig: any = useAppSelector((state) => state.config);
  const checkoutDetails = useAppSelector(
    (state) => state.findDoctor.checkoutPatientDetail
  );
  const memberList = useAppSelector((state) => state.member);
  const doctorProfileInfo = useAppSelector((state) => state.doctorProfile);
  const doctorProfile = doctorProfileInfo?.detail;

  const handlePaymentSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMode((event.target as HTMLInputElement).value);
  };

  const closeModal = () => {
    const isModalOpen: any = {};
    for (let key in isOpenModal) {
      isModalOpen[key] = false;
    }
    setIsOpenModal(isModalOpen);
  };

  const fetchPatientList = async () => {
    await dispatch(fetchPatientMembers());
  };

  const handleReserveAppointment = async () => {
    const reserveAppointmentPatientResponse =
      await reserveAppointmentFromPatient({
        doctorId: doctorProfile?.id,
        memberId: checkoutDetails?.memberId,
        slotId: checkoutDetails?.slotId,
        consultationType:
          checkoutDetails?.type === IDoctorAvailabilityPlatform.VIDEO_CALL
            ? IDoctorScheduleConsultationType.VIDEO_CALL
            : IDoctorScheduleConsultationType.IN_CLINIC,
        paymentMethodId: +paymentMode!,
        paymentAmount: checkoutDetails?.fee,
        ...(checkoutDetails?.type === IDoctorAvailabilityPlatform.IN_CLINIC && {
          clinicId: checkoutDetails?.clinicId,
        }),
      });
    if ("result" in reserveAppointmentPatientResponse) {
      notify(INotifyEnum.SUCCESS, reserveAppointmentPatientResponse.message);
      setIsOpenModal({ ...isOpenModal, success: true });
      setSlotNo(reserveAppointmentPatientResponse.result.slotNo);
      storage.setSessionToken(
        "bookingId",
        reserveAppointmentPatientResponse.result.bookingId
      );
    } else {
      notify(INotifyEnum.ERROR, reserveAppointmentPatientResponse.message);
    }
  };

  const checkoutScreen = () => (
    <Grid container p={2}>
      <Grid item xs={12}>
        <Header heading="Checkout" onGoBack={closeHandler} />
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
        >
          <Grid item xs={2}>
            <GenericCardHeader
              image={doctorProfile?.photo_url}
              name={""}
              bio={""}
              languages={""}
              status={IDoctorProfileStatus.VERIFIED}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1" fontWeight={500}>
              {doctorProfile?.name}
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              flexWrap={"wrap"}
            >
              <Typography variant="caption" color={"secondary"} noWrap>
                {doctorProfile?.experience_years}{" "}
                {intl.formatMessage({
                  id: "years",
                  defaultMessage: "years",
                })}
              </Typography>
              &bull;
              <Typography variant="caption" color={"secondary"} noWrap>
                {doctorProfile?.speciality}
              </Typography>
              &bull;
              {doctorProfile?.qualification_details.map((item) => (
                <>
                  <Typography variant="caption" color={"secondary"} noWrap>
                    {item.certificate}
                  </Typography>
                  &bull;
                </>
              ))}
              {doctorProfile?.language.map((item) => (
                <>
                  <Typography variant="caption" color={"secondary"} noWrap>
                    {item}
                  </Typography>
                  &bull;
                </>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Grid item className={style.consultationDetailContainer}>
          <Typography component={"p"}>
            {intl.formatMessage({
              id: "consultation_detail",
              defaultMessage: "Consultation Details",
            })}
          </Typography>
          <Grid item className={style.innerContainer}>
            <Grid item className={style.consultationDetailItem}>
              <Grid item>
                <GetImages
                  name="ScheduleSelectedSmallIcon"
                  width="20"
                  height="16"
                />
              </Grid>
              <Typography component={"p"}>
                {checkoutDetails?.dateSlotTime}
              </Typography>
            </Grid>
            <Grid item className={style.consultationDetailItem}>
              <Grid item>
                <GetImages
                  name={
                    checkoutDetails?.type ===
                    IDoctorAvailabilityPlatform.VIDEO_CALL
                      ? "VideoIcon"
                      : "ClinicSmallIcon"
                  }
                  width="20"
                  height="16"
                />
              </Grid>
              <Typography component={"p"}>
                {checkoutDetails?.type ===
                IDoctorAvailabilityPlatform.VIDEO_CALL
                  ? IDoctorConsultationSelection.VIDEO_CONSULTATION
                  : checkoutDetails?.clinicName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={style.patientPayableContainerSelected}>
          <Typography component={"p"}>
            {intl.formatMessage({
              id: "apply",
              defaultMessage: "Apply",
            })}
          </Typography>
          <Grid item className={style.patientDetail}>
            <Typography component={"p"} className={style.patientName}>
              {checkoutDetails?.name} (Main)
            </Typography>
            <Typography
              component={"p"}
              className={style.changePatient}
              onClick={() =>
                setIsOpenModal({
                  ...isOpenModal,
                  memberList: !isOpenModal.memberList,
                })
              }
            >
              {intl.formatMessage({
                id: "change",
                defaultMessage: "change",
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={style.patientPayableContainer}>
          <Grid item className={style.totalPayableContainer}>
            <Typography component={"p"}>
              {intl.formatMessage({
                id: "total_payable",
                defaultMessage: "Total Payable",
              })}
            </Typography>
            <Typography component={"p"}>₹ {checkoutDetails?.fee}</Typography>
          </Grid>
          <Grid item className={style.paymentModeSelection}>
            <RadioGroup value={paymentMode} onChange={handlePaymentSelection}>
              {paymentMethodConfig?.config[
                isMobile ? location.state : type
              ]?.map((item: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={item.id}
                  control={
                    <Radio
                      checkedIcon={
                        <GetImages
                          name="TickOrangeIcon"
                          width="20"
                          height="20"
                        />
                      }
                      icon={
                        <GetImages name="RadioBtnIcon" width="20" height="20" />
                      }
                    />
                  }
                  label={
                    <Typography
                      component={"p"}
                      className={style.paymentModeLabel}
                    >
                      {item.frontendText}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item>
          <Button disabled={!paymentMode} btnTrigger={handleReserveAppointment}>
            <GetImages name="ChekoutBtnIcon" width="28" height="20" />
            <FormattedMessage id="next" defaultMessage="Next" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  useEffect(() => {
    fetchPatientList();
    if (
      location.state === IFilterType.VIDEO_CALL ||
      location.state === IFilterType.IN_CLINIC ||
      type
    ) {
      dispatch(fetchConfiguration(type ? type : location.state));
    }
  }, [location, type]);

  useEffect(() => {
    const memberInfo = memberList.list.filter(
      (member, index) => member.is_default
    )[0];

    dispatch(
      checkoutDetail({
        ...checkoutDetails,
        name: memberInfo?.name,
      })
    );
  }, [memberList]);

  if (isOpenModal.success) {
    return (
      <Stack gap={1} p={2}>
        <Stack justifyContent={"center"} alignItems={"center"} gap={0.5}>
          <GetImages name="SlotAllocationIcon" width="70" height="70" />
          <Typography variant="caption" color={"secondary"}>
            <FormattedMessage
              id="slot_alloted"
              defaultMessage={"Slot Number Alloted"}
            />
          </Typography>
          <Typography variant="subtitle2" color={"success.main"}>
            <FormattedMessage
              id="clinit_appointment_success"
              defaultMessage={"Your Clinic Visit is successful Booked"}
            />
          </Typography>
        </Stack>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography variant="subtitle1" fontWeight={500}>
            {doctorProfile?.name}
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
            width={"100%"}
          >
            <Typography variant="caption" color={"secondary"} noWrap>
              {doctorProfile?.experience_years} Yrs
            </Typography>
            &bull;
            <Typography variant="caption" color={"secondary"} noWrap>
              {doctorProfile?.speciality}
            </Typography>
            &bull;
            {doctorProfile?.qualification_details.map((item) => (
              <>
                <Typography variant="caption" color={"secondary"} noWrap>
                  {item.certificate}
                </Typography>
                &bull;
              </>
            ))}
            {doctorProfile?.language.map((item) => (
              <>
                <Typography variant="caption" color={"secondary"} noWrap>
                  {item}
                </Typography>
                &bull;
              </>
            ))}
          </Stack>

          <Typography variant="subtitle1" fontWeight={500}>
            {checkoutDetails.dateSlotTime}
          </Typography>
        </Stack>
        <MUIButton
          variant={"contained"}
          color="primary"
          sx={{ py: 1, width: "80%", mx: "auto" }}
          onClick={() => navigate("/home")}
        >
          <FormattedMessage
            id="goto_homepage"
            defaultMessage={"Go to Home Page"}
          />
        </MUIButton>
      </Stack>
    );
  }

  return (
    <>
      {checkoutScreen()}
      {isOpenModal.memberList && (
        <ChangePatient
          isOpen={isOpenModal.memberList}
          closeHandler={closeModal}
          handleAddMember={openCloseAddMemberModal}
        />
      )}
      {isAddMemberModalOpen && (
        <AddEditProfile
          isOpen={isAddMemberModalOpen}
          closeHandler={openCloseAddMemberModal}
          title="Add new Profile"
          age={profileDetail.age}
          gender={profileDetail.gender}
          name={profileDetail.name}
          isGenderOpen={isGenderOpen}
          saveGender={saveGenderValue}
          saveProfile={createPatientMember}
          openCloseGenderModal={openCloseGenderModal}
          profileDetailHandler={profileDetailHandler}
        />
      )}
    </>
  );
};

export default Checkout;
