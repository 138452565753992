import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EnterMobile from "../../../../components/Verification/VerifyMobile";
import VerifyOTP from "../../../../components/Verification/VerifyOTP";

import {
  IAddPatient,
  IPatientExistOrNotModule,
} from "../../../../models/doctor";
import { IMobileVerify, IOtpVerify } from "../../../../models/onboarding";

import {
  fetchDoctorHospitalListing,
  fetchHospitalRoomsListing,
} from "../../../../store/slices/doctor/ipDashboard";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  resetPatientDetailErrorMessages,
  setModuleName,
} from "../../../../store/slices/doctor/checkMobilePatientExistOrNot";
import { setAddPatient } from "../../../../store/slices/doctor/topNavigation";

import { INotifyEnum, notify } from "../../../../utils/toaster";
import { IAPIStatusCode } from "../../../../utils/apis/APIEndpointConfig";
import useMobileCheckPatientCreate from "../../../../utils/hooks/useMobileCheckPatientCreate";
import useIsMobile from "../../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../../utils/hooks/useIsTablet";

const PreAdmitPatientPopUps: React.FC = () => {
  const [mobileNo, setMobileNo] = useState<string>("");

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const { selectedHospitalId, hospitals } = useAppSelector(
    (state) => state.ipDashboard
  );
  const { checkNumberError, moduleName } = useAppSelector(
    (state) => state.patientExistOrNot
  );
  const { mobileCheck, otpVerify } = useAppSelector(
    (state) => state.topNavigation.addPatient
  );

  const dispatch = useAppDispatch();

  const {
    checkMobileNumberDetail,
    createPatientAfterVerifyOtp,
    sendOtpToCreatePatient,
  } = useMobileCheckPatientCreate({ mobileNo });

  const openCloseMobileCheckOTPVerify = (
    field: IAddPatient,
    value: boolean
  ) => {
    dispatch(
      setAddPatient({
        field,
        value,
      })
    );
  };

  const checkMobileNumberSubmission = (values: IMobileVerify) => {
    setMobileNo(values.mobileNo);
    checkMobileNumberDetail(values.mobileNo);
    dispatch(setModuleName(IPatientExistOrNotModule.ADMIT_PATIENT));
    if (checkNumberError?.statusCode === IAPIStatusCode.BAD_REQUEST) {
      sendOtpToCreatePatient();
      openCloseMobileCheckOTPVerify(IAddPatient.OTP_VERIFY, true);
    }
    dispatch(resetPatientDetailErrorMessages());
  };

  const otpSubmitHandler = (value: IOtpVerify) => {
    createPatientAfterVerifyOtp(value.otp);
    openCloseMobileCheckOTPVerify(IAddPatient.OTP_VERIFY, false);
    openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, false);
    dispatch(
      fetchHospitalRoomsListing({
        hospitalId: selectedHospitalId,
        available: true,
      })
    );
    if (isMobile) {
      navigate("/admit-patient");
    } else {
      openCloseMobileCheckOTPVerify(IAddPatient.ADMIT_PATIENT, true);
    }
  };

  useEffect(() => {
    if (moduleName === IPatientExistOrNotModule.ADMIT_PATIENT) {
      if (!checkNumberError) return;
      const { message, statusCode } = checkNumberError;
      statusCode === IAPIStatusCode.BAD_REQUEST
        ? (sendOtpToCreatePatient(),
          openCloseMobileCheckOTPVerify(IAddPatient.OTP_VERIFY, true))
        : statusCode === IAPIStatusCode.FORBIDDEN
        ? notify(INotifyEnum.ERROR, message)
        : statusCode === IAPIStatusCode.SUCCESS &&
          (isMobile || isTablet
            ? navigate("/admit-patient")
            : openCloseMobileCheckOTPVerify(IAddPatient.ADMIT_PATIENT, true),
          dispatch(
            fetchHospitalRoomsListing({
              hospitalId: selectedHospitalId,
              available: true,
            })
          ),
          openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, false));
    }
  }, [checkNumberError, moduleName]);

  useEffect(() => {
    if (hospitals.length === 0) {
      dispatch(fetchDoctorHospitalListing());
    }
  }, [dispatch]);
  return (
    <>
      {mobileCheck && (
        <EnterMobile
          isOpen={mobileCheck}
          closeHandler={() => {
            openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, false);
            dispatch(resetPatientDetailErrorMessages());
            dispatch(setModuleName(""));
          }}
          mobileNo={mobileNo}
          heading="enter_patient_contact"
          btnLabel="check_number"
          showCountryCode={false}
          showHeaderNavigation
          headerNavigationText={"admit_patient"}
          mobileSubmitHandler={checkMobileNumberSubmission}
        />
      )}
      {otpVerify && (
        <VerifyOTP
          handleOTPSubmit={otpSubmitHandler}
          mobileNo={mobileNo}
          navigateToMobileVerify={() => {
            openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, true);
            openCloseMobileCheckOTPVerify(IAddPatient.OTP_VERIFY, false);
            dispatch(resetPatientDetailErrorMessages());
          }}
          resendOTP={sendOtpToCreatePatient}
        />
      )}
    </>
  );
};

export default PreAdmitPatientPopUps;
