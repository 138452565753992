import React from "react";
import { FormControlLabel, Grid, Radio, Typography } from "@mui/material";

import GetImages from "../../GetImages";

import style from "./index.module.css";

import { IPatientMemberDetailEnums } from "../../../models/patient";

import { transformClasses } from "../../../utils";

interface IProps {
  type: IPatientMemberDetailEnums;
  memberId: string;
  name: string;
  age: number;
}
const MemberDetailTile: React.FC<IProps> = (props) => {
  const { type, memberId, name, age } = props;

  const radioSelectionScreen = () => (
    <FormControlLabel
      value={memberId}
      className={
        type === IPatientMemberDetailEnums.CHECKOUT
          ? style.checkoutRadioButton
          : style.homeRadioButton
      }
      control={
        <Radio
          checkedIcon={
            <GetImages name="TickOrangeIcon" width="20" height="20" />
          }
          icon={<GetImages name="RadioBtnIcon" width="20" height="20" />}
        />
      }
      label={<></>}
    />
  );
  return (
    <Grid
      item
      className={
        type === IPatientMemberDetailEnums.CHECKOUT
          ? transformClasses(style.card, style.checkoutCard)
          : transformClasses(style.card, style.homeCard)
      }
    >
      {type === IPatientMemberDetailEnums.CHECKOUT && radioSelectionScreen()}
      <Typography component={"p"} className={style.patientName}>
        {type === IPatientMemberDetailEnums.HOME ? name?.slice(0, 10) : name}
      </Typography>
      <Typography
        component={"p"}
        className={transformClasses(
          style.patientAge,
          type === IPatientMemberDetailEnums.HOME ? style.patientAgeHome : ""
        )}
      >
        Age {age}{" "}
        {type === IPatientMemberDetailEnums.HOME && (
          <Grid item className={style.radioWrapper}>
            {radioSelectionScreen()}
          </Grid>
        )}
      </Typography>
    </Grid>
  );
};

export default MemberDetailTile;
