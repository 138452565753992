import React, { useMemo, useState } from "react";
import MUIButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import { InputField } from "../../InputField";
import Button from "../../Button";
import GetImages from "../../GetImages";

import { IDiagnosticTestDetail } from "../../../models/ApiRequestResponse/diagnostic";
import { IOrderStatusEnum } from "../../../models/ApiRequestResponse/pharmacy";
import { IButtonVariant } from "../../../models/button";

import useHandlePharmacyOrderUpdate from "../../../utils/hooks/useHandlePharmacyOrderUpdate";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { saveSelectedOrder } from "../../../store/slices/pharmacyClinicDiagnostic";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";

const PaymentDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { order } = useAppSelector((state) => state.pharmacyClinicDiagnostic);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState({
    discount: null,
    totalPayable: null,
  });
  const { handlePharmacyOrderUpdate } = useHandlePharmacyOrderUpdate();
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const handleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateOrderStatus = async () => {
    handlePharmacyOrderUpdate(IOrderStatusEnum.COMPLETED);
    handleModal();
    dispatch(
      saveSelectedOrder({
        ...order,
        status: IOrderStatusEnum.COMPLETED,
      })
    );
  };

  const isWebView = useMemo(
    () => !(isMobile || isTablet),
    [isMobile, isTablet]
  );

  if (isWebView) {
    return (
      <>
        <Stack className={style.container} borderRadius={2}>
          <Grid container p={1}>
            <Grid item xs={12} className={style.paymentContainer}>
              <Typography variant={"subtitle1"}>Payment Details</Typography>
              {order?.diagnostic_test_details?.map(
                (test: IDiagnosticTestDetail, index: number) => (
                  <Grid
                    item
                    className={style.paymentDetails}
                    key={`${test}-${index}`}
                  >
                    <Typography variant={"subtitle2"}>{test?.name}</Typography>
                    <Typography variant={"subtitle2"}>
                      Rs. {test?.price}
                    </Typography>
                  </Grid>
                )
              )}
            </Grid>
            <Grid item xs={12} className={style.totalPaymentDetails}>
              <Typography variant={"subtitle2"}>Total</Typography>
              <Typography variant={"subtitle2"}>
                Rs. {order?.total_amount}
              </Typography>
            </Grid>
          </Grid>
          {order.status !== IOrderStatusEnum.PENDING && (
            <Stack
              p={1}
              flexGrow={1}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              display={{ xs: "none", md: "flex" }}
            >
              {order.status === IOrderStatusEnum.COMPLETED && (
                <MUIButton variant={"outlined"} color="primary">
                  <GetImages name="TickOrangeIcon" width="26" height="20" />
                  <FormattedMessage
                    defaultMessage={"Order Completed"}
                    id="order_complete"
                  />
                </MUIButton>
              )}
              {(order.status === IOrderStatusEnum.CONFIRMED ||
                order.status === IOrderStatusEnum.UPLOADED) && (
                <MUIButton
                  variant={"contained"}
                  color="primary"
                  onClick={handleModal}
                >
                  <FormattedMessage
                    defaultMessage={"Mark Order Complete"}
                    id="mark_order_complete"
                  />
                </MUIButton>
              )}
              {order?.status === IOrderStatusEnum.REJECTED && (
                <MUIButton variant={"outlined"} color="primary">
                  <GetImages
                    name={"DiagnosticOrderRejectIcon"}
                    width="26"
                    height="20"
                  />
                  <Typography
                    component="p"
                    color={"primary"}
                    textAlign={"center"}
                  >
                    <FormattedMessage
                      defaultMessage={"Order Rejected"}
                      id="order_rejected"
                    />{" "}
                  </Typography>
                </MUIButton>
              )}
            </Stack>
          )}
        </Stack>
        <Dialog
          open={isModalOpen}
          onClose={handleModal}
          maxWidth="xs"
          fullWidth
          PaperProps={{
            sx: { p: 2, borderRadius: { md: 2 } },
          }}
        >
          <Stack gap={2}>
            <Typography variant="h6" gutterBottom>
              <FormattedMessage
                defaultMessage={"Update Payment Details"}
                id="update_payment_details"
              />
            </Typography>
            <Grid container>
              <Grid item xs={12} className={style.paymentContainer}>
                <Typography variant={"subtitle1"} fontWeight={500}>
                  Payment Details
                </Typography>
                {order?.diagnostic_test_details?.map(
                  (test: IDiagnosticTestDetail, index: number) => (
                    <Grid
                      item
                      className={style.paymentDetails}
                      key={`${test}-${index}`}
                    >
                      <Typography variant={"subtitle2"} fontWeight={400}>
                        {test?.name}
                      </Typography>
                      <Typography variant={"subtitle2"} fontWeight={400}>
                        Rs. {test?.price}
                      </Typography>
                    </Grid>
                  )
                )}
              </Grid>
              <Grid item xs={12} className={style.totalPaymentDetails}>
                <Typography variant={"subtitle2"} fontWeight={400}>
                  Total
                </Typography>
                <Typography variant={"subtitle2"} fontWeight={400}>
                  Rs. {order?.total_amount}
                </Typography>
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <InputField
                label="Discount"
                name="discount"
                value={values.discount}
                onChange={handleChange}
                backgroundColor="secondary"
                className={style.inputBackground}
              />
              <InputField
                label="Total Payable"
                name="totalPayable"
                value={values.totalPayable}
                onChange={handleChange}
                backgroundColor="secondary"
                className={style.inputBackground}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Button variant={IButtonVariant.WHITE} btnTrigger={handleModal}>
                <FormattedMessage defaultMessage={"Cancel"} id="cancel" />
              </Button>
              <Button
                variant={IButtonVariant.ORANGE}
                btnTrigger={handleUpdateOrderStatus}
              >
                <FormattedMessage defaultMessage={"Update"} id="update" />
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      </>
    );
  }

  return (
    <Grid item className={style.paymentContainer}>
      <Typography component={"h4"}>Payment Details</Typography>
      {order?.diagnostic_test_details?.map(
        (test: IDiagnosticTestDetail, index: number) => (
          <Grid item className={style.paymentDetails} key={`${test}-${index}`}>
            <Typography component={"p"}>{test?.name}</Typography>
            <Typography component={"h5"}>Rs. {test?.price}</Typography>
          </Grid>
        )
      )}
      <Grid item className={style.totalPaymentDetails}>
        <Typography component={"p"}>Total</Typography>
        <Typography component={"h5"}>Rs. {order?.total_amount}</Typography>
      </Grid>
    </Grid>
  );
};

export default PaymentDetail;
