import React, { useEffect, useState } from "react";
import { BottomNavigation as Navigation } from "@mui/material";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { style } from "./style";

import GetImages from "../../../GetImages";

import { IRoleType } from "../../../../models/role";

import { storage } from "../../../../utils/Storage";
import { bottomNavigation } from "../../../../utils/common";

interface INavItemInfo {
  path: string;
  icon: string;
  selectedIcon: string;
  label: string;
}
const MobileBottomNavigation: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const intl = useIntl();

  const [navIndex, setNavIndex] = useState<number>(
    location.state?.navItem ?? 0
  );
  const [navItems, setNavItems] = useState<INavItemInfo[]>();

  const roleType = storage.getSessionToken("roleType");
  const hasRolesType =
    roleType === IRoleType.PATIENT ||
    roleType === IRoleType.DOCTOR ||
    roleType === IRoleType.JR_DOCTOR ||
    roleType === IRoleType.PHARMACY;

  const navigateToPage = () => {
    if (!roleType) {
      navigate(bottomNavigation[IRoleType.PATIENT][navIndex]?.path);
      return;
    }
    if (hasRolesType) {
      navigate(bottomNavigation[roleType][navIndex]?.path);
    }
  };

  const setNavigationItemsByRoleType = () => {
    if (!roleType) {
      setNavItems(bottomNavigation[IRoleType.PATIENT]);
      return;
    }
    if (hasRolesType) {
      setNavItems(bottomNavigation[roleType]);
    }
  };

  useEffect(() => {
    navigateToPage();
  }, [navIndex]);

  useEffect(() => {
    if (location?.state?.navItem) {
      setNavIndex(location?.state?.navItem);
    }
  }, [location.state]);

  useEffect(() => {
    setNavigationItemsByRoleType();
  }, []);
  return (
    <Grid item sx={style.navigationContainer}>
      <Navigation
        showLabels
        sx={style.navigation}
        value={navIndex}
        onChange={(event, newValue) => setNavIndex(newValue)}
      >
        {navItems
          ? navItems.map((nav, index) => (
              <BottomNavigationAction
                label={intl.formatMessage({
                  id: nav.label,
                  defaultMessage: "Bottom Navigation Action",
                })}
                icon={
                  navIndex === index ? (
                    <GetImages
                      fill="var(--orange-500)"
                      name={nav.selectedIcon}
                      width="22"
                      height="22"
                    />
                  ) : (
                    <GetImages
                      fill="var(--gray-400)"
                      name={nav.icon}
                      width="22"
                      height="22"
                    />
                  )
                }
                key={index}
                sx={
                  navIndex === index
                    ? style.navigationSelectedText
                    : style.navigationText
                }
              />
            ))
          : null}
      </Navigation>
    </Grid>
  );
};

export default MobileBottomNavigation;
