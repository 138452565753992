import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import OnboardingRoutes from "./onboarding";
import PatientRoutes from "./patient";
import DoctorRoutes from "./doctor";
import ClinicRoutes from "./clinic";
import CommonRoutes from "./common";

import MobileTopNavigation from "../components/Navigation/Mobile/MobileTopNavigation";
import MobileBottomNavigation from "../components/Navigation/Mobile/MobileBottomNavigation";
import DoctorPharmacyDiagnosticTopNav from "../components/Navigation/Desktop/DesktopTopNavigation/DoctorPharmacyDiagnosticTopNav";
import DesktopBottomNavigation from "../components/Navigation/Desktop/DesktopBottomNavigation";
import PatientTopNav from "../components/Navigation/Desktop/DesktopTopNavigation/PatientTopNav";
import CopyWriteFooter from "../components/Navigation/Desktop/DesktopBottomNavigation/CopyWriteFooter";
import LocationAccess from "../components/LocationAccess";

import { storage } from "../utils/Storage";
import useIsMobile from "../utils/hooks/useIsMobile";
import useIsTablet from "../utils/hooks/useIsTablet";
import { isClinic } from "../utils";

import { IRoleType } from "../models/role";

import { routes } from "./routes";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setIsLocationAccess } from "../store/slices/doctor/topNavigation";

const Router: React.FC = () => {
  const location = useLocation();
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const currentPath = location.pathname;
  const roleType = storage.getSessionToken("roleType");
  const dispatch = useAppDispatch();
  const { isLocationAccessOpen } = useAppSelector(
    (state) => state.topNavigation
  );

  const {
    doctorBottomNavRoutes,
    doctorRoutes,
    hideTopNavRoutes,
    onboardingRoutes,
    patientRoutes,
    pharmacyTopNavRoutes,
    routesWithoutNavsOnMobile,
  } = routes;

  const isOnboarding = onboardingRoutes.includes(currentPath);
  const isPatientRoute = patientRoutes.includes(currentPath);
  const isRoutesWithoutNavsOnMobile =
    routesWithoutNavsOnMobile.includes(currentPath);
  const isPharmacyTopNavRoute = pharmacyTopNavRoutes.includes(currentPath);
  const isDoctorRoute = doctorRoutes.includes(currentPath);
  const isHideTopNav = hideTopNavRoutes.includes(currentPath);
  const showBottomNavForDoctor = doctorBottomNavRoutes.includes(currentPath);

  const allRoleExceptPatient =
    roleType === IRoleType.DOCTOR ||
    roleType === IRoleType.JR_DOCTOR ||
    roleType === IRoleType.PHARMACY ||
    roleType === IRoleType.DIAGNOSTIC_CENTER;

  const isClinicRole = isClinic();

  return (
    <>
      {!isOnboarding && !isHideTopNav && (
        <>
          {isMobile && !isRoutesWithoutNavsOnMobile && isPatientRoute && (
            <>
              <MobileTopNavigation />
              <MobileBottomNavigation />
            </>
          )}
          {isTablet && isPatientRoute && (
            <>
              <MobileTopNavigation />
              <MobileBottomNavigation />
            </>
          )}
          {!isMobile && !isTablet && isPatientRoute && <PatientTopNav />}

          {isDoctorRoute && (isMobile || isTablet) && <MobileTopNavigation />}
        </>
      )}
      {(isMobile || isTablet) && isPharmacyTopNavRoute && (
        <MobileTopNavigation />
      )}
      {allRoleExceptPatient && !isMobile && !isTablet && (
        <DoctorPharmacyDiagnosticTopNav />
      )}
      {(showBottomNavForDoctor || isClinicRole) && (isMobile || isTablet) && (
        <MobileBottomNavigation />
      )}
      <Routes>
        {[
          ...OnboardingRoutes,
          ...PatientRoutes,
          ...DoctorRoutes,
          ...ClinicRoutes,
          ...CommonRoutes,
        ].map((route, index) => (
          <Route path={route.path} element={route.component} key={index} />
        ))}
      </Routes>
      {!(isMobile || isTablet) && isPatientRoute && <DesktopBottomNavigation />}

      {!(isMobile || isTablet) && allRoleExceptPatient && <CopyWriteFooter />}

      {isLocationAccessOpen && (
        <LocationAccess
          isOpen={isLocationAccessOpen}
          closeHandler={() => dispatch(setIsLocationAccess(false))}
        />
      )}
    </>
  );
};

export default Router;
