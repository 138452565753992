import React from "react";
import { Grid } from "@mui/material";

import Loader from "../../../../components/Loader";
import HealthHubSearchCard from "../HealthHubSearchCard";
import ListWithIntersection from "../../../../components/ListWithIntersection";

import style from "./index.module.css";

import { IHealthHubPlaylistResult } from "../../../../models/ApiRequestResponse/patient";

import { useAppSelector } from "../../../../store/hooks";
type HealthHubSearchListProps = {
  onLoadMore: () => void;
  onCardClick: () => void;
};

const HealthHubSearchList: React.FC<HealthHubSearchListProps> = ({
  onLoadMore,
  onCardClick,
}) => {
  const { searchData, isFetchingData } = useAppSelector(
    (state) => state.healthHub
  );

  const playlistData =
    searchData?.result?.playlist?.map((i: IHealthHubPlaylistResult) => {
      return {
        ...i,
        thumbnailUrl: i.data[0].thumbnailUrl,
        type: "playlist",
      };
    }) || [];

  const videos = searchData?.result?.video || [];

  const mappableData = [...playlistData, ...videos];

  return (
    <>
      <Grid
        className={style.container}
        sx={{
          gap: {
            xs: "0rem",
            sm: "1rem",
            md: "2rem",
            lg: "2rem",
            xl: "2rem",
          },
        }}
      >
        <ListWithIntersection
          list={mappableData ?? []}
          onRender={(item: any) => {
            const category =
              item?.type === "playlist"
                ? "Playlist"
                : item?.type === "long"
                ? "Video"
                : "Short";
            return (
              <HealthHubSearchCard
                key={item?.id}
                onClick={onCardClick}
                id={item?.id}
                title={item?.name}
                category={category}
                thumbnailUrl={item?.thumbnailUrl}
              />
            );
          }}
          onLoadMore={onLoadMore}
        />
      </Grid>
      {isFetchingData && (
        <Grid sx={{ width: "100%", marginTop: "4rem" }}>
          <Loader height="2rem" />
        </Grid>
      )}
    </>
  );
};

export default HealthHubSearchList;
