import { useEffect, useState } from "react";

const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      let x = window.matchMedia("(min-width: 600px) and (max-width: 899px)");
      setIsTablet(x.matches);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { isTablet };
};

export default useIsTablet;
