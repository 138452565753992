import React, { useState, useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Modal from "../Modal";
import Button from "../Button";
import GridIconText from "../GridIconText";
import GetImages from "../GetImages";
import Dialog from "../Dialog";

import style from "./index.module.css";

import { transformClasses } from "../../utils";
import { styles } from "../../utils/styles";
import { storage } from "../../utils/Storage";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { locationAccessDetail } from "../../utils/common";
import useIsTablet from "../../utils/hooks/useIsTablet";

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
}
const LocationAccess: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const errorGetLocationAccess = (error: any) => {
    setIsLoading(false);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
    }
  };

  const successGetLocationAccess = (position: any) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    storage.setSessionToken(
      "location",
      JSON.stringify({ latitude, longitude })
    );
    closeHandler();

    navigate("/find-doctor", { state: { navItem: 1 } });
  };

  const getLocation = () => {
    setIsLoading(true);
    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGetLocationAccess,
        errorGetLocationAccess
      );
    } else {
      setIsLoading(false);
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const locationAccessScreen = useCallback(() => {
    return (
      <>
        <Grid item className={style.locationHeader}>
          <Grid item>
            <Grid item className={style.header}>
              <GetImages name="LocationAccessIcon" width="36" height="36" />
              <Typography component={"h3"} className={style.heading}>
                <FormattedMessage
                  id="enableLocationAccess"
                  defaultMessage="Enable Location Access"
                />
              </Typography>
            </Grid>
            <Typography component={"p"} className={style.subHeading}>
              <FormattedMessage
                id="locationAccessSubHead"
                defaultMessage="To provide you with the best experience, we need access to your
              location. This helps us to:"
              />
            </Typography>
          </Grid>
          <Grid item className={style.locationGridContainer}>
            {locationAccessDetail.map((info, index) => (
              <GridIconText key={`${info.title}-${index}`} {...info} />
            ))}
          </Grid>
          <Grid item className={style.locationMiddleText}>
            <Typography
              component={"p"}
              className={transformClasses(
                style.locationAccessText,
                style.locationLabeling
              )}
            >
              <FormattedMessage
                id="privacyLocationText"
                defaultMessage="We respect your privacy. Your location data will be used only for
              these purposes and will not be shared with third parties."
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid item className={style.buttonContainer}>
          <Typography
            component={"p"}
            className={transformClasses(
              style.locationAccessText,
              style.locationLabeling
            )}
            sx={styles.mb20}
          >
            <FormattedMessage
              id="allowAccessText"
              defaultMessage="By allowing access, you agree to our"
            />
            <Typography
              component={"span"}
              className={transformClasses(
                style.orangeText,
                style.locationLabeling
              )}
            >
              {" "}
              <FormattedMessage
                id="privacyPolicyText"
                defaultMessage="Privacy Policy"
              />
            </Typography>
          </Typography>
          <Button isLoading={isLoading} btnTrigger={getLocation}>
            <FormattedMessage
              id="allowLocationBtn"
              defaultMessage="Allow Location Access"
            />
          </Button>
        </Grid>
      </>
    );
  }, []);
  return (
    <>
      {isMobile || isTablet ? (
        <Modal lineBottomBackground="white">{locationAccessScreen()}</Modal>
      ) : (
        <Dialog
          fullWidth
          maxWidth="xs"
          isOpen={isOpen}
          closeHandler={closeHandler}
        >
          {locationAccessScreen()}
        </Dialog>
      )}
    </>
  );
};

export default LocationAccess;
