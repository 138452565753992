import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import Loader from "../../../components/Loader";
import DoctorReviews from "../../../components/DoctorReviews";
import GetImages from "../../../components/GetImages";
import ChipSelect from "../../../components/ChipSelect";
import PermissionWrapper from "../../../components/PermissionWrapper";
import DropDownSelect from "../../../components/DropDownSelect";
import SettingPreferences from "../../Patient/SettingPreferences";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDoctorProfile,
  fetchDoctorReviewRatingList,
} from "../../../store/slices/doctor/profile";
import { setShowPermissionAlert } from "../../../store/slices/permission";
import { setIsSettingPreferencesOpenClose } from "../../../store/slices/config";

import { doctorDegreeName, getPermissionAccess } from "../../../utils";
import useDebounce from "../../../utils/hooks/useDebouncer";

import { IChipVariant } from "../../../models/button";
import {
  IDoctorPermissionEnums,
  IReviewPermissionEnums,
  IRolePermissionAccess,
  IModalKeyEnums,
} from "../../../models/permission";
import { IDropDownSelectionType } from "../../../models/doctor";

import useIsTablet from "../../../utils/hooks/useIsTablet";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import DateRangePicker from "../../../components/DateRangePicker";
import { storage } from "../../../utils/Storage";

enum IRatingOptionEnum {
  ALL = "ALL",
  REVIEW = "REVIEW",
  NOT_REPLIED = "0",
}
const ratingFilterOptions = [
  {
    label: "All",
    value: IRatingOptionEnum.ALL,
    isSelection: false,
  },
  {
    label: "Review",
    value: IRatingOptionEnum.REVIEW,
    isSelection: true,
  },
  {
    label: "Not Replied",
    value: IRatingOptionEnum.NOT_REPLIED,
    isSelection: false,
  },
];

const Profile: React.FC = () => {
  const [ratingFilterSelectedOption, setRatingFilterSelectedOption] =
    useState<string>(ratingFilterOptions[0].value);
  const [isSearchReviewOn, setIsSearchReviewOn] = useState<boolean>(false);
  const [searchReview, setSearchReview] = useState<string>("");
  const [openReviewRatingList, setOpenReviewRatingList] =
    useState<boolean>(false);
  const [ratingValue, setRatingValue] = useState<string>("");
  const debouncedDoctorSearch = useDebounce(searchReview, 1000);
  const doctorId = storage.getSessionToken("doctor_id");
  const [showDateRangeSelector, setShowDateRangeSelector] =
    useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const dateSelectorModalRef = useRef<HTMLDivElement>(null);
  const [selectedDayText, setSelectedDayText] = useState<string>("Calender");
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();
  const { isMobile } = useIsMobile();

  const dispatch = useAppDispatch();
  const doctorProfileInfo = useAppSelector((state) => state.doctorProfile);
  const { permission } = useAppSelector((state) => state.rolePermission);
  const { isSettingPreferencesOpenClose } = useAppSelector(
    (state) => state.config
  );

  const doctorProfile = doctorProfileInfo?.detail;

  const viewDoctorProfileDetail = () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.DOCTOR,
      IDoctorPermissionEnums.GET_DOCTOR_PROFILE_BY_DOCTOR,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    navigate("/profile-detail");
  };

  const handleClickDateRangeOutside = (event: MouseEvent) => {
    if (
      dateSelectorModalRef.current &&
      !dateSelectorModalRef.current.contains(event.target as Node)
    ) {
      setSelectedDayText("Calender");
      setShowDateRangeSelector(false);
    }
  };

  const openCloseDateRangeSelector = () => {
    if (showDateRangeSelector) {
      document.removeEventListener("mousedown", handleClickDateRangeOutside);
    } else {
      document.addEventListener("mousedown", handleClickDateRangeOutside);
    }
    setShowDateRangeSelector(!showDateRangeSelector);
  };
  const formatDateToCustomText = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  };

  const submitDateRangeValues = (startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const formattedText =
      startDate !== endDate
        ? `${formatDateToCustomText(startDate)} - ${formatDateToCustomText(
            endDate
          )}`
        : `${formatDateToCustomText(startDate)}`;
    setSelectedDayText(formattedText);
    openCloseDateRangeSelector();
  };

  const openCloseReviewRatingModal = () => {
    setOpenReviewRatingList(!openReviewRatingList);
  };

  const closeHandler = () => {
    openCloseReviewRatingModal();
    setRatingFilterSelectedOption("");
  };
  const applyhandler = (value: string, type: any) => {
    setRatingValue(value);
    dispatch(
      fetchDoctorReviewRatingList({
        doctorId,
        noOfStars: value,
        ...(selectedDayText !== "Calender" && {
          startDate,
          endDate,
        }),
      })
    );
    openCloseReviewRatingModal();
  };
  useEffect(() => {
    if (!doctorProfile) {
      dispatch(fetchDoctorProfile());
    }
  }, []);

  useEffect(() => {
    if (ratingFilterSelectedOption === IRatingOptionEnum.REVIEW) {
      openCloseReviewRatingModal();
    }
    if (
      ratingFilterSelectedOption === IRatingOptionEnum.ALL ||
      ratingFilterSelectedOption === IRatingOptionEnum.NOT_REPLIED
    ) {
      dispatch(
        fetchDoctorReviewRatingList({
          ...(ratingFilterSelectedOption === IRatingOptionEnum.NOT_REPLIED
            ? {
                doctorId,
                repliedByDoctor: ratingFilterSelectedOption,
                ...(selectedDayText !== "Calender" && {
                  startDate,
                  endDate,
                }),
              }
            : {
                doctorId,
                ...(selectedDayText !== "Calender" && {
                  startDate,
                  endDate,
                }),
              }),
        })
      );
    }
  }, [ratingFilterSelectedOption, selectedDayText]);

  useEffect(() => {
    if (isSearchReviewOn && debouncedDoctorSearch.length === 0) {
      setIsSearchReviewOn(!isSearchReviewOn);
      dispatch(
        fetchDoctorReviewRatingList({
          doctorId,
          ...(selectedDayText !== "Calender" && {
            startDate,
            endDate,
          }),
        })
      );
      return;
    }
    if (debouncedDoctorSearch) {
      dispatch(
        fetchDoctorReviewRatingList({
          doctorId,
          keyword: searchReview,
          ...(selectedDayText !== "Calender" && {
            startDate,
            endDate,
          }),
        })
      );
    }
  }, [debouncedDoctorSearch]);

  const dateFilterUI = useCallback(
    () => (
      <Grid
        item
        className={style.chooseDateFilter}
        onClick={openCloseDateRangeSelector}
      >
        <GetImages name="CalenderWhiteIcon" width="24" height="20" />
        <Typography component="p" data-custom={selectedDayText !== "Calender"}>
          {selectedDayText}
        </Typography>
      </Grid>
    ),
    [selectedDayText, showDateRangeSelector]
  );

  return (
    <Grid item className={style.container}>
      <Grid item className={style.doctorProfileHeaderContainer}>
        <Grid item className={style.doctorImageContainer}>
          <img src={doctorProfile?.photo_url} alt={doctorProfile?.name} />
        </Grid>
        <Grid item className={style.doctorProfileDetailContainer}>
          <Typography component={"h4"}>{doctorProfile?.name}</Typography>
          <Typography component={"h5"}>{doctorProfile?.speciality}</Typography>
          <Typography component={"h6"}>
            {" "}
            {doctorProfile?.qualification_details &&
              doctorDegreeName(doctorProfile?.qualification_details)}
          </Typography>
          <Typography component={"p"} className={style.doctorExp}>
            {doctorProfile?.experience_years} Years Exp
          </Typography>
          <Typography
            component={"p"}
            className={style.doctorProfileView}
            onClick={viewDoctorProfileDetail}
          >
            View Profile
          </Typography>
        </Grid>
        {(isMobile || isTablet) && (
          <Grid item className={style.profileHeader}>
            <Grid item>
              <GetImages name="NotificationBellIcon" height="24" width="30" />
            </Grid>
            <Grid
              item
              onClick={() => dispatch(setIsSettingPreferencesOpenClose())}
            >
              <GetImages name="SettingSmallIcon" height="24" width="24" />
            </Grid>
          </Grid>
        )}
      </Grid>
      <PermissionWrapper
        moduleKey={IModalKeyEnums.REVIEW}
        featureKey={IReviewPermissionEnums.VIEW_REVIEW}
      >
        <Grid item className={style.reviewFilterContainer}>
          <Grid item className={style.reviewRatingSearchContainer}>
            {!isSearchReviewOn && (
              <Grid item className={style.reviewRatingCountContainer}>
                {isTablet || isMobile ? (
                  <>
                    <Typography component={"p"} className={style.ratingCount}>
                      <GetImages
                        name="YellowStarSmallIcon"
                        width="20"
                        height="16"
                      />

                      {Number(doctorProfile?.averageRating).toFixed(2)}
                    </Typography>
                    <Typography component={"p"} className={style.reviewCount}>
                      <Typography className={style.BoldDot}>·</Typography>
                      {doctorProfile?.reviews} Reviews
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      component={"p"}
                      className={style.webratingCount}
                    >
                      <IconButton className={style.iconContainer}>
                        <GetImages
                          name="YellowStarIcon"
                          width="36"
                          height="36"
                        />
                      </IconButton>

                      {Number(doctorProfile?.averageRating).toFixed(2)}
                    </Typography>
                    <Typography
                      component={"p"}
                      className={style.webreviewCount}
                    >
                      {doctorProfile?.reviews} Reviews
                    </Typography>
                  </>
                )}
              </Grid>
            )}
            {isSearchReviewOn && (
              <Grid item className={style.searchReviewInput}>
                <input
                  type="text"
                  value={searchReview}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSearchReview(event.target.value)
                  }
                  placeholder="Search review"
                  autoFocus
                />
              </Grid>
            )}
            <Grid
              className={style.searchReviewIcon}
              item
              onClick={() => setIsSearchReviewOn(!isSearchReviewOn)}
            >
              <GetImages name="NavSearchIcon" width="24" height="24" />
            </Grid>
          </Grid>
          <Grid item className={style.RatingFilters}>
            {dateFilterUI()}
            {showDateRangeSelector && (
              <Grid
                item
                className={style.dateRangeSelectorContainer}
                ref={dateSelectorModalRef}
              >
                <DateRangePicker
                  daySelected={0}
                  submitDateRangeValues={submitDateRangeValues}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>
            )}
            <Grid item className={style.chipFilter}>
              <ChipSelect
                data={ratingFilterOptions}
                selectedValue={ratingFilterSelectedOption}
                setSelectedValue={setRatingFilterSelectedOption}
                variant={IChipVariant.ROUNDED}
                showCarousel={true}
                showClearBtn={true}
              />
            </Grid>
          </Grid>
        </Grid>
        {!doctorProfileInfo.isLoading ? (
          <Grid item className={style.patientReviewsContainer}>
            <DoctorReviews />
          </Grid>
        ) : (
          <Loader height="60vh" />
        )}
      </PermissionWrapper>

      {openReviewRatingList && (
        <DropDownSelect
          btnLabel="apply"
          type={IDropDownSelectionType.RATING}
          isOpen={openReviewRatingList}
          closeHandler={closeHandler}
          title="select_rating"
          values={["1", "2", "3", "4", "5"].map((item: string) => ({
            label: `${item} Star`,
            value: item,
          }))}
          selectedValue={ratingValue}
          saveSelectedValues={applyhandler}
        />
      )}
      {isSettingPreferencesOpenClose && <SettingPreferences />}
    </Grid>
  );
};

export default Profile;
