import dayjs, { Dayjs } from "dayjs";

import {
  IAdmitPatientType,
  IDays,
  IProfileDropdownEnums,
  IDoctorScheduleConsultationType,
  IDoctorScheduleOrderType,
  IKeyPairsValues,
  IPDashboardFilterType,
  IScheduleAppointmentFilterEnum,
  IUniversalSearchEnum,
  IMakeAppointmentEvent,
} from "../models/doctor";
import { ILanguageEnums, IPermission } from "../models/onboarding";
import {
  IHealthAssesmentTypeEnums,
  IQualificationDetail,
} from "../models/ApiRequestResponse/patient";
import {
  IAdmittedPatientDetail,
  IClinicAppointment,
  IClinicAppointmentSegment,
  IScheduleAvailabilitySetting,
  ISlot,
  IVideoAppointment,
} from "../models/ApiRequestResponse/doctor";
import { IOperatingSystemEnums } from "../models";
import { IConsultationTypes, IOrderTypes } from "../models/patient";
import { IModalKeyEnums } from "../models/permission";
import { IPharmacyDiagnosticOrderFilterEnum } from "../models/ApiRequestResponse/pharmacy";

import { mapUrl } from "./Config";

import { storage } from "./Storage";

const pdfRegex = /^https?:\/\/.*\.pdf(\?.*)?$/;

export const AllOrdersStatusList = [
  {
    label: "Upcoming",
    value: "PENDING",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
  },
];

const enum DELIVERY_TYPE {
  HOME_DELIVERY,
  PICKUP,
}

const enum DIAGNOSTIC_TAB {
  DETAILS,
  RECORDS,
}

const convertDateToTimeStamp = (dateVal: Date | string) => {
  const selectDate = dayjs(dateVal);
  return selectDate.unix().toString();
};

const convertTimestampToISO = (timestamp: number) => {
  return dayjs.unix(timestamp).toISOString();
};

const convertTimestampToDDMMM = (
  timestamp: number,
  format: string = "DD MMM"
) => {
  return dayjs.unix(timestamp).format(format);
};

const defaultCalendarDates = {
  startDate: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
  endDate: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
};

const defaultCalendarDatesTimeStamp = {
  startDate: convertDateToTimeStamp(dayjs().startOf("day").toDate()),
  endDate: convertDateToTimeStamp(dayjs().endOf("day").toDate()),
};

const dayList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const tranformIndexesToDays = (arr: string[]) => {
  let isComplete = false,
    result = [];
  for (let i = 0; i < arr.length; i++) {
    isComplete = +arr[i] - 1 === i;
    result.push(dayList[+arr[i] - 1]);
  }
  return { isComplete, list: result };
};

const tranformDaysToIndexes = (dayIndexesArr: string[]) => {
  return dayIndexesArr.map((dayIndex, index) =>
    dayList[+dayIndex - 1].toString()
  );
};

const convertDaysToRanges = (arr: string[]) => {
  const { isComplete, list } = tranformIndexesToDays(arr);

  if (isComplete) {
    if (list.length < 3) {
      if (list.length === 1) {
        return list[0];
      }
      return `${list[0]},${list[1]}`;
    } else {
      return `${list[0]}-${list[list.length - 1]}`;
    }
  } else {
    return `${list.join(",")}`;
  }
};

const timeInAmPmFormat = (value: string) => {
  return dayjs(`${dayjs().format("YYYY-MM-DD")}T${value}`).format("hh:mm a");
};

const transformClasses = function (...args: any) {
  return args.join(" ");
};

const getAvailabilityListByDay = (
  list: IScheduleAvailabilitySetting[],
  day: IDays
) => {
  const dayIndexInList = dayList.indexOf(day) + 1;
  return list.filter((item, index) =>
    item.days.includes(dayIndexInList.toString())
  );
};

const languagesList = [
  {
    label: "English",
    value: ILanguageEnums.ENG,
  },
  {
    label: "हिंदी",
    value: ILanguageEnums.HIN,
  },
  {
    label: "ગુજરાતી",
    value: ILanguageEnums.GUJ,
  },
  {
    label: "বাংলা",
    value: ILanguageEnums.BEN,
  },
];

const doctorSpeakLanguages = (langList: string[]) => {
  if (langList.length === 0) return "";
  const langRes: string[] = [];
  languagesList.forEach((list, index) => {
    langList.forEach((lis, ind) => {
      if (list.value.includes(lis)) {
        langRes.push(list.label);
      }
    });
  });
  return langRes.join(" • ");
};

const doctorDegreeName = (qual: IQualificationDetail[]) => {
  return qual.map((list, index) => list.degree_name).join(",");
};

const getDateTime = (value: string) => {
  const now = dayjs(value);
  if (now.isSame(dayjs(), "day")) {
    return "Today";
  } else if (now.isSame(dayjs().add(1, "day"), "day")) {
    return "Tomorrow";
  } else {
    return now.format("MMM D, YYYY h:mm a");
  }
};

const getNextSixDates = (days = 6) => {
  const dates = [];
  const today = dayjs();
  for (let i = 0; i <= days; i++) {
    const nextDate = today.clone().add(i, "days");
    dates.push({
      date: nextDate.format("DD MMM"),
      day: i === 0 ? "Today" : nextDate.format("ddd"),
      fullDate: nextDate.format("YYYY MM DD"),
    });
  }
  return dates;
};

const findDoctorFilterListing = [
  {
    label: "Speciality",
    value: "speciality",
    isSelection: true,
  },
  {
    label: "In-Clinic",
    value: "In-Clinic",
    isSelection: false,
  },
  {
    label: "Video Call",
    value: "Video Call",
    isSelection: false,
  },
  {
    label: "Experince",
    value: "experience",
    isSelection: true,
  },
  {
    label: "Distance",
    value: "distance",
    isSelection: true,
  },
  {
    label: "Language",
    value: "language",
    isSelection: true,
  },
];

const openClinicMapLocation = (lat: number, lng: number) => {
  const url = `${mapUrl}${lat},${lng}`;
  window.open(url, "_blank");
};

const transformDateToMMDDYYY = (date: any) => {
  return dayjs(date).format("MMM D, YYYY");
};

const base64ToFile = (base64String: string, fileName: string) => {
  // Split the base64 string into two parts: data type and data content
  const arr = base64String.split(",");
  // Extract the MIME type from the data type (the first part of the string)
  const mime = arr[0].match(/:(.*?);/)![1];
  // Decode the Base64 string to binary data
  const bstr = atob(arr[1]);
  // Create an array of bytes
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // Create a new File object using the byte array and the MIME type
  return new File([u8arr], fileName, { type: mime });
};

const detectOS = () => {
  const userAgent = window.navigator.userAgent;
  if (/Windows|win/i.test(userAgent)) {
    return IOperatingSystemEnums.WINDOWS;
  } else if (/mac|Macintosh|MacIntel|MacPPC|Mac68K|Mac OS/i.test(userAgent)) {
    return IOperatingSystemEnums.MAC_OS;
  } else if (/linux|Linux/i.test(userAgent)) {
    return IOperatingSystemEnums.LINUX;
  } else if (/android/i.test(userAgent)) {
    return IOperatingSystemEnums.ANDROID;
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    return IOperatingSystemEnums.IOS;
  } else {
    return IOperatingSystemEnums.UNKNOWN_OS;
  }
};

const convertDateYYYYMMDD = (date: string | Date | Dayjs) =>
  dayjs(date).format("YYYY-MM-DD");

const downloadReport = (documentURL: string, fileName: string) => {
  fetch(documentURL)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const fileUrl = documentURL.split("/");
      const fileExtension = fileUrl[fileUrl.length - 1].split(".");
      const downloadLink = document.createElement("a");
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${fileName}.${
        fileExtension[fileExtension.length - 1]
      }`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    })
    .catch((error) =>
      console.error("There was a problem downloading the PDF:", error)
    );
};

const convertDateDDMMM = (date: string) => dayjs(date).format("DD MMM");

const topBarDropdownDispatchingEnums: any = {
  [IProfileDropdownEnums.PROFILE]: "isProfileOpen",
  [IProfileDropdownEnums.REVIEW]: "isReviewOpen",
  [IProfileDropdownEnums.AVAILABILITY_SETTINGS]: "isAvailabilityOpen",
  [IProfileDropdownEnums.CLINICAL_TEAM]: "isClinicTeamOpen",
  [IProfileDropdownEnums.TEMPLATES]: "isTemplatesOpen",
  [IProfileDropdownEnums.CHANGE_HOSPITAL]: "isChangeHospitalOpen",
  [IProfileDropdownEnums.LOGOUT]: "isLogoutOpen",
  [IProfileDropdownEnums.NOTIFICATION]: "isNotificationOpen",
  [IProfileDropdownEnums.HELP_SUPPORT]: "isHelpSupportOpen",
};

const dayFilterListing = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Mon",
    value: "Mon",
  },
  {
    label: "Tue",
    value: "Tue",
  },
  {
    label: "Wed",
    value: "Wed",
  },
  {
    label: "Thu",
    value: "Thu",
  },
  {
    label: "Fri",
    value: "Fri",
  },
  {
    label: "Sat",
    value: "Sat",
  },
  {
    label: "Sun",
    value: "Sun",
  },
];

const scheduleFilterList = [
  {
    label: "All",
    value: IScheduleAppointmentFilterEnum.ALL,
  },
  {
    label: "Upcoming",
    value: IScheduleAppointmentFilterEnum.UPCOMING,
  },
  {
    label: "Completed",
    value: IScheduleAppointmentFilterEnum.COMPLETED,
  },
  {
    label: "Cancelled",
    value: IScheduleAppointmentFilterEnum.CANCELLED,
  },
];

const pharmacyOrderFilterList = [
  {
    label: "Home Delivery",
    value: IPharmacyDiagnosticOrderFilterEnum.HOME_DELIVERY,
  },
  {
    label: "Pickup",
    value: IPharmacyDiagnosticOrderFilterEnum.PICKUP,
  },
  {
    label: "Accepted",
    value: IPharmacyDiagnosticOrderFilterEnum.CONFIRMED,
  },
  {
    label: "Completed",
    value: IPharmacyDiagnosticOrderFilterEnum.COMPLETED,
  },
  {
    label: "Rejected",
    value: IPharmacyDiagnosticOrderFilterEnum.REJECTED,
  },
];

const diagnosticOrderFilterList = [
  {
    label: "Accepted",
    value: IPharmacyDiagnosticOrderFilterEnum.CONFIRMED,
  },
  {
    label: "Uploaded",
    value: IPharmacyDiagnosticOrderFilterEnum.UPLOADED,
  },
  {
    label: "Completed",
    value: IPharmacyDiagnosticOrderFilterEnum.COMPLETED,
  },
  {
    label: "Rejected",
    value: IPharmacyDiagnosticOrderFilterEnum.REJECTED,
  },
];

const OrderTypeFilterList = [
  {
    label: "In-Clinic",
    value: IDoctorScheduleOrderType.IN_CLINIC,
  },
  {
    label: "Video Call",
    value: IDoctorScheduleOrderType.VIDEO_CALL,
  },
  {
    label: "Medicine",
    value: IDoctorScheduleOrderType.MEDICINE,
  },
  {
    label: "Test",
    value: IDoctorScheduleOrderType.TEST,
  },
];
const orderTypes: IOrderTypes = {
  [IDoctorScheduleOrderType.IN_CLINIC]: "In-Clinic",
  [IDoctorScheduleOrderType.VIDEO_CALL]: "Video Call",
  [IDoctorScheduleOrderType.MEDICINE]: "Medicine",
  [IDoctorScheduleOrderType.TEST]: "Test",
};

const languagesListInfo: IKeyPairsValues = {
  [ILanguageEnums.ENG]: "English",
  [ILanguageEnums.HIN]: "हिंदी",
  [ILanguageEnums.GUJ]: "ગુજરાતી",
  [ILanguageEnums.BEN]: "বাংলা",
};

const consultationTypes: IConsultationTypes = {
  [IDoctorScheduleConsultationType.IN_CLINIC]: "In-Clinic",
  [IDoctorScheduleConsultationType.VIDEO_CALL]: "Video Call",
};

const calculateDayFromTargetDate = (date: string, endDate?: string) => {
  const targetDate = dayjs(date);
  const today = endDate ? dayjs(endDate) : dayjs();
  const diffDays = today.diff(targetDate, "day");
  return diffDays === 0 && !endDate ? `Today` : `${diffDays}d`;
};

const transformDateToDDMMYYYY = (date: any) => {
  return dayjs(date).format("DD MMMM YYYY");
};

const ipDashboardListing = [
  {
    label: "ICU",
    value: IPDashboardFilterType.ICU,
    isSelection: true,
  },
  {
    label: "Ward",
    value: IPDashboardFilterType.WARD,
    isSelection: true,
  },
  {
    label: "Active",
    value: IPDashboardFilterType.ACTIVE,
    isSelection: false,
  },
  {
    label: "Discharge",
    value: IPDashboardFilterType.DISCHARGE,
    isSelection: false,
  },
];

const ipDashboardListingWeb = [
  {
    label: "Active",
    value: IPDashboardFilterType.ACTIVE,
  },
  {
    label: "Discharge",
    value: IPDashboardFilterType.DISCHARGE,
  },
];

const getTodayTomorrowDate = () => {
  const currentDate = dayjs();
  const nextDay = currentDate.add(1, "day");
  return {
    today: convertDateYYYYMMDD(currentDate),
    tomorrow: convertDateYYYYMMDD(nextDay),
  };
};

const admitPatientinitialState = {
  hospital_id: "",
  hospital_room_id: "",
  room_bed_id: "",
  sponsor_name: "",
  case_history: "",
  admission_date: null,
  hospital_uhid: "",
  name: "",
  member_id: "",
  age: "",
  gender: "",
  mobile_number: "",
};

const admitPatientDispatcher = (state: any, action: any) => {
  switch (action.type) {
    case IAdmitPatientType.SET_FIELD:
      return {
        ...state,
        [action.field]: action.value,
      };
    case IAdmitPatientType.SET_API_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return {};
  }
};

const getDay = (date: string) => {
  return dayjs(date).format("DD");
};

const getDateFormat = (date: string, format: string = "YYYY MM DD") => {
  return dayjs(date).format(format);
};

const formatTimestamp = (timestamp: number, format: string): string => {
  // Ensure timestamp is in milliseconds
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

  // Map for date format replacements
  const map: { [key: string]: string } = {
    DD: String(date.getDate()).padStart(2, "0"), // Day with leading zero (e.g., 01, 23)
    D: String(date.getDate()), // Day without leading zero (e.g., 1, 23)
    MMM: date.toLocaleString("en-US", { month: "short" }), // Short month name (e.g., Jun)
    MMMM: date.toLocaleString("en-US", { month: "long" }), // Full month name (e.g., June)
    MM: String(date.getMonth() + 1).padStart(2, "0"), // Month as number with leading zero (e.g., 01, 12)
    M: String(date.getMonth() + 1), // Month as number without leading zero (e.g., 1, 12)
    YYYY: String(date.getFullYear()), // Full year (e.g., 2025)
    YY: String(date.getFullYear()).slice(-2), // Last two digits of year (e.g., 25)
  };

  // Replace format tokens with their corresponding values
  return format.replace(
    /DD|D|MMMM|MMM|MM|M|YYYY|YY/g,
    (matched) => map[matched] || matched
  );
};

const removeAttributesAdmitPatientDetail = (
  patientDetail: IAdmittedPatientDetail
) => {
  const {
    id,
    doctor_id,
    patient_id,
    discharged_date,
    status,
    created_at,
    updated_at,
    number,
    room_available,
    room_name,
    type,
    phone_number,
    ...rest
  } = patientDetail;
  return { ...rest, mobile_number: phone_number };
};

const getGreeting = () => {
  const currentHour = dayjs().hour();
  if (currentHour < 12) {
    return "Good morning";
  } else if (currentHour < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
};

const doctorChipSearchList = [
  {
    label: "Appointments",
    value: IUniversalSearchEnum.APPOINTMENTS,
  },
  {
    label: "In Patients",
    value: IUniversalSearchEnum.IN_PATIENTS,
  },
  {
    label: "Clinical Team",
    value: IUniversalSearchEnum.CLINICAL_TEAM,
  },
];

const patientChipSearchList = [
  {
    label: "Doctors",
    value: IUniversalSearchEnum.FIND_DOCTORS,
  },
  {
    label: "Orders",
    value: IUniversalSearchEnum.ORDERS,
  },
  {
    label: "Diagnostic",
    value: IUniversalSearchEnum.DIAGNOSTIC,
  },
  {
    label: "Test",
    value: IUniversalSearchEnum.TEST,
  },
  {
    label: "Health Hub",
    value: IUniversalSearchEnum.HEALTH_HUB,
  },
];

const pharmacyChipSearchList = [
  {
    label: "Pharmacy",
    value: IUniversalSearchEnum.PHARMACY,
  },
  {
    label: "Clinic",
    value: IUniversalSearchEnum.CLINIC,
  },
];

const diagnosticChipSearchList = [
  {
    label: "Diagnostic",
    value: IUniversalSearchEnum.DIAGNOSTIC_CENTER,
  },
];

const universalSearchType: IKeyPairsValues = {
  [IUniversalSearchEnum.APPOINTMENTS]: "appointments",
  [IUniversalSearchEnum.IN_PATIENTS]: "patients",
  [IUniversalSearchEnum.CLINICAL_TEAM]: "clinical teams",
  [IUniversalSearchEnum.PHARMACY]: "pharmacy",
  [IUniversalSearchEnum.CLINIC]: "clinic",
  // Patient search
  [IUniversalSearchEnum.FIND_DOCTORS]: "doctors",
  [IUniversalSearchEnum.ORDERS]: "orders",
  [IUniversalSearchEnum.DIAGNOSTIC]: "diagnostic",
  [IUniversalSearchEnum.TEST]: "tests",
  [IUniversalSearchEnum.HEALTH_HUB]: "health hub",
};

const timeInAmPmFromDateTime = (value: string) => {
  return dayjs(value).format("hh:mm a");
};

const convertDDMMYYYYHHMM = (value: string) => {
  return dayjs(value).format("DD MMMM YYYY, hh:mm A");
};
const randomCardColorGenerator = () => {
  const colorsList = [
    "var(--warm-neutral-50)",
    "#FFF1EF",
    "#E7F8FF",
    "#FFF8DF",
    "#E8FFF7",
    "#EFFFE0",
    "#FFE0F1",
    "#F9FFE0",
    "#E4FDFF",
  ];
  return colorsList[Math.floor(Math.random() * colorsList.length)];
};

const getHealthAssessmentImageByType: IKeyPairsValues = {
  [IHealthAssesmentTypeEnums.Brain]: "BrainImage",
  [IHealthAssesmentTypeEnums.Spine]: "SpineImage",
  [IHealthAssesmentTypeEnums.HEAD]: "BrainImage",
};

const getLocation = () => {
  const location: any =
    storage.getSessionToken("location") &&
    JSON.parse(storage.getSessionToken("location") as any);
  return location;
};

const getPermissionAccess = (
  moduleKey: IModalKeyEnums,
  featureKey: string,
  permissions: IPermission[]
) => {
  const module = permissions?.find((mod) => mod.moduleKey === moduleKey);
  if (!module) return null;
  const feature = module.features.find(
    (feat) => feat.featureKey === featureKey
  );
  return feature ? feature.permissionAccess : null;
};
const capitalizeFirstLetter = (value: string): string => {
  if (!value) return "";
  return value
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const roundToTwoDecimals = (value: number) => {
  if (typeof value === "number") {
    return Math.round(value * 100) / 100;
  }
  throw new Error("Input must be a number");
};

const isClinic = () => {
  const clinicId = storage.getSessionToken("clinic_id");
  const doctorId = storage.getSessionToken("doctor_id");
  return clinicId && doctorId;
};

const toCamelCase = (str: string) => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

const convertKeysToCamelCase = (obj: any) => {
  const newObj: any = {};
  for (let key in obj) {
    const camelKey: any = toCamelCase(key); // Convert key to camelCase
    newObj[camelKey] =
      typeof obj[key] === "object" && !Array.isArray(obj[key])
        ? convertKeysToCamelCase(obj[key]) // Recursively handle nested objects
        : obj[key]; // Keep value as is
  }
  return newObj;
};

const getMedicalReportDocumentName = (url: string) => {
  return url?.split("/")?.pop();
};

const filteredNullEmptyArrayData = (data: any) =>
  Object.fromEntries(
    Object.entries(data).filter(
      ([_, value]) =>
        value !== null && !(Array.isArray(value) && value.length === 0)
    )
  );

const getTimeDifferenceInMinutes = (
  startTime: string,
  endTime: string
): number => {
  const start = dayjs(startTime, "HH:mm");
  const end = dayjs(endTime, "HH:mm");

  let diff = end.diff(start, "minute");
  if (diff < 0) {
    diff += 24 * 60;
  }
  return diff;
};

const getFileNameFromUrl = (url: string) => {
  const regex = /\/([^\/]+)\.(pdf|jpeg|png)$/i;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const clinicAppointmentSegregation = (
  clinicSlotWiseData: IClinicAppointment[]
) => {
  const result: ISlot[] = [];
  const slots = clinicSlotWiseData[0]?.slots;
  for (let i = 0; i < slots?.length; i++) {
    const slot = slots[i];
    const clinic = result.find(
      (c: any) =>
        c.clinicId === slot.clinicId &&
        c.consultationFee === slot.consultationFee
    );
    if (clinic) {
      clinic.slots.push(slot);
    } else {
      result.push({
        clinicId: slot.clinicId,
        clinicName: slot.clinicName,
        clinicCity: slot.clinicCity,
        clinicAddress: slot.clinicAddress,
        latitude: slot.latitude,
        longitude: slot.longitude,
        consultationFee: slot.consultationFee,
        slots: [slot],
      });
    }
  }
  if (clinicSlotWiseData.length === 0 && result.length === 0) return [];
  return [
    {
      date: clinicSlotWiseData[0]?.date,
      day: clinicSlotWiseData[0]?.day,
      slots: result,
    },
  ];
};

const getAppointmentSlotDetail = (
  selectedTab: IMakeAppointmentEvent,
  slotsDetail: {
    videoAppointment: IVideoAppointment[];
    clinicAppointment: IClinicAppointmentSegment[];
  },
  selectedAppointmentSlot: string,
  nextSixDays: { date: string; day: string }[],
  selectedDateTab: number
) => {
  if (selectedTab === IMakeAppointmentEvent.VIDEO_CALL) {
    for (const segment of slotsDetail["videoAppointment"]) {
      const slot = segment.slots.find(
        (slot: any) => slot.slotId === selectedAppointmentSlot
      );
      if (slot) {
        return {
          startTime: timeInAmPmFormat(slot.slotStartTime),
          endTime: timeInAmPmFormat(slot.slotEndTime),
          date: nextSixDays[selectedDateTab]?.date ?? "",
          consultationFee: slot.consultationFee,
        };
      }
    }
  } else {
    for (const segment of slotsDetail["clinicAppointment"]) {
      for (const segmentSlot of segment.slots) {
        const slot = segmentSlot.slots.find(
          (slot: any) => slot.slotId === selectedAppointmentSlot
        );
        if (slot) {
          return {
            startTime: timeInAmPmFormat(slot.slotStartTime),
            endTime: timeInAmPmFormat(slot.slotEndTime),
            date: nextSixDays[selectedDateTab]?.date ?? "",
            clinicId: segmentSlot.clinicId,
            clinicName: segmentSlot.clinicName,
            clinicCity: segmentSlot.clinicCity,
            clinicAddress: segmentSlot.clinicAddress,
            consultationFee: segmentSlot.consultationFee,
          };
        }
      }
    }
  }
};

export {
  clinicAppointmentSegregation,
  getAppointmentSlotDetail,
  getFileNameFromUrl,
  getTimeDifferenceInMinutes,
  filteredNullEmptyArrayData,
  convertTimestampToISO,
  getMedicalReportDocumentName,
  convertTimestampToDDMMM,
  defaultCalendarDatesTimeStamp,
  diagnosticOrderFilterList,
  convertDateToTimeStamp,
  convertKeysToCamelCase,
  isClinic,
  getPermissionAccess,
  getLocation,
  convertDDMMYYYYHHMM,
  getHealthAssessmentImageByType,
  randomCardColorGenerator,
  timeInAmPmFromDateTime,
  universalSearchType,
  doctorChipSearchList,
  pharmacyChipSearchList,
  getGreeting,
  removeAttributesAdmitPatientDetail,
  getDay,
  admitPatientinitialState,
  admitPatientDispatcher,
  getTodayTomorrowDate,
  ipDashboardListingWeb,
  ipDashboardListing,
  transformDateToDDMMYYYY,
  calculateDayFromTargetDate,
  languagesListInfo,
  consultationTypes,
  orderTypes,
  scheduleFilterList,
  dayFilterListing,
  downloadReport,
  convertDateDDMMM,
  convertDateYYYYMMDD,
  detectOS,
  base64ToFile,
  transformDateToMMDDYYY,
  openClinicMapLocation,
  findDoctorFilterListing,
  getNextSixDates,
  getDateTime,
  languagesList,
  OrderTypeFilterList,
  transformClasses,
  tranformIndexesToDays,
  convertDaysToRanges,
  timeInAmPmFormat,
  tranformDaysToIndexes,
  getAvailabilityListByDay,
  doctorSpeakLanguages,
  doctorDegreeName,
  topBarDropdownDispatchingEnums,
  pharmacyOrderFilterList,
  capitalizeFirstLetter,
  roundToTwoDecimals,
  getDateFormat,
  formatTimestamp,
  defaultCalendarDates,
  patientChipSearchList,
  diagnosticChipSearchList,
  DIAGNOSTIC_TAB,
  pdfRegex,
  DELIVERY_TYPE,
};
