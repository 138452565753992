import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EnterMobile from "../../../components/Verification/VerifyMobile";
import VerifyOTP from "../../../components/Verification/VerifyOTP";

import { IMobileVerify, IOtpVerify } from "../../../models/onboarding";
import { IRoleType } from "../../../models/role";
import { IAddPatient } from "../../../models/doctor";

import {
  mobileVerifyOrSendOTPApi,
  OTPVerifyApi,
} from "../../../utils/apis/onboarding/OnBoardingApis";
import { countryCode } from "../../../utils/text";
import { storage } from "../../../utils/Storage";
import useIsMobile from "../../../utils/hooks/useIsMobile";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setAddPatient,
  setIsLocationAccess,
} from "../../../store/slices/doctor/topNavigation";
import { setRoleAndPermission } from "../../../store/slices/permission";

const MobileOTPVerify: React.FC = () => {
  const [isMobileVerifyShow, setIsMobileVerifyShow] = useState<boolean | null>(
    true
  );
  const [mobileNoOTPId, setMobileNoOTPId] = useState({
    mobileNo: "",
    otpId: "",
  });

  const navigate = useNavigate();

  const { mobileCheck } = useAppSelector(
    (state) => state.topNavigation.addPatient
  );
  const { fcmDeviceToken } = useAppSelector((state) => state.videoAppointment);

  const dispatch = useAppDispatch();
  const { isMobile } = useIsMobile();

  const mobileSubmitHandler = async (values: IMobileVerify) => {
    try {
      const mobileVerifyRes = await mobileVerifyOrSendOTPApi({
        country_code: countryCode,
        device_id: "string",
        device_token: fcmDeviceToken ?? "string",
        phone_number: values.mobileNo,
      });
      if ("result" in mobileVerifyRes) {
        setMobileNoOTPId({
          mobileNo: values.mobileNo,
          otpId: mobileVerifyRes?.result?.OtpId,
        });
        setIsMobileVerifyShow(!isMobileVerifyShow);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mobileCheckOpenClosed = (value: boolean) => {
    dispatch(
      setAddPatient({
        field: IAddPatient.MOBILE_CHECK,
        value,
      })
    );
  };

  const handleOTPSubmit = async (values: IOtpVerify) => {
    try {
      const otpVerifyRes = await OTPVerifyApi({
        device_id: "string",
        device_token: fcmDeviceToken ?? "string",
        otp_code: values.otp,
        token: mobileNoOTPId?.otpId,
      });
      if ("result" in otpVerifyRes) {
        const {
          role,
          name,
          user_id,
          token,
          clinic_id,
          doctor_id,
          doctor_name,
          permissions,
          phone_number,
        } = otpVerifyRes?.result;

        dispatch(setRoleAndPermission(permissions));

        const sessionDate = {
          token,
          user_id,
          roleType: role,
          name,
          phone_number,
          ...(clinic_id &&
            doctor_id && {
              clinic_id,
              doctor_id,
              doctor_name,
            }),
        };

        // set session data
        Object.entries(sessionDate).forEach(([key, value]) =>
          storage.setSessionToken(key, value)
        );

        switch (role) {
          case IRoleType.DOCTOR:
          case IRoleType.JR_DOCTOR:
            navigate("/schedule");
            break;
          case IRoleType.PHARMACY:
          case IRoleType.DIAGNOSTIC_CENTER:
            navigate("/orders");
            break;
          default:
            dispatch(setIsLocationAccess(true));
            setIsMobileVerifyShow(null);
            break;
        }
        mobileCheckOpenClosed(false);
      }
    } catch (error) {
      console.log(error);
      mobileCheckOpenClosed(false);
    }
  };

  const resendOTP = async () => {
    try {
      const mobileVerifyRes = await mobileVerifyOrSendOTPApi({
        country_code: countryCode,
        device_id: "string",
        device_token: "string",
        phone_number: mobileNoOTPId?.mobileNo,
      });
      if ("result" in mobileVerifyRes) {
        setMobileNoOTPId({
          ...mobileNoOTPId,
          otpId: mobileVerifyRes.result.OtpId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToMobileVerify = () =>
    setIsMobileVerifyShow(!isMobileVerifyShow);

  const mobileCheckCloseHandler = () => {
    !isMobile
      ? (setIsMobileVerifyShow(null), mobileCheckOpenClosed(false))
      : {};
  };

  useEffect(() => {
    if (mobileCheck) setIsMobileVerifyShow(mobileCheck);
  }, [mobileCheck]);

  return (
    <>
      {isMobileVerifyShow ? (
        <EnterMobile
          isOpen={isMobileVerifyShow}
          closeHandler={mobileCheckCloseHandler}
          heading="loginUsingPhoneText"
          mobileSubmitHandler={mobileSubmitHandler}
          btnLabel="getOTP"
          mobileNo={mobileNoOTPId?.mobileNo}
        />
      ) : (
        isMobileVerifyShow !== null && (
          <VerifyOTP
            handleOTPSubmit={handleOTPSubmit}
            mobileNo={mobileNoOTPId?.mobileNo}
            navigateToMobileVerify={navigateToMobileVerify}
            resendOTP={resendOTP}
          />
        )
      )}
    </>
  );
};

export default MobileOTPVerify;
