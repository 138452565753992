import { IStatusCodeMessage } from "../../../models";
import {
  IHealthCategoryApiResponse,
  IHealthHubData,
} from "../../../models/ApiRequestResponse/patient";
import { GBSCError } from "../../../models/GBSCError";
import { get, post } from "../../TypedApi";
import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getHealthHubSearchDataApi = async (
  searchTerm?: string,
  offset: number = 1
): Promise<IHealthHubData | GBSCError> => {
  try {
    let endPoint = APIEndpoint.patient.healthHubSearch;
    if (searchTerm) {
      endPoint = `${endPoint}?keyword=${searchTerm}&page=${offset}&limit=20`;
    }
    const response = await get<IHealthHubData>(endPoint);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getHealthHubDataApi = async (): Promise<
  IHealthHubData | GBSCError
> => {
  try {
    let endPoint = APIEndpoint.patient.healthHub;
    const response = await get<IHealthHubData>(endPoint);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getHealthHubCategoriesApi = async (): Promise<
  IHealthCategoryApiResponse | GBSCError
> => {
  try {
    const response = await get<IHealthCategoryApiResponse>(
      APIEndpoint.patient.healthHubCategories
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const applyCategoryFilterApi = async (
  filterId: string,
  isFavourite = false
): Promise<IHealthHubData | GBSCError> => {
  try {
    const favEndpoint = `?favourite=${isFavourite}`;
    const response = await get<IHealthHubData>(
      `${APIEndpoint.patient.healthHubApplyCategoryFilter}${
        isFavourite ? favEndpoint : `?tag=${filterId}`
      }`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const likeVideoApi = async (
  videoId: string,
  unLike = false
): Promise<IStatusCodeMessage | GBSCError> => {
  try {
    const payload: { videoId: string; remove?: boolean } = {
      videoId,
    };

    if (unLike) {
      payload.remove = true;
    }
    const response = await post<IStatusCodeMessage>(
      `${APIEndpoint.patient.healthHubLikeVideo}`,
      payload
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const favouriteVideoApi = async (
  videoId: string,
  unFavourite = false
): Promise<IStatusCodeMessage | GBSCError> => {
  try {
    const payload: { videoId: string; remove?: boolean } = {
      videoId,
    };

    if (unFavourite) {
      payload.remove = true;
    }
    const response = await post<IStatusCodeMessage>(
      `${APIEndpoint.patient.healthHubFavouriteVideo}`,
      payload
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const incrementBlogViewCountApi = async (
  blogId: string
): Promise<IStatusCodeMessage | GBSCError> => {
  try {
    const response = await post<IStatusCodeMessage>(
      `${APIEndpoint.patient.healthHubIncrementBlogCount}`,
      { id: blogId }
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
export const incrementVideoViewCountApi = async (
  videoId: string
): Promise<IStatusCodeMessage | GBSCError> => {
  try {
    const response = await post<IStatusCodeMessage>(
      `${APIEndpoint.patient.healthHubIncrementViewCount}`,
      { videoId }
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const likeBlogApi = async (
  blog_id: string,
  unLike = false
): Promise<IStatusCodeMessage | GBSCError> => {
  try {
    const payload: { id: string; remove?: boolean; type: "LIKE" } = {
      id: blog_id,
      type: "LIKE",
    };

    if (unLike) {
      payload.remove = true;
    }
    const response = await post<IStatusCodeMessage>(
      `${APIEndpoint.patient.healthHubBlog}`,
      payload
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const favouriteBlogApi = async (
  blog_id: string,
  unFavourite = false
): Promise<IStatusCodeMessage | GBSCError> => {
  try {
    const payload: { id: string; remove?: boolean; type: "FAVORITE" } = {
      id: blog_id,
      type: "FAVORITE",
    };

    if (unFavourite) {
      payload.remove = true;
    }
    const response = await post<IStatusCodeMessage>(
      `${APIEndpoint.patient.healthHubBlog}`,
      payload
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const likeDiseaseApi = async (
  diseaseId: string,
  unLike = false
): Promise<IStatusCodeMessage | GBSCError> => {
  try {
    const payload: { id: string; remove?: boolean; type: "LIKE" } = {
      id: diseaseId,
      type: "LIKE",
    };

    if (unLike) {
      payload.remove = true;
    }
    const response = await post<IStatusCodeMessage>(
      `${APIEndpoint.patient.healthHubBlog}`,
      payload
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const favouriteDiseaseApi = async (
  diseaseId: string,
  unFavourite = false
): Promise<IStatusCodeMessage | GBSCError> => {
  try {
    const payload: { id: string; remove?: boolean; type: "FAVORITE" } = {
      id: diseaseId,
      type: "FAVORITE",
    };

    if (unFavourite) {
      payload.remove = true;
    }
    const response = await post<IStatusCodeMessage>(
      `${APIEndpoint.patient.healthHubBlog}`,
      payload
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
