import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchDoctorHospitalPatientListing,
  setSelectedDayText,
} from "../../store/slices/doctor/ipDashboard";

import { ISelectedDate } from "../../models/doctor";
import { convertDateDDMMM } from "..";
import dayjs from "dayjs";

const useIPDashboardDateFilter = () => {
  const [selectedDate, setSelectedDate] = useState<null | ISelectedDate>(null);

  const { selectedHospitalId } = useAppSelector((state) => state.ipDashboard);

  const dispatch = useAppDispatch();

  const todayDateString = new Date().toISOString().split("T")[0];

  const applyDateRangeValues = (startDate: string, endDate: string) => {
    const oneDayForward = dayjs().add(1, "day").format("YYYY-MM-DD");
    const filterOptions = {
      start_date: startDate,
      end_date: oneDayForward,
    };
    setSelectedDate({
      startDate: startDate,
      endDate: oneDayForward,
    });
    dispatch(
      setSelectedDayText(
        startDate === endDate
          ? `${convertDateDDMMM(startDate)}`
          : `${convertDateDDMMM(startDate)}-${convertDateDDMMM(endDate)}`
      )
    );
    dispatch(
      fetchDoctorHospitalPatientListing({
        hospitalId: selectedHospitalId,
        ...filterOptions,
      })
    );
  };
  return { applyDateRangeValues, selectedDate, setSelectedDate };
};

export default useIPDashboardDateFilter;
