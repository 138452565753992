import { createTheme } from "@mui/material";

const MUITheme = createTheme();

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: "#F15A29",
      light: "#FFF6EA",
    },
    secondary: {
      light: "#8d8d8d",
      main: "#6F6F6F",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#f2f6f7",
          [MUITheme.breakpoints.up("md")]: {
            borderRadius: "2.4rem", // Apply borderRadius only on medium screens and larger
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0.8rem",
          boxShadow: "none",
        },
        disableElevation: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffff",
          cursor: "pointer",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "0.8rem",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "none",
          borderRadius: "1rem",
          boxShadow: "none",
          "&:first-of-type": {
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          },
          "&:last-of-type": {
            borderBottomLeftRadius: "1rem",
            borderBottomRightRadius: "1rem",
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontSize: 18,
          fontWeight: 500,
          [MUITheme.breakpoints.down("md")]: {
            fontSize: 12,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 400,
          [MUITheme.breakpoints.down("md")]: {
            fontSize: 10,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
          minWidth: "30ch",
        },
        list: {
          p: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid var(--gray-20)",
          paddingInline: 4,
          paddingBlock: 10,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none",
        },
      },
    },
  },
});
