import { useMemo } from "react";

import { IDoctorScheduleConsultationType } from "../../models/doctor";
import {
  IAllOrdersDetail,
  IMedicineOrderType,
} from "../../models/ApiRequestResponse/patient";
import { ITestOrderTypeEnums } from "../../models/patient";

const useOrderLists = (allOrders: IAllOrdersDetail[]) => {
  const medicineOrderList = useMemo(
    () =>
      allOrders.filter(
        (order) =>
          order.type === IMedicineOrderType.HOME_DELIVERY ||
          order.type === IMedicineOrderType.PICKUP
      ),
    [allOrders]
  );

  const appointmentList = useMemo(
    () =>
      allOrders.filter(
        (order) =>
          order.consultation_type ===
            IDoctorScheduleConsultationType.IN_CLINIC ||
          order.consultation_type === IDoctorScheduleConsultationType.VIDEO_CALL
      ),
    [allOrders]
  );

  const testBookingList = useMemo(
    () =>
      allOrders.filter(
        (order) => order.ordertype === ITestOrderTypeEnums.DIAGNOSTIC_TEST
      ),
    [allOrders]
  );

  return { medicineOrderList, appointmentList, testBookingList };
};

export default useOrderLists;
