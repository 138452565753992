import { styled, TextField, TextFieldProps } from "@mui/material";

const InputField = styled(TextField)<
  TextFieldProps & { backgroundColor?: string }
>(({ backgroundColor }) => {
  return {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "black",
      "-webkit-text-fill-color": "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--neutral-100)",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-disabled": {
        "& fieldset": {
          borderColor: "white",
        },
      },
      "&:hover fieldset": {
        borderColor: "var(--neutral-100)",
        borderRadius: "0.8rem",
        borderBottom: "0.1rem solid var(--gray-200)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--neutral-100)",
        borderRadius: "0.8rem",
        borderBottom: "0.1rem solid var(--gray-200)",
      },
      backgroundColor: backgroundColor ?? "var(--neutral-100)",
      borderRadius: "0.8rem",
    },
    "&.Mui-disabled": {
      "& .MuiOutlinedInput-root": {
        borderColor: "white",
      },
    },
    "& .MuiInputLabel-root": {
      color: "var(--gray-400)",
      backgroundColor: "white",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "var(--gray-400)",
    },
  };
});

const style = {
  textArea: {
    "& textarea": {
      width: "-webkit-fill-available",
      fontFamily: "Poppins",
      color: "var(--black-900)",
      border: "none",
      padding: "2.6rem 0.8rem",
      borderRadius: "0.8rem",
      borderBottom: "0.1rem solid var(--gray-200)",
      background: "var(--neutral-100)",
      "&:focus": {
        outline: "none",
      },
      "&::placeholder": {
        color: "var(--gray-400)",
        fontSize: "1.4rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
  },
};
const TextArea = styled("textarea")(
  ({ theme }) => style.textArea["& textarea"]
);

export { InputField, TextArea };
