import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import {
  IAppointments,
  IDoctorScheduleSlotDetail,
} from "../../../models/ApiRequestResponse/doctor";
import {
  IMakeAppointmentFromDoctorSlice,
  IScheduleSlotsRequest,
} from "../../../models/doctor";

import { getDoctorScheduleSlotApi } from "../../../utils/apis/doctor/appointment";

import { RootState } from "../../store";

export const fetchScheduleSlots = createAsyncThunk(
  "doctor/scheduleSlots",
  async ({ doctorId, clinicId }: IScheduleSlotsRequest) => {
    const response = await getDoctorScheduleSlotApi({ doctorId, clinicId });
    return response;
  }
);

const appointmentSlice = createSlice({
  name: "scheduleSlots",
  initialState: {
    slot: {} as IDoctorScheduleSlotDetail,
    makePatientAppointment: {} as IMakeAppointmentFromDoctorSlice,
    isLoading: true as boolean,
    appointmentDetail: {} as IAppointments,
  },
  reducers: {
    makePatientAppointment: (state, action) => {
      state.makePatientAppointment = action.payload;
    },
    setAppointmentDetail: (state, action) => {
      state.appointmentDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // doctor slots
      .addCase(fetchScheduleSlots.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchScheduleSlots.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.slot = action.payload.result;
        }
      })
      .addCase(fetchScheduleSlots.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

const selectAppointments = (state: RootState) => state.appointment;

export const appointmentDetails = createSelector(
  [selectAppointments],
  (selectAppointment) => selectAppointment.appointmentDetail
);

export const { makePatientAppointment, setAppointmentDetail } =
  appointmentSlice.actions;
export default appointmentSlice.reducer;
