import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { appLogoutApi } from "../apis/config";

import { INotifyEnum, notify } from "../toaster";

import { useAppDispatch } from "../../store/hooks";
import { setFCMDeviceToken } from "../../store/slices/videoAppointment";
import { fetchHomeModules } from "../../store/slices/patient/home";

import { IResetAppState } from "../../models";
import { generateTokenFCM } from "../../firebaseConfig";

import { storage } from "../Storage";

const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userId = storage.getSessionToken("user_id");
  const logoutOnExpire = async () => {
    navigate("/home");
    dispatch({ type: IResetAppState.RESET_ALL });
    localStorage.clear();
    const deviceToken = await generateTokenFCM();
    dispatch(setFCMDeviceToken(deviceToken));
    dispatch(fetchHomeModules({ userId: userId! }));
  };

  const logout = useCallback(async () => {
    try {
      const appLogoutResponse = await appLogoutApi();
      if ("result" in appLogoutResponse) {
        notify(INotifyEnum.SUCCESS, appLogoutResponse.message);
        await logoutOnExpire();
      }
    } catch (error) {
      console.error("Logout Error:", error);
    }
  }, []);

  return { logout, logoutOnExpire };
};

export default useLogout;
