import React from "react";

import { handleUpdateOrderApi } from "../apis/pharmacy";

import {
  IOrderStatusEnum,
  IPharmacyDiagnosticOrderFilterEnum,
  IPharmacyOrderDetailUpdate,
} from "../../models/ApiRequestResponse/pharmacy";
import { IPharmacyDiagnosticOrderDetail } from "../../models/ApiRequestResponse/diagnostic";
import { IRoleType } from "../../models/role";

import {
  updateOrderList,
  updatePendingOrderList,
} from "../../store/slices/pharmacyClinicDiagnostic";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { INotifyEnum, notify } from "../toaster";

import { storage } from "../Storage";

import { updateDiagnosticOrderApi } from "../apis/diagnostic";

const useHandlePharmacyOrderUpdate = () => {
  const dispatch = useAppDispatch();

  const roleType = storage.getSessionToken("roleType");

  const { list, pendingOrdersList, order } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );

  const handlePharmacyOrderUpdate = async (
    status: IOrderStatusEnum,
    values?: IPharmacyOrderDetailUpdate | null,
    medicalRecordIds?: string[]
  ) => {
    try {
      let response;
      if (roleType === IRoleType.DIAGNOSTIC_CENTER) {
        response = await updateDiagnosticOrderApi({
          ...(status ? { status } : {}),
          id: order?.id,
          ...(values?.discount && { discountAmount: values?.discount }),
          ...(medicalRecordIds ? { medicalRecordIds } : {}),
        });
      } else {
        response = await handleUpdateOrderApi({
          ...(values &&
          order.type === IPharmacyDiagnosticOrderFilterEnum.HOME_DELIVERY
            ? {
                amount: values?.totalBill,
                tracking_id: values?.trackingId,
                delivery_partner: values?.deliveryPartner,
              }
            : {
                amount: values?.totalBill,
              }),
          orderId: order?.id,
          status,
        });
      }

      if (response.statusCode === 200) {
        const { id } = order as IPharmacyDiagnosticOrderDetail;
        if (medicalRecordIds) {
          const reportList = order?.reports?.filter((report) =>
            medicalRecordIds?.includes(report?.id)
          );
          const newList = list.map((orderDetail) => {
            if (orderDetail.id === id) {
              return {
                ...orderDetail,
                ...{
                  order,
                  status,
                  ...(medicalRecordIds ? { reports: reportList } : {}),
                },
              };
            }
            return orderDetail;
          });
          dispatch(updateOrderList(newList));

          notify(INotifyEnum.SUCCESS, response.message);
          return;
        }
        if (status === IOrderStatusEnum.COMPLETED) {
          const newList = list.map((orderDetail) => {
            if (orderDetail.id === id) {
              return {
                ...orderDetail,
                ...{
                  order,
                  status,
                  amount: values?.totalBill,
                  ...(order.type ===
                  IPharmacyDiagnosticOrderFilterEnum.HOME_DELIVERY
                    ? {
                        tracking_id: values?.trackingId,
                        delivery_partner: values?.deliveryPartner,
                      }
                    : {}),
                },
              };
            }
            return orderDetail;
          });
          dispatch(updateOrderList(newList));
        } else {
          const newPendingOrderList = pendingOrdersList.filter(
            (order) => order?.id !== id
          );
          dispatch(
            updateOrderList([
              ...list,
              {
                ...order,
                status,
              },
            ])
          );
          dispatch(updatePendingOrderList([...newPendingOrderList]));
        }
        notify(INotifyEnum.SUCCESS, response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { handlePharmacyOrderUpdate };
};

export default useHandlePharmacyOrderUpdate;
