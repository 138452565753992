import { useState } from "react";
import { convertDateDDMMM } from "..";
import { useAppDispatch } from "../../store/hooks";
import {
  fetchScheduleAppointmentList,
  setSelectedDayText,
} from "../../store/slices/doctor/schedule";

import { ISelectedDate } from "../../models/doctor";

const useAppointmentDateFilter = () => {
  const [selectedDate, setSelectedDate] = useState<null | ISelectedDate>(null);

  const dispatch = useAppDispatch();

  const applyDateRangeValues = (startDate: string, endDate: string) => {
    const filterOptions = {
      start: startDate,
      end: endDate,
    };
    setSelectedDate({
      startDate: startDate,
      endDate: endDate,
    });
    dispatch(
      setSelectedDayText(
        startDate === endDate
          ? "Today"
          : `${convertDateDDMMM(startDate)}-${convertDateDDMMM(endDate)}`
      )
    );
    dispatch(fetchScheduleAppointmentList(filterOptions));
  };

  return { applyDateRangeValues, selectedDate, setSelectedDate };
};

export default useAppointmentDateFilter;
