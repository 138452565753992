import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { InputField } from "../InputField";
import DropDownSelect from "../DropDownSelect";
import Modal from "../Modal";
import Button from "../Button";
import Dialog from "../Dialog";

import style from "./index.module.css";

import { IDropDownSelectionType } from "../../models/doctor";
import { IProfileInput } from "../../models/patient";

import useIsMobile from "../../utils/hooks/useIsMobile";
import useIsTablet from "../../utils/hooks/useIsTablet";

interface IProps {
  isOpen?: boolean;
  closeHandler?: () => void;
  title: string;
  name: string;
  profileDetailHandler: (
    event: React.ChangeEvent<any>,
    type: IProfileInput
  ) => void;
  age: string;
  mobileNo?: string;
  openMobileChange?: () => void;
  gender: string;
  isGenderOpen: boolean;
  openCloseGenderModal: () => void;
  saveGender: (value: string, type: IDropDownSelectionType) => void;
  saveProfile: () => void;
}

const AddEditProfile: React.FC<IProps> = (props) => {
  const {
    isOpen = true,
    closeHandler,
    title,
    name,
    profileDetailHandler,
    age,
    mobileNo,
    openMobileChange,
    gender,
    isGenderOpen,
    openCloseGenderModal,
    saveGender,
    saveProfile,
  } = props;
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const addEditProfileScreen = () => (
    <Grid item className={style.profileContainer}>
      <Typography component={"h6"} className={style.heading}>
        {title}
      </Typography>
      <FormControl fullWidth className={style.profileInputField}>
        <InputField
          label="Name"
          variant="outlined"
          placeholder="Name"
          value={name}
          onChange={(event) => profileDetailHandler(event, IProfileInput.NAME)}
        />
      </FormControl>
      <FormControl fullWidth className={style.profileInputField}>
        <InputField
          label="Age"
          variant="outlined"
          placeholder="Age"
          value={age}
          onChange={(event) => profileDetailHandler(event, IProfileInput.AGE)}
          fullWidth
        />
      </FormControl>
      {mobileNo && (
        <FormControl
          variant="outlined"
          className={style.profileInputField}
          fullWidth
        >
          <InputField
            label={"Mobile Number*"}
            value={mobileNo}
            onChange={(event) =>
              profileDetailHandler(event, IProfileInput.MOBILE_NUMBER)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    component={"p"}
                    className={style.selectorText}
                    onClick={openMobileChange}
                  >
                    <FormattedMessage id="change" defaultMessage="change" />
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      )}
      <FormControl
        variant="outlined"
        fullWidth
        className={style.profileInputField}
      >
        <InputField
          label={"Gender"}
          value={gender}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography
                  component={"p"}
                  className={style.selectorText}
                  onClick={openCloseGenderModal}
                >
                  <FormattedMessage id="select" defaultMessage="Select" />
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              color="warning"
              sx={{ padding: "0rem 0.8rem 0rem 1.6rem" }}
              onChange={(event) =>
                profileDetailHandler(event, IProfileInput.IS_DEFAULT)
              }
            />
          }
          label={
            <Typography component={"p"} className={style.createProfile}>
              <FormattedMessage
                id="use_as_main_profile"
                defaultMessage="Use as main profile"
              />
            </Typography>
          }
        />
      </FormControl>
      <Button btnTrigger={saveProfile}>
        <FormattedMessage id="save" defaultMessage="save" />
      </Button>
    </Grid>
  );

  return (
    <>
      {isMobile || isTablet ? (
        <Modal isOpen={isOpen} closeHandler={closeHandler}>
          {addEditProfileScreen()}
        </Modal>
      ) : (
        <Dialog
          fullWidth
          maxWidth="xs"
          isOpen={isOpen}
          closeHandler={closeHandler}
        >
          {addEditProfileScreen()}
        </Dialog>
      )}
      {isGenderOpen && (
        <DropDownSelect
          type={IDropDownSelectionType.GENDER}
          isOpen={isGenderOpen}
          closeHandler={openCloseGenderModal}
          title="gender"
          values={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ]}
          selectedValue={gender || ""}
          saveSelectedValues={saveGender}
        />
      )}
    </>
  );
};

export default AddEditProfile;
