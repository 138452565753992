import React, { ReactElement, useEffect, useRef } from "react";

type Props = {
  list: any[];
  onLoadMore: () => void;
  onRender: (item: any, id: number) => ReactElement;
};

const ListWithIntersection = ({ list, onRender, onLoadMore }: Props) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // API CALL HERE
          onLoadMore();
        }
      },
      { threshold: 0.5 }
    );

    const items = Array.from(targetRef?.current?.children ?? []);
    const lastItem = items?.[items.length - 1];

    if (lastItem) {
      observer.observe(lastItem);
    }

    return () => {
      observer.disconnect();
    };
  }, [list]);

  return <div ref={targetRef}>{list.map(onRender)}</div>;
};

export default ListWithIntersection;
