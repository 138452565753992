import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import style from "../styles/index.module.css";

import Modal from "../../Modal";
import { InputField } from "../../InputField";
import Button from "../../Button";
import Dialog from "../../Dialog";

import { transformClasses } from "../../../utils";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";

import { IOtpVerify } from "../../../models/onboarding";
import { IButtonType, IButtonVariant } from "../../../models/button";

interface IProps {
  handleOTPSubmit: (value: IOtpVerify) => void;
  resendOTP: () => void;
  navigateToMobileVerify: () => void;
  mobileNo: string;
}
const VerifyOTP: React.FC<IProps> = (props) => {
  const [seconds, setSeconds] = useState<number>(60);
  const [isTimerStart, setIsTimerStart] = useState<boolean>(false);

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const intl = useIntl();

  const { handleOTPSubmit, resendOTP, navigateToMobileVerify, mobileNo } =
    props;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    getValues,
  } = useForm<IOtpVerify>();

  const handleResendOTP = () => {
    if (isTimerStart) return;
    setIsTimerStart(true);
    resendOTP();
  };

  const handleOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    setValue("otp", value);
    trigger("otp");
  };

  const verifyOTPScreen = useCallback(() => {
    return (
      <Grid item>
        <Grid item className={style.otpHeader}>
          <Grid item>
            <Typography component={"h3"} className={style.heading}>
              <FormattedMessage
                id="enterOtpText"
                defaultMessage="Enter OTP to Continue"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography component={"p"} className={style.subHeading}>
              <FormattedMessage
                id="subHeadingOtp"
                defaultMessage="We have sent a 6 digit code on your mobile Number"
              />
              {mobileNo}
            </Typography>
            <Typography
              component={"span"}
              className={transformClasses(style.orangeText, style.otpLabeling)}
              onClick={navigateToMobileVerify}
            >
              <FormattedMessage
                id="changeNumber"
                defaultMessage="change number"
              />
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(handleOTPSubmit)}>
          <Grid item>
            <Grid item className={style.otpInnerContainer}>
              <Grid item className={style.otpInputContainer}>
                <InputField
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  inputProps={{ maxLength: 6 }}
                  {...register("otp", {
                    required: intl.formatMessage({
                      id: "otpRequired",
                      defaultMessage: "OTP is required",
                    }),
                    maxLength: 6,
                    onChange: handleOTPChange,
                    validate: (value) =>
                      value.length === 6 ||
                      intl.formatMessage({
                        id: "otpSixDigits",
                        defaultMessage: "OTP should have 6 digits",
                      }),
                  })}
                  label={intl.formatMessage({
                    id: "otp",
                    defaultMessage: "OTP",
                  })}
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "otp",
                    defaultMessage: "OTP",
                  })}
                  fullWidth
                />
              </Grid>
              <Typography
                component={"p"}
                className={transformClasses(
                  isTimerStart ? style.disabledResend : style.orangeText,
                  style.otpLabeling,
                  style.resendContainer
                )}
                onClick={handleResendOTP}
              >
                {isTimerStart && (
                  <Typography
                    component={"span"}
                    className={transformClasses(
                      style.subHeading,
                      style.otpLabeling
                    )}
                  >
                    {seconds}{" "}
                  </Typography>
                )}
                <FormattedMessage id="resendOTP" defaultMessage="Resend OTP" />
              </Typography>
              <Grid item className={style.mobileVerifyErrorContainer}>
                {errors.otp && (
                  <Typography component={"span"} className={style.errorMessage}>
                    {errors.otp.message}
                  </Typography>
                )}
              </Grid>

              <Grid item className={style.termsContainer}>
                <Typography
                  component={"p"}
                  className={transformClasses(
                    style.otpTermConditionText,
                    style.otpLabeling
                  )}
                >
                  <FormattedMessage
                    id="enterOtpAgree"
                    defaultMessage="By Entering OTP, I agree with the"
                  />
                  <Typography
                    component={"span"}
                    className={transformClasses(
                      style.orangeText,
                      style.otpLabeling
                    )}
                  >
                    {" "}
                    <FormattedMessage
                      id="term&condition"
                      defaultMessage="Terms and condition"
                    />
                  </Typography>
                </Typography>
              </Grid>
            </Grid>

            <Grid item className={style.buttonContainer}>
              <Grid item>
                <Button
                  type={IButtonType.SUBMIT}
                  variant={
                    errors.otp?.message || getValues("otp")?.length < 6
                      ? IButtonVariant.GREY
                      : IButtonVariant.ORANGE
                  }
                  disabled={
                    errors.otp?.message || getValues("otp")?.length < 6
                      ? true
                      : false
                  }
                >
                  <FormattedMessage
                    id="verifyDone"
                    defaultMessage="Verify and Done"
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  }, [isTimerStart, seconds]);

  useEffect(() => {
    if (isTimerStart) {
      if (seconds > 0) {
        window.setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        setIsTimerStart(false);
        setSeconds(60);
      }
    }
  }, [isTimerStart, seconds]);

  useEffect(() => {
    setIsTimerStart(true);
  }, []);

  return (
    <>
      {isMobile || isTablet ? (
        <Modal lineBottomBackground="white">{verifyOTPScreen()}</Modal>
      ) : (
        <Dialog fullWidth maxWidth="xs">
          {verifyOTPScreen()}
        </Dialog>
      )}
    </>
  );
};

export default VerifyOTP;
