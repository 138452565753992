import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Grid, Typography, Stack, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import DateSelectionTab from "../../DateSelectionTab";
import GetImages from "../../GetImages";
import Button from "../../Button";
import ChipSelect from "../../ChipSelect";
import {
  AppointmentTab,
  AppointmentTabsPanel,
  AppointmentTabPanel,
} from "../../AppointmentTab";
import Loader from "../../Loader";
import Checkout from "../Checkout";

import {
  IAppointmetEnums,
  IDoctorAvailabilityPlatform,
  IMakeAppointmentEvent,
} from "../../../models/doctor";
import {
  IClinicAppointmentSegment,
  IVideoAppointment,
} from "../../../models/ApiRequestResponse/doctor";
import { IRoleType } from "../../../models/role";
import { IFilterType } from "../../../models/patient";
import {
  IRolePermissionAccess,
  IScheduleSlotsPermissionEnums,
  IModalKeyEnums,
} from "../../../models/permission";

import { storage } from "../../../utils/Storage";
import {
  clinicAppointmentSegregation,
  getAppointmentSlotDetail,
  getNextSixDates,
  getPermissionAccess,
  openClinicMapLocation,
  timeInAmPmFormat,
} from "../../../utils";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchScheduleSlots,
  makePatientAppointment,
} from "../../../store/slices/doctor/appointment";
import { checkoutDetail } from "../../../store/slices/patient/findDoctor";
import { setShowPermissionAlert } from "../../../store/slices/permission";

const enum StepForm {
  BOOK_APPOINTMENT,
  CHECKOUT,
}

interface IProps {
  doctorId?: string;
  openScheduleModalHandler: (type: IAppointmetEnums) => void;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  platform?: IDoctorAvailabilityPlatform;
  isReschedule?: boolean;
}

const MakeAppointment: React.FC<IProps> = (props) => {
  const {
    doctorId,
    selectedTab,
    setSelectedTab,
    platform = IDoctorAvailabilityPlatform.BOTH,
    isReschedule = false,
  } = props;

  const [selectedDateTab, setSelectedDateTab] = useState<number>(0);
  const [appointmentType, setAppointmentType] =
    useState<IDoctorAvailabilityPlatform>();
  const [nextSixDays, setNextSixDays] = useState<
    { date: string; day: string }[]
  >([]);
  const [slotsDetail, setSlotsDetail] = useState<{
    videoAppointment: IVideoAppointment[];
    clinicAppointment: IClinicAppointmentSegment[];
  }>({ videoAppointment: [], clinicAppointment: [] });
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] =
    useState<string>("");
  const [isCheckoutShowDesktop, setIsCheckoutShowDesktop] =
    useState<boolean>(false);

  const roleType = storage.getSessionToken("roleType");

  const [step, setStep] = useState(StepForm.BOOK_APPOINTMENT);

  const dispatch = useAppDispatch();
  const checkoutDetailInfo = useAppSelector(
    (state) => state.findDoctor.checkoutPatientDetail
  );
  const makePatientAppointmentInfo = useAppSelector(
    (state) => state.appointment.makePatientAppointment
  );
  const scheduleSlots = useAppSelector((state) => state.appointment);
  const { permission } = useAppSelector((state) => state.rolePermission);

  const filterScheduleSlotsByDate = (type: IDoctorAvailabilityPlatform) => {
    if (Object.keys(scheduleSlots.slot).length === 0) return [];

    const filteredSlotsDateWise: any = scheduleSlots?.slot[
      type === IDoctorAvailabilityPlatform.VIDEO_CALL
        ? "videoAppointment"
        : "clinicAppointment"
    ].filter((slots: any, index: number) => {
      const [month, date, _] = slots.date.split("-");
      const dateMonth = dayjs(`${date}-${month}`, "MM-DD").format("DD MMM");
      if (dateMonth === nextSixDays[selectedDateTab]?.date) {
        return slots;
      }
    });
    setSlotsDetail({
      ...slotsDetail,
      ...(type === IDoctorAvailabilityPlatform.VIDEO_CALL
        ? { videoAppointment: filteredSlotsDateWise }
        : {
            clinicAppointment: clinicAppointmentSegregation(
              filteredSlotsDateWise
            ),
          }),
    });
  };

  const dateSelectionHandler = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setSelectedDateTab(newValue);
    },
    [appointmentType, selectedDateTab]
  );

  const handleAppointmentTab = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setAppointmentType(
      newValue === IMakeAppointmentEvent.VIDEO_CALL
        ? IDoctorAvailabilityPlatform.VIDEO_CALL
        : IDoctorAvailabilityPlatform.IN_CLINIC
    );
    setSelectedTab(newValue);
  };

  const dispatchActionSlotSelection = () => {
    const appointmentSlotDetail = getAppointmentSlotDetail(
      selectedTab,
      slotsDetail,
      selectedAppointmentSlot,
      nextSixDays,
      selectedDateTab
    );
    const dateSlotTime = `${appointmentSlotDetail?.date} ${appointmentSlotDetail?.startTime} - ${appointmentSlotDetail?.endTime}`;
    const isVideoCall = selectedTab === IMakeAppointmentEvent.VIDEO_CALL;
    const platformType = isVideoCall
      ? IDoctorAvailabilityPlatform.VIDEO_CALL
      : IDoctorAvailabilityPlatform.IN_CLINIC;
    const commonDetails = {
      dateSlotTime,
      type: platformType,
      slotId: selectedAppointmentSlot,
      ...(platformType === IDoctorAvailabilityPlatform.IN_CLINIC && {
        clinicId: appointmentSlotDetail?.clinicId,
        clinicName: `${appointmentSlotDetail?.clinicName}, ${appointmentSlotDetail?.clinicAddress}, ${appointmentSlotDetail?.clinicCity}`,
      }),
      fee: appointmentSlotDetail?.consultationFee ?? 0,
    };
    if (roleType === IRoleType.PATIENT) {
      dispatch(
        checkoutDetail({
          ...checkoutDetailInfo,
          ...commonDetails,
        })
      );
    } else {
      dispatch(
        makePatientAppointment({
          ...makePatientAppointmentInfo,
          ...commonDetails,
        })
      );
    }
  };

  const handleNext = () => {
    setStep((state) => state + 1);
  };

  const handleBack = () => {
    setStep((state) => state - 1);
  };

  useEffect(() => {
    if (
      roleType === IRoleType.DOCTOR ||
      roleType === IRoleType.JR_DOCTOR ||
      roleType === IRoleType.PHARMACY
    ) {
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.SCHEDULE_SLOTS,
        IScheduleSlotsPermissionEnums.VIEW_SCHEDULE_SLOT_BY_DOCTOR,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      dispatch(fetchScheduleSlots({}));
    } else if (roleType === IRoleType.PATIENT) {
      dispatch(fetchScheduleSlots({ doctorId }));
    }
    setNextSixDays(getNextSixDates(6));
  }, []);

  useEffect(() => {
    if (
      appointmentType ||
      checkoutDetailInfo?.type ||
      makePatientAppointmentInfo?.type
    )
      filterScheduleSlotsByDate(
        appointmentType ||
          checkoutDetailInfo?.type ||
          makePatientAppointmentInfo?.type
      );
  }, [
    appointmentType,
    selectedTab,
    selectedDateTab,
    scheduleSlots,
    nextSixDays,
  ]);

  useEffect(() => {
    setSelectedDateTab(IMakeAppointmentEvent.VIDEO_CALL);
  }, [selectedTab]);

  useEffect(() => {
    dispatchActionSlotSelection();
  }, [selectedAppointmentSlot, selectedDateTab, selectedTab]);

  if (scheduleSlots.isLoading) {
    return <Loader />;
  }

  if (step === StepForm.CHECKOUT) {
    return (
      <Checkout
        isOpen={isCheckoutShowDesktop}
        closeHandler={handleBack}
        type={
          selectedTab === 0 ? IFilterType.VIDEO_CALL : IFilterType.IN_CLINIC
        }
      />
    );
  } else {
    return (
      <Stack justifyContent={"center"} alignItems={"center"} p={2} gap={2}>
        <Typography variant="subtitle1" fontWeight={500} gutterBottom>
          <FormattedMessage
            id={isReschedule ? "select_slot_reschedule" : "book_appointment"}
            defaultMessage={
              isReschedule ? "Select Slot to Reschedule" : "Book an appointment"
            }
          />
        </Typography>
        <Grid container justifyContent={"center"} alignItems={"center"} gap={4}>
          {platform === IDoctorAvailabilityPlatform.BOTH && (
            <Grid item xs={12}>
              <AppointmentTabsPanel
                value={selectedTab}
                onChange={handleAppointmentTab}
                aria-label="basic tabs example"
                className={style.tabBtnContainer}
              >
                <AppointmentTabPanel
                  label={
                    <Grid item className={style.tabInnerContainer}>
                      <GetImages
                        name={selectedTab ? "VideoIcon" : "VideoWhiteIcon"}
                        width="20"
                        height="20"
                      />
                      <Typography component={"p"}>
                        <FormattedMessage
                          id="video_call"
                          defaultMessage="Video Call"
                        />
                      </Typography>
                    </Grid>
                  }
                />
                <AppointmentTabPanel
                  label={
                    <Grid item className={style.tabInnerContainer}>
                      <GetImages
                        name={selectedTab ? "ClinicWhiteIcon" : "ClinicIcon"}
                        width="20"
                        height="20"
                      />
                      <Typography component={"p"}>
                        <FormattedMessage
                          id="in_clinic"
                          defaultMessage="In-Clinic"
                        />
                      </Typography>
                    </Grid>
                  }
                />
              </AppointmentTabsPanel>
            </Grid>
          )}
          <Grid item xs={12} bgcolor={"white"} borderRadius={4}>
            <DateSelectionTab
              data={nextSixDays}
              selectedDateTab={selectedDateTab}
              dateSelectionHandler={dateSelectionHandler}
            />
            <AppointmentTab value={selectedTab} index={0}>
              {slotsDetail.videoAppointment?.length > 0 ? (
                <Grid item>
                  <Typography
                    textAlign={"center"}
                    component={"p"}
                    className={style.slotFeeText}
                  >
                    <FormattedMessage id="slot_fee" defaultMessage="Slot fee" />
                    <Typography component={"span"} className={style.slotPrice}>
                      ₹
                      {
                        slotsDetail.videoAppointment[0]?.slots[0]
                          ?.consultationFee
                      }
                    </Typography>
                  </Typography>
                  <Box
                    maxHeight={"40vh"}
                    pb={2}
                    sx={{
                      overflowY: "scroll",
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "1.4rem",
                    }}
                  >
                    {slotsDetail?.videoAppointment[0]?.slots?.map((info) => (
                      <ChipSelect
                        data={[
                          {
                            label: timeInAmPmFormat(info.slotStartTime),
                            value: info.slotId,
                            isBooked: info.isBooked,
                          },
                        ]}
                        selectedValue={selectedAppointmentSlot}
                        setSelectedValue={setSelectedAppointmentSlot}
                        showSlots={true}
                      />
                    ))}
                  </Box>
                </Grid>
              ) : (
                <Grid item>
                  <Typography
                    component={"h1"}
                    align="center"
                    padding={"4rem 0rem"}
                  >
                    <FormattedMessage
                      id="no_slot_available"
                      defaultMessage="No Slot Available"
                    />
                  </Typography>
                </Grid>
              )}
            </AppointmentTab>
            <AppointmentTab value={selectedTab} index={1}>
              {slotsDetail?.clinicAppointment.length > 0 ? (
                <Grid item className={style.inClinicContainer}>
                  {slotsDetail?.clinicAppointment[0].slots.map(
                    (info, index) => (
                      <Grid
                        item
                        key={`${info.clinicName}-${index}`}
                        className={style.clinicInfoWrapper}
                      >
                        <Grid item className={style.clinicDetailContainer}>
                          <Grid item>
                            <Typography
                              component={"h6"}
                              className={style.clinicName}
                            >
                              {info.clinicName}
                            </Typography>
                            <Typography
                              component={"p"}
                              className={style.priceBranch}
                            >
                              ₹{info.consultationFee} • {info.clinicAddress}
                            </Typography>
                          </Grid>
                          {roleType === IRoleType.PATIENT && (
                            <Grid
                              item
                              className={style.directionIcon}
                              onClick={() =>
                                openClinicMapLocation(
                                  info.latitude,
                                  info.longitude
                                )
                              }
                            >
                              <GetImages
                                name="LocationAccessSmallIcon"
                                width="20"
                                height="20"
                              />
                              <Typography
                                component={"p"}
                                className={style.mapText}
                              >
                                <FormattedMessage
                                  id="maps"
                                  defaultMessage="Maps"
                                />
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2,1fr)",
                            gap: "0.8rem",
                          }}
                        >
                          {info.slots.map((slot: any) => (
                            <ChipSelect
                              data={[
                                {
                                  label: `${timeInAmPmFormat(
                                    slot.slotStartTime
                                  )} - ${timeInAmPmFormat(slot.slotEndTime)}`,
                                  value: slot.slotId,
                                  isBooked: slot.isBooked,
                                },
                              ]}
                              selectedValue={selectedAppointmentSlot}
                              setSelectedValue={setSelectedAppointmentSlot}
                              showSlots={true}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              ) : (
                <Typography
                  component={"h1"}
                  align="center"
                  padding={"4rem 0rem"}
                >
                  <FormattedMessage
                    id="no_slot_available"
                    defaultMessage="No Slot Available"
                  />
                </Typography>
              )}
            </AppointmentTab>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} gap={4}>
              <Button
                disabled={!selectedAppointmentSlot}
                btnTrigger={handleNext}
              >
                <GetImages name="ChekoutBtnIcon" width="28" height="20" />
                <FormattedMessage id="next" defaultMessage="Next" />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  }
};

export default MakeAppointment;
