import { isClinic, topBarDropdownDispatchingEnums, transformClasses } from "..";

import GetImages from "../../components/GetImages";
import CloseIcon from "@mui/icons-material/Close";
import { FiberManualRecord } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import {
  IDoctorConsultationSelection,
  IProfileDropdownEnums,
  IKeyPairsValues,
  IMedicalRecordTypeEnum,
  IPatientDetailEnumWeb,
  IChangeHospitalModuleName,
} from "../../models/doctor";

import style from "./index.module.css";
import MobileTopNavstyle from "../../components/Navigation/Mobile/MobileTopNavigation/index.module.css";

import ChatIcon from "../../assets/chat.png";
import Team1 from "../../assets/team-1.png";
import Team2 from "../../assets/team-2.png";
import Team3 from "../../assets/team-3.png";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { showHideAddMember } from "../../store/slices/videoAppointment";
import {
  setIsVideoCallChatInitiated,
  setIsVideoMinimizedOrPrescInitiated,
} from "../../store/slices/videoAppointment";

import { IOrderTypeEnums } from "../../models/ApiRequestResponse/patient";
import { IOrderStatusEnum } from "../../models/ApiRequestResponse/pharmacy";
import { IRoleType } from "../../models/role";
import { Link, useNavigate } from "react-router-dom";
import { detail } from "../../store/slices/doctor/availabilitySettings";
import {
  setChangeHospitalModuleName,
  setTopBarDropdownItems,
} from "../../store/slices/doctor/topNavigation";
import { setStepsTest } from "../../store/slices/patient/tests";
import { setIsPendingOrderRequestsOpen } from "../../store/slices/pharmacyClinicDiagnostic";
import { storage } from "../Storage";
import { useIntl } from "react-intl";

export const getStartedTutorial = [
  {
    image: <GetImages name="StayConnectedImage" width="304" height="245" />,
    title: "stayConnectedTitle",
    description: "stayConnectedDescription",
  },
  {
    image: <GetImages name="TestOnlineImage" width="268" height="279" />,
    title: "bookTestTitle",
    description: "bookTestDescription",
  },
  {
    image: <GetImages name="BookDoctorImage" width="310" height="199" />,
    title: "bookDoctorTitle",
    description: "bookDoctorDescription",
  },
];

export const locationAccessDetail = [
  {
    icon: <GetImages name="DoctorSearchIcon" width="20" height="20" />,
    title: "doctors",
    subHeding: "nearbyText",
  },
  {
    icon: <GetImages name="ClinicIcon" width="20" height="20" />,
    title: "clinicsText",
    subHeding: "nearbyText",
  },
  {
    icon: <GetImages name="LocationAccessIcon" width="36" height="36" />,
    title: "directionsText",
    subHeding: "toThemText",
  },
];

export const appointmentActionList = [
  {
    identifier: IPatientDetailEnumWeb.CANCEL,
    label: "Cancel",
    icon: "CancelIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RESCHEDULE,
    label: "Reschedule",
    icon: "RescheduleIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.CHAT,
    label: "Chat",
    icon: "ChatIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RECORD,
    label: "Record",
    icon: "RecordWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.PRESCRIPTION,
    label: "Prescription",
    icon: "PrescriptionWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
];

export const ipDashboardActionList = [
  {
    identifier: IPatientDetailEnumWeb.CHAT,
    label: "Chat",
    icon: "ChatIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.IP_DETAIL,
    label: "Details",
    icon: "SettingGreyedIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RECORD,
    label: "Record",
    icon: "RecordWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
];

export const clinicTeamList = [
  {
    userId: "USR-1",
    name: "Chat",
    role: "JR_DOCTOR",
    profilePhoto: ChatIcon,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-2",
    name: "Doctor",
    role: "JR_DOCTOR",
    profilePhoto: Team1,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-3",
    name: "Jr Doctor",
    role: "JR_DOCTOR",
    profilePhoto: Team2,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-4",
    name: "Co-or...",
    role: "JR_DOCTOR",
    profilePhoto: Team3,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
];

export const videoToolbarTop = () => {
  const dispatch = useAppDispatch();
  const videoToolbarTopList = [
    {
      icon: "VideoChatIcon",
      css: style.btnLayer,
      handler: () => {
        dispatch(setIsVideoCallChatInitiated({ value: true }));
        dispatch(setIsVideoMinimizedOrPrescInitiated({ value: true }));
      },
    },
    {
      icon: "AddMemberIcon",
      css: transformClasses(style.btnLayer, style.spacing),
      handler: () => dispatch(showHideAddMember()),
    },
    {
      icon: "CameraSwitchIcon",
      css: style.btnLayer,
      handler: () => {},
    },
  ];
  return { videoToolbarTopList };
};

export const socialLinks = [
  {
    iconName: "InstagramIcon",
    link: "#",
  },
  {
    iconName: "FBIcon",
    link: "#",
  },
  {
    iconName: "YoutubeIcon",
    link: "#",
  },
  {
    iconName: "LinkedInIcon",
    link: "#",
  },
];

export const contactBtns = (token: string | null) => [
  { preText: null, btnText: "Call Us", iconName: "TelePhoneIcon", link: "#" },
  { preText: null, btnText: "FAQ", iconName: "BookPlusIcon", link: "/faq" },
  ...(token
    ? []
    : [
        {
          preText: "Already have a Account ?",
          btnText: "Log in",
          iconName: null,
          link: "/",
        },
      ]),
];

export const homeTitleDetail = [
  {
    icon: <GetImages name="DoctorSearchIcon" width="20" height="20" />,
    title: "doctors",
    subHeding: "available",
    moveTo: "/find-doctor",
  },
  {
    icon: <GetImages name="MedicineSmallIcon" width="20" height="20" />,
    title: "medicines",
    subHeding: "free_delivery",
    moveTo: "/medicines",
  },
  {
    icon: <GetImages name="TestSmallIcon" width="20" height="20" />,
    title: "tests",
    subHeding: "flat_off",
    moveTo: "/test-reports",
  },
];

export const medicalRecordFilterList = [
  {
    label: "All",
    value: IMedicalRecordTypeEnum.All,
  },
  {
    label: "Prescriptions",
    value: IMedicalRecordTypeEnum.PRESCRIPTION,
  },
  {
    label: "Reports",
    value: IMedicalRecordTypeEnum.REPORT,
  },
];

export const orderFilterList = [
  {
    label: "All",
    value: IOrderTypeEnums.ALL,
  },
  {
    label: "Clinic Visit",
    value: IOrderTypeEnums.IN_CLINIC,
  },
  {
    label: "Video Call",
    value: IOrderTypeEnums.VIDEO_CONSULTATION,
  },
  {
    label: "Medicine",
    value: IOrderTypeEnums.MEDICINE,
  },
  {
    label: "Tests",
    value: IOrderTypeEnums.TESTS,
  },
];

export const faqAllTag = [
  {
    id: "all",
    tag: "All",
  },
];

export const allFilterList = [
  {
    label: "All",
    value: "all",
  },
];

export const metriceLabelByStatus: IKeyPairsValues = {
  [IOrderStatusEnum.PENDING]: "Order Request",
  [IOrderStatusEnum.CONFIRMED]: "Order Accepted",
  [IOrderStatusEnum.REJECTED]: "Order Rejected",
  [IOrderStatusEnum.COMPLETED]: "Order Completed",
  [IOrderStatusEnum.CANCELLED]: "Total Sales",
};

export const offeringData = [
  {
    icon: "searchDoctor",
    title: "Consult Top Neuro Doctors",
    desc: "Connect instantly with Top Neuro Specialist through Video consultation or Nearby Clinics",
  },
  {
    icon: "searchDoctor2",
    title: "Choose Free Second Opinion",
    desc: "Get Free Second opinion of your MRI reports and Check you need Surgery of Not ",
  },
  {
    icon: "MedicineNavIcon",
    title: "Get Flat 10% OFF on Medicine and Tests",
    desc: "Order all Neuro Medicines and Test from The Brain Spine App and Get Flat 10% OFF",
  },
  {
    icon: "contactIcon",
    title: "Get 24X7 Helpline Support",
    desc: "Get 24X7 Support For any Queries related to Appointment Booking or Medical Emergency",
  },
  {
    icon: "plusDropIcon",
    title: "Get free 30 Days Medical Support",
    desc: "Avail 30 Days Free Medical Support in case on any Medical Emergencies",
  },
];
export const statsData = [
  { name: "Specialty", number: 10 },
  { name: "Brain and Spine Surgeries", number: 10000 },
  { name: "Patients Life Touched", number: 60000 },
];

export enum IDeleteAfterEnum {
  NEVER = "Never",
  SELECT_DATE = "Select a Date",
}

export const daysList = [
  {
    available: true,
    value: "Mon",
  },
  {
    available: true,
    value: "Tue",
  },
  {
    available: true,
    value: "Wed",
  },
  {
    available: true,
    value: "Thu",
  },
  {
    available: true,
    value: "Fri",
  },
  {
    available: true,
    value: "Sat",
  },
  {
    available: true,
    value: "Sun",
  },
];

export const deleteAfterList = [
  {
    label: IDeleteAfterEnum.NEVER,
    value: IDeleteAfterEnum.NEVER,
  },
  {
    label: IDeleteAfterEnum.SELECT_DATE,
    value: IDeleteAfterEnum.SELECT_DATE,
  },
];

export const typeOfConsultation = [
  {
    label: IDoctorConsultationSelection.IN_CLINIC,
    value: "1",
  },
  {
    label: IDoctorConsultationSelection.VIDEO_CONSULTATION,
    value: "2",
  },
];

export const consultationTypes: IKeyPairsValues = {
  "1": IDoctorConsultationSelection.IN_CLINIC,
  "2": IDoctorConsultationSelection.VIDEO_CONSULTATION,
};

export const availabilityInputsNames: IKeyPairsValues = {
  startTime: "startTime",
  endTime: "endTime",
  slotDuration: "slotDuration",
  bufferDuration: "bufferDuration",
  consultationFee: "consultationFee",
  appointmentDisplay: "appointmentDisplay",
  totalSlots: "totalSlots",
};

const notificationDropdownItems = [
  {
    iconName: "NotificationBellIcon",
    text: "notification",
    identifier: IProfileDropdownEnums.NOTIFICATION,
  },
];
const logoutDropdownItems = [
  {
    iconName: "LogoutIcon",
    text: "logout",
    identifier: IProfileDropdownEnums.LOGOUT,
  },
];

export const profileDropdownItems = [
  {
    iconName: "SmileSmallIcon",
    text: "profile",
    identifier: IProfileDropdownEnums.PROFILE,
  },
];

const helpSupportDropdownItem = [
  {
    iconName: "HelpSupportIcon",
    text: "help_support",
    identifier: IProfileDropdownEnums.HELP_SUPPORT,
  },
];

const doctorProfileDropdownItems = [
  ...profileDropdownItems,
  {
    iconName: "SettingSmallIcon",
    text: "availability",
    identifier: IProfileDropdownEnums.AVAILABILITY_SETTINGS,
  },
  {
    iconName: "StarSmallIcon",
    text: "reviews",
    identifier: IProfileDropdownEnums.REVIEW,
  },
  {
    iconName: "ClinicTeamSmallIcon",
    text: "clinical_team",
    identifier: IProfileDropdownEnums.CLINICAL_TEAM,
  },
  {
    iconName: "TemplatesSmallIcon",
    text: "templates",
    identifier: IProfileDropdownEnums.TEMPLATES,
  },
  ...helpSupportDropdownItem,
  ...notificationDropdownItems,
  ...logoutDropdownItems,
];

const pharmacyDiagnosticDropdownItems = [
  ...profileDropdownItems,
  ...notificationDropdownItems,
  ...logoutDropdownItems,
];

export const doctorPharmacyDiagnosticDropdownNavItems: any = {
  [IRoleType.DOCTOR]: doctorProfileDropdownItems,
  [IRoleType.JR_DOCTOR]: doctorProfileDropdownItems,
  [IRoleType.PHARMACY]: pharmacyDiagnosticDropdownItems,
  [IRoleType.DIAGNOSTIC_CENTER]: pharmacyDiagnosticDropdownItems,
};

export const patientDropdownNavItem = [
  ...notificationDropdownItems,
  ...logoutDropdownItems,
];

export const patientTopNavItems = [
  {
    path: "/home",
    label: "home",
  },
  {
    path: "/find-doctor",
    label: "doctors",
  },
  {
    path: "/medicines",
    label: "medicines",
  },
  {
    path: "/test-reports",
    label: "tests",
  },
  {
    path: "/health-hub",
    label: "health",
  },
];

const doctorBottomNavigation = [
  {
    path: "/schedule",
    icon: "ScheduleIcon",
    selectedIcon: "ScheduleSelectedIcon",
    label: "schedule",
  },
  {
    path: "/ip-dash",
    icon: "IpDashIcon",
    selectedIcon: "IpDashSelectedIcon",
    label: "ip_dash",
  },
  {
    path: "/chats",
    icon: "ChatIcon",
    selectedIcon: "ChatSelectedIcon",
    label: "chat",
  },
  {
    path: "/profile",
    icon: "ProfileIcon",
    selectedIcon: "ProfileSelectedIcon",
    label: "profile",
  },
];

const pharmacyBottomNavigation = [
  {
    path: "/orders",
    icon: "OrderSmileIcon",
    selectedIcon: "OrderSmileSelectedIcon",
    label: "orders",
  },
  {
    path: "/clinic-patient",
    icon: "MyPatientsIcon",
    selectedIcon: "MyPatientsSelectedIcon",
    label: "my_patients",
  },
  {
    path: "/profile-details",
    icon: "PharmacyProfileSmileIcon",
    selectedIcon: "PharmacyProfileSmileSelectedIcon",
    label: "profile",
  },
];

const patientBottomNavigation = [
  {
    path: "/home",
    icon: "HomeIcon",
    selectedIcon: "HomeSelectedIcon",
    label: "home",
  },
  {
    path: "/find-doctor",
    icon: "DoctorIcon",
    selectedIcon: "DoctorSelectedIcon",
    label: "doctors",
  },
  {
    path: "/medicines",
    icon: "MedicineIcon",
    selectedIcon: "MedicineSelectedIcon",
    label: "medicines",
  },
  {
    path: "/test-reports",
    icon: "TestIcon",
    selectedIcon: "TestSelectedIcon",
    label: "tests",
  },
  {
    path: "/health-hub",
    icon: "TvIcon",
    selectedIcon: "TvSelectedIcon",
    label: "health",
  },
];
export const bottomNavigation = {
  [IRoleType.PATIENT]: patientBottomNavigation,
  [IRoleType.DOCTOR]: doctorBottomNavigation,
  [IRoleType.JR_DOCTOR]: doctorBottomNavigation,
  [IRoleType.PHARMACY]: pharmacyBottomNavigation,
};

export const patientFooterData = {
  secondColumnLinks: [
    {
      title: "company",
      items: [
        "patient_side",
        "healthy_individual",
        "about_us",
        "our_app",
        "careers",
        "contact",
      ],
    },
    {
      title: "terms_utility",
      items: [
        "cookies_policy",
        "terms_and_conditions",
        "privacyPolicyText",
        "licenses",
      ],
    },
  ],
  thirdColumnLinks: [
    {
      title: "patient",
      items: ["treatment", "success_stories"],
    },
    {
      title: "health_hub",
      items: ["exercise_videos", "health_tips"],
    },
    {
      title: "faq",
      items: [],
    },
  ],
  fourthColumnLinks: [
    {
      title: "featured",
      items: [
        "appointment_booking",
        "second_opinion",
        "find_doctor",
        "free_MRI_CT_review",
        "medicine_delivery",
        "international_patient",
        "mental_and_brain_check",
        "spine_health_check",
      ],
    },
  ],
  socialIconsList: ["InstagramIcon", "FbIcon", "YouTubeIcon", "LinkedInIcon"],
};

export const doctorPharmacyDiagnosticDesktopTopNav = () => ({
  [IRoleType.DOCTOR]: [
    {
      path: "/schedule",
      icon: <GetImages name="ScheduleLargeIcon" width="32" height="32" />,
      selectedIcon: (
        <GetImages name="ScheduleSelectedLargeIcon" width="32" height="32" />
      ),
      label: "schedule",
    },
    {
      path: "/ip-dash",
      icon: <GetImages name="IpDashLargeIcon" width="32" height="32" />,
      selectedIcon: (
        <GetImages name="IpDashLargeSelectedIcon" width="32" height="32" />
      ),
      label: "ip_dashboard",
    },
  ],
  [IRoleType.PHARMACY]: [
    ...(isClinic()
      ? [
          {
            path: "/clinic-patient",
            icon: <GetImages name="MyPatientIcon" width="36" height="36" />,
            selectedIcon: (
              <GetImages name="MyPatientSelectedIcon" width="36" height="36" />
            ),
            label: "my_patients",
          },
        ]
      : []),
    {
      path: "/orders",
      icon: <GetImages name="PharmacyOrdersIcon" width="36" height="36" />,
      selectedIcon: (
        <GetImages name="PharmacyOrdersSelectedIcon" width="36" height="36" />
      ),
      label: "orders",
    },
  ],
  [IRoleType.DIAGNOSTIC_CENTER]: [
    {
      path: "/orders",
      icon: <GetImages name="ScheduleLargeIcon" width="32" height="32" />,
      selectedIcon: (
        <GetImages name="ScheduleSelectedLargeIcon" width="32" height="32" />
      ),
      label: "orders",
    },
  ],
});
export const generateNavItems = (
  setConfirmDeleteAvailability: React.Dispatch<React.SetStateAction<boolean>>,
  selectedHospital: string
) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const availabilityDetail = useAppSelector(
    (state) => state.availability.detail
  );
  const dispatch = useAppDispatch();
  const { cartDetails } = useAppSelector((state) => state.tests);
  const isClinicRole = isClinic();

  const doctorItem = [
    {
      path: "/availability-settings",
      icon: <GetImages name="SettingOrangeIcon" width="36" height="36" />,
      title: intl.formatMessage({
        id: "availability_settings",
        defaultMessage: "Availability Settings",
      }),
      locationIcon: false,
      subText: "",
      secondIcon: (
        <Grid item onClick={() => navigate(-1)}>
          <CloseIcon sx={{ height: 24, weight: 24 }} />
        </Grid>
      ),
      isShowSearch: false,
    },
    {
      path: "/availability-create",
      icon: <GetImages name="SettingGreyedIcon" width="24" height="24" />,
      title: intl.formatMessage({
        id: "availability_settings",
        defaultMessage: "Availability Settings",
      }),
      locationIcon: false,
      subText: "",
      secondIcon: (
        <Grid item className={MobileTopNavstyle.availabilityBtnsContainer}>
          {availabilityDetail && (
            <Grid
              item
              className={MobileTopNavstyle.trashBtn}
              onClick={() =>
                setConfirmDeleteAvailability((prev: boolean) => !prev)
              }
            >
              <GetImages
                name="TrashIcon"
                width="24"
                height="24"
                fill="var(--red-600)"
              />
            </Grid>
          )}
          <Grid
            item
            onClick={() => {
              navigate(-1);
              dispatch(detail(null));
            }}
          >
            <CloseIcon sx={{ height: 24, weight: 24 }} />
          </Grid>
        </Grid>
      ),
      isShowSearch: false,
    },
    {
      path: "/schedule",
      icon: <GetImages name="ScheduleNavIcon" width="36" height="36" />,
      title: intl.formatMessage({
        id: "schedule",
        defaultMessage: "Schedule",
      }),
      locationIcon: false,
      subText: "",
      secondIcon: (
        <Grid item className={MobileTopNavstyle.scheduleTopIconContainer}>
          <Link to={"/availability-settings"}>
            <GetImages name="SettingGreyedIcon" width="24" height="24" />
          </Link>
        </Grid>
      ),
      isShowSearch: true,
    },
    {
      path: "/ip-dash",
      icon: <GetImages name="IpDashNavIcon" width="36" height="36" />,
      title: selectedHospital,
      locationIcon: false,
      subTextHandler: () => {
        dispatch(
          setChangeHospitalModuleName(IChangeHospitalModuleName.FROM_LIST)
        );
        dispatch(
          setTopBarDropdownItems(
            topBarDropdownDispatchingEnums[
              IProfileDropdownEnums.CHANGE_HOSPITAL
            ]
          )
        );
      },
      subText: "Change",
      secondIcon: null,
      isShowSearch: true,
    },
    {
      path: "/chats",
      icon: <GetImages name="ChatNavIcon" width="36" height="36" />,
      title: intl.formatMessage({
        id: "chats",
        defaultMessage: "Chats",
      }),
      locationIcon: false,
      subText: "",
      secondIcon: null,
      isShowSearch: true,
    },
  ];

  const pharmacyItem = [
    {
      path: "/orders",
      icon: (
        <Grid item className={MobileTopNavstyle.pharmacyOrders}>
          <GetImages name="PharamcyOrdersIcon" width="55" height="16" />
          <Grid item className={MobileTopNavstyle.verticalLine}>
            <GetImages name="VerticalLineImage" width="1" height="22" />
          </Grid>
          <Grid
            item
            className={MobileTopNavstyle.request}
            onClick={() => dispatch(setIsPendingOrderRequestsOpen())}
          >
            <Grid item className={MobileTopNavstyle.circleIcon}>
              <GetImages name="CircleEyeIcon" width="6" height="6" />
            </Grid>
            <Grid item className={MobileTopNavstyle.eyeIcon}>
              <GetImages name="EyeIcon" width="28" height="24" />
            </Grid>
            <Typography component={"p"}>Request</Typography>
          </Grid>
        </Grid>
      ),
      title: "",
      locationIcon: false,
      subText: "",
      secondIcon: (
        <Grid item className={MobileTopNavstyle.scheduleTopIconContainer}>
          {!isClinicRole && (
            <Grid item onClick={() => navigate("/profile-details")}>
              <GetImages name="ProfileSmileIcon" width="24" height="24" />
            </Grid>
          )}
        </Grid>
      ),
      isShowSearch: true,
    },
    {
      path: "/clinic-patient",
      icon: <GetImages name="PatientsModuleIcon" width="95" height="14" />,
      title: "",
      locationIcon: false,
      subText: "",
      secondIcon: null,
      isShowSearch: true,
    },
  ];

  return {
    [IRoleType.PATIENT]: [
      {
        path: "/home",
        icon: <GetImages name="HomeNavIcon" width="36" height="36" />,
        title: storage.getSessionToken("name") ?? "Unknown",
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: <GetImages name="MessageNavIcon" width="24" height="24" />,
        isShowSearch: true,
      },
      {
        path: "/find-doctor",
        icon: <GetImages name="DoctorNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "find_doctors",
          defaultMessage: "Find Doctors",
        }),
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: null,
        isShowSearch: true,
      },
      {
        path: "/medicines",
        icon: <GetImages name="MedicineNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "get_medicines",
          defaultMessage: "Get Medicines",
        }),
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: null,
        isShowSearch: true,
      },
      {
        path: "/test-reports",
        icon: <GetImages name="TestNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "book_tests",
          defaultMessage: "Book Tests",
        }),
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: (
          <Grid
            item
            sx={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              dispatch(setStepsTest(3));
            }}
          >
            <GetImages name="CartNavIcon" width="24" height="24" />
            <Typography className={MobileTopNavstyle.redMark}>
              {cartDetails?.tests?.length > 0 && (
                <FiberManualRecord
                  style={{ fontSize: "small", color: "var(--red-400)" }}
                />
              )}
            </Typography>
          </Grid>
        ),
        isShowSearch: true,
      },
      {
        path: "/health-hub",
        icon: <GetImages name="TvNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "health_hub",
          defaultMessage: "Health Hub",
        }),
        secondIcon: null,
        locationIcon: false,
        subText: "",
        isShowSearch: true,
      },
    ],
    [IRoleType.DOCTOR]: doctorItem,
    [IRoleType.JR_DOCTOR]: doctorItem,
    [IRoleType.PHARMACY]: pharmacyItem,
    [IRoleType.DIAGNOSTIC_CENTER]: pharmacyItem,
  };
};

export const doctorSpecialitiesBreakpoints = {
  0: {
    slidesPerView: 1.9,
    spaceBetween: 10,
  },
  450: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 3.5,
    spaceBetween: 40,
  },
  900: {
    slidesPerView: 5,
    spaceBetween: 30,
  },
  1400: {
    slidesPerView: 6,
    spaceBetween: 30,
  },
  1600: {
    slidesPerView: 8,
    spaceBetween: 50,
  },
};
