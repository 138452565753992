import React, { useEffect, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import GetImages from "../../../GetImages";

import style from "./index.module.css";

import DoctorImage from "../../../../assets/team-1.png";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  fetchAuthTokenAgora,
  setCallerDetails,
  setChannelName,
} from "../../../../store/slices/videoAppointment";

import incomingRingtone from "../../../../assets/incoming_ring.mp3";

const IncomingCall: React.FC = () => {
  const incomingRingtoneRef = useRef<HTMLAudioElement | null>(null);

  const dispatch = useAppDispatch();
  const { callerDetails } = useAppSelector((state) => state.videoAppointment);

  const navigate = useNavigate();

  const joinVideoCall = () => {
    navigate("/join-room");
    dispatch(fetchAuthTokenAgora(callerDetails?.bookingId));
    dispatch(setChannelName(callerDetails?.bookingId));
    dispatch(setCallerDetails(null));
  };

  const rejectCall = () => dispatch(setCallerDetails(null));

  useEffect(() => {
    if (!incomingRingtoneRef.current) {
      incomingRingtoneRef.current = new Audio(incomingRingtone);
      incomingRingtoneRef.current.loop = true;
    }

    const audio = incomingRingtoneRef.current;

    if (callerDetails) {
      audio.play().catch((err: any) => {
        console.warn("Autoplay blocked. Play audio manually:", err);
      });
    } else {
      audio.pause();
      audio.currentTime = 0;
    }

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [callerDetails]);

  return (
    <Grid container className={style.callingScreen}>
      <Typography component={"p"} className={style.statusText}>
        Incoming Video Call
      </Typography>
      <Grid item className={style.profileInfo}>
        <img
          src={
            callerDetails?.callerPhoto === "photo_url"
              ? DoctorImage
              : callerDetails?.callerPhoto
          }
          alt="Profile"
          className={style.profilePic}
        />
        <Typography component={"p"} className={style.profileName}>
          {callerDetails?.callerName}
        </Typography>
        {(callerDetails?.experience ||
          callerDetails?.speciality ||
          callerDetails?.qualification) && (
          <Typography component={"p"} className={style.profileDetails}>
            {callerDetails?.experience} yrs • {callerDetails?.speciality} •
            {JSON.parse(callerDetails?.qualification)[0]?.degree_name}
          </Typography>
        )}
      </Grid>
      <Grid item className={style.callActionBtns}>
        <button className={style.endCallButton} onClick={rejectCall}>
          <GetImages name="VideoCallIcon" height="28" width="28" />
        </button>
        <button className={style.pickCallButton} onClick={joinVideoCall}>
          <GetImages name="VideoCallIcon" height="28" width="28" />
        </button>
      </Grid>
    </Grid>
  );
};

export default IncomingCall;
