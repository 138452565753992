import React from "react";
import { Grid, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import DiseaseActionsDesktop from "../DiseaseActionsDesktop";
import DiseaseActionsMobile from "../DiseaseActionsMobile";

import {
  IHealthHubInteractionType,
  IHealthHubUserInteraction,
} from "../../../../models/ApiRequestResponse/patient";

import useIsMobile from "../../../../utils/hooks/useIsMobile";

type DiseaseContentProps = {
  content: string;
  diseaseId: string;
  diseaseTitle: string;
  diseaseDescription: string;
  diseaseImage: string;
  likes: number;
  user_interaction: IHealthHubUserInteraction[];
};

const DiseaseContent: React.FC<DiseaseContentProps> = ({
  content,
  diseaseId,
  diseaseTitle,
  diseaseDescription,
  diseaseImage,
  likes,
  user_interaction,
}) => {
  const navigate = useNavigate();

  const { isMobile } = useIsMobile();

  return (
    <Grid>
      <Grid>
        <Grid
          className={style.blogMetaContainer}
          sx={{
            backgroundColor: { xs: "var(--neutral-100)", sm: "transparent" },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "600",
              color: "var(--black-900)",
              fontSize: {
                xs: "1.6rem",
                sm: "2.4rem",
                md: "2.8rem",
                lg: "3.2rem",
              },
              lineHeight: {
                xs: "2.4rem",
                sm: "3.6rem",
                md: "4rem",
                lg: "4.8rem",
              },
              display: { xs: "none", sm: "block" },
            }}
            className={style.blogTitle}
          >
            {diseaseTitle}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "400",
              color: "var(--black-900)",
              fontSize: { xs: "1.4rem", sm: "1.6rem" },
              lineHeight: { xs: "2.1rem", sm: "2.4rem" },
              display: { xs: "none", sm: "block" },
            }}
            className={style.blogDescription}
          >
            {diseaseDescription}
          </Typography>
          <Grid
            className={style.blogImageContainer}
            sx={{
              position: "relative",
              width: { xs: "100%", sm: "auto" },
              height: {
                xs: "24rem",
                sm: "26rem",
                md: "30rem",
                lg: "37.1rem",
              },
              borderRadius: { xs: "0rem 0rem 1.6rem 1.6rem;", sm: "1.6rem" },
              overflow: "hidden",
            }}
          >
            <img src={diseaseImage} alt={diseaseTitle} />
            <ChevronLeft
              className={style.goBackIcon}
              onClick={() => navigate(-1)}
              fontSize="large"
              sx={{
                display: { xs: "block", sm: "none" },
                cursor: "pointer",
                position: "absolute",
                top: "3.5rem",
                left: "1.6rem",
                color: "var(--neutral-100)",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={style.blogContent}
        sx={{
          backgroundColor: { xs: "var(--neutral-100)", sm: "transparent" },
          padding: { xs: "0rem 1.6rem", sm: "0rem" },
          marginBottom: { xs: "6rem", sm: "1rem" },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Grid>
      <Grid
        className={style.blogActionsContainer}
        sx={{
          padding: { xs: "0rem 1.6rem 1.6rem 1.6rem", sm: "0rem" },
        }}
      >
        {isMobile ? (
          <DiseaseActionsMobile
            isLiked={user_interaction.some(
              (interaction) =>
                interaction.type === IHealthHubInteractionType.LIKE
            )}
            isFavourited={user_interaction.some(
              (interaction) =>
                interaction.type === IHealthHubInteractionType.FAVOURITE
            )}
            likes={likes}
            diseaseId={diseaseId}
          />
        ) : (
          <DiseaseActionsDesktop
            likesCount={likes}
            isLiked={user_interaction.some(
              (interaction) =>
                interaction.type === IHealthHubInteractionType.LIKE
            )}
            isFavourited={user_interaction.some(
              (interaction) =>
                interaction.type === IHealthHubInteractionType.FAVOURITE
            )}
            diseaseId={diseaseId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DiseaseContent;
