import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { useIntl } from "react-intl";

import style from "./index.module.css";

import Breadcrumb from "../../../components/Breadcrumb";
import PlayListCard from "./PlayListCard";
import Loader from "../../../components/Loader";
import ContactForm from "../../../components/ContactForm";
import NextVideoCard from "../VideoDetails/LongVideoMobileView/NextVideoCard";
import SectionHeader from "../HealthHub/SectionHeader";

import {
  IHealthHubPlaylistVideo,
  IHealthHubResult,
  IHealthHubResultType,
} from "../../../models/ApiRequestResponse/patient";

import { useAppSelector } from "../../../store/hooks";

import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";

const PlaylistPage: React.FC = () => {
  const { data, isLoading } = useAppSelector((state) => state.healthHub);
  const intl = useIntl();
  const { state } = useLocation();

  const [playList, setPlayList] = useState<IHealthHubResult>();

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const isWebScreen = useMemo(() => {
    return !(isMobile || isTablet);
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (state?.id && data.result) {
      getPlayList();
    }
  }, [data, state?.id, isLoading]);

  const getPlayList = () => {
    const playlistData = data.result.find(
      (p) => p.type === IHealthHubResultType.PLAYLIST && p.id === state.id
    );
    if (playlistData) {
      setPlayList(playlistData);
    }
  };

  if (isLoading || !playList) {
    return <Loader />;
  }

  return (
    <Grid className={style.wrapperContainer}>
      <Grid className={style.outerContainer}>
        <Grid className={style.headerContainer}>
          <Breadcrumb
            items={[
              { label: "Health Hub", link: "/health-hub" },
              {
                label: `${playList.name} Playlist`,
                isCurrent: true,
              },
            ]}
          />
        </Grid>
        <Grid className={style.listContainer}>
          <Grid className={style.sectionHeader}>
            <SectionHeader
              title={playList.name}
              subtitle={`${playList.data.length} ${
                playList.data.length === 1
                  ? intl.formatMessage({
                      id: "video",
                      defaultMessage: "Video",
                    })
                  : intl.formatMessage({
                      id: "videos",
                      defaultMessage: "Videos",
                    })
              }`}
            />
          </Grid>
          <Grid className={style.blogContainer}>
            {playList?.data &&
              playList.data.map((v) =>
                !isWebScreen ? (
                  <NextVideoCard
                    key={v.id}
                    videoData={v as IHealthHubPlaylistVideo}
                  />
                ) : (
                  <PlayListCard
                    key={v.id}
                    video={v as IHealthHubPlaylistVideo}
                  />
                )
              )}
          </Grid>
        </Grid>
      </Grid>
      <Grid className={style.contactFormContainer}>
        <ContactForm />
      </Grid>
    </Grid>
  );
};

export default PlaylistPage;
