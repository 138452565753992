import { IStatusCodeMessage } from ".";
import { IModalKeyEnums, IRolePermissionAccess } from "./permission";

export interface IMobileVerifyOrSendOTPReq {
  phone_number: string;
  country_code: string;
  device_id: string;
  device_token: string;
}

export interface IMobileVerifyOrSend {
  OtpId: string;
}

export interface IMobileVerifyOrSendOTPInfo {
  result: IMobileVerifyOrSend;
}

export type IMobileVerifyOrSendOTPRes = IStatusCodeMessage &
  IMobileVerifyOrSendOTPInfo;

export interface IOTPVerifyReq {
  otp_code: string;
  token: string;
  device_id: string;
  device_token: string;
}
export interface IFeature {
  featureKey: string;
  permissionAccess: IRolePermissionAccess;
}

export interface IPermission {
  moduleKey: IModalKeyEnums;
  features: IFeature[];
}

export interface IOTPVerifyInfo {
  token: string;
  user_id: string;
  role: string;
  language: string;
  name: string;
  clinic_id: string;
  doctor_id: string;
  doctor_name: string;
  permissions: IPermission[];
  phone_number: string;
}
export interface IOTPVerifyDetail {
  result: IOTPVerifyInfo;
}

export type IOTPVerifyRes = IStatusCodeMessage & IOTPVerifyDetail;

export interface IMobileVerify {
  mobileNo: string;
}

export interface IOtpVerify {
  otp: string;
}

export interface IAccessTokenDetail {
  country_code: string;
  phone_number: string;
  user_id: string;
  role: string;
  language: string;
  permissions: IPermission[];
}
export interface IAccessTokenResult {
  result: IAccessTokenDetail;
}

export type IAccessToken = IStatusCodeMessage & IAccessTokenResult;

export enum ILanguageEnums {
  ENG = "eng",
  HIN = "hin",
  GUJ = "guj",
  BEN = "ben",
}

export interface IGuestUserLogin {
  token: string;
  role: string;
}

export interface IGuestUserLoginResult {
  result: IGuestUserLogin;
}
export type IGuestUserLoginResponse = IStatusCodeMessage &
  IGuestUserLoginResult;
