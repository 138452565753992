import { Grid } from "@mui/material";
import Breadcrumb from "../../../components/Breadcrumb";

import style from "./index.module.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import {
  IHealthHubData,
  IHealthHubDisease,
  IHealthHubDiseasesResult,
  IHealthHubResultType,
} from "../../../models/ApiRequestResponse/patient";
import DiseaseContent from "./DiseaseContent";
import DiseaseListSection from "../HealthHub/DiseaseListSection";
import Loader from "../../../components/Loader";

export const getCurrentDiseaselist = (
  diseaseId: string,
  data: IHealthHubData
) => {
  const diseases = data.result.find(
    (i): i is IHealthHubDiseasesResult =>
      i.type === IHealthHubResultType.TREATMENT
  );

  if (!diseases) return;

  const disease = (diseases.data as IHealthHubDisease[]).find(
    (b) => b.id === diseaseId
  );

  if (!disease) return;

  const relatedDiseases = (diseases.data as IHealthHubDisease[]).filter(
    (b) => b.id !== diseaseId
  );

  return {
    id: diseases.id,
    name: diseases.name,
    type: diseases.type,
    data: relatedDiseases,
  };
};

const DiseaseDetails = () => {
  const { data } = useAppSelector((state) => state.healthHub);
  const [diseaseData, setDiseaseData] = useState<IHealthHubDisease | null>(
    null
  );
  const [currentDiseaselist, setCurrentDiseaselist] = useState<
    ReturnType<typeof getCurrentDiseaselist> | undefined
  >(undefined);

  const { state, search } = useLocation();

  const id = search.split("=")[1];

  useEffect(() => {
    if ((state?.diseaseId || id) && data.result) {
      findDiseaseData();
    }
  }, [state?.diseaseId, data, id]);

  useEffect(() => {
    if (diseaseData) {
      setCurrentDiseaselist(getCurrentDiseaselist(diseaseData.id, data));
    }
  }, [diseaseData]);

  const findDiseaseData = () => {
    const diseases = data.result.find(
      (b) => b.type === IHealthHubResultType.TREATMENT
    );

    if (!diseases) return;

    const disease = (diseases.data as IHealthHubDisease[]).find(
      (b) => b.id === (state?.diseaseId || id)
    );

    if (disease) {
      setDiseaseData(disease);
    }
  };

  if (!diseaseData) {
    return <Loader />;
  }

  return (
    <Grid>
      <Grid
        className={style.outerContainer}
        sx={{
          padding: {
            xs: "0",
            sm: "2rem 3rem 4rem 3rem",
            md: "2.8rem 9.2rem 6rem 9.2rem",
            lg: "3.2rem 11.2rem 6.4rem 11.2rem",
          },
          minHeight: { xs: "100vh", sm: "auto" },
          backgroundColor: { xs: "var(--neutral-100)", sm: "transparent" },
        }}
      >
        <Grid className={style.innerContainer}>
          <Grid
            sx={{ display: { xs: "none", sm: "block" } }}
            className={style.breadcrumbContainer}
          >
            <Breadcrumb
              items={[
                { label: "Health Hub", link: "/health-hub" },
                {
                  label: diseaseData?.name || "",
                  link: "/health-hub/disease",
                  isCurrent: true,
                },
              ]}
            />
          </Grid>
          <DiseaseContent
            user_interaction={diseaseData?.content.user_interaction || []}
            likes={diseaseData?.content?.likes || 0}
            diseaseId={diseaseData?.content.id || ""}
            content={diseaseData?.content?.content || ""}
            diseaseTitle={diseaseData?.content.title || ""}
            diseaseDescription={diseaseData?.content.description || ""}
            diseaseImage={diseaseData?.content.thumbnail || ""}
          />
        </Grid>
      </Grid>
      <Grid
        sx={{ display: { xs: "none", sm: "block" } }}
        className={style.relatedBlogsContainer}
      >
        {currentDiseaselist && <DiseaseListSection data={currentDiseaselist} />}
      </Grid>
    </Grid>
  );
};

export default DiseaseDetails;
