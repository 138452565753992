import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Dialog as MUIDialog,
  Stack,
  Box,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import Modal from "../Modal";
import DateSelectionTab from "../DateSelectionTab";
import GetImages from "../GetImages";
import Button from "../Button";
import ChipSelect from "../ChipSelect";
import {
  AppointmentTab,
  AppointmentTabsPanel,
  AppointmentTabPanel,
} from "../AppointmentTab";
import Checkout from "../../views/Patient/Checkout";
import Loader from "../Loader";

import {
  IAppointmetEnums,
  IDoctorAvailabilityPlatform,
  IMakeAppointmentEvent,
  IPatientAppointmentStatus,
} from "../../models/doctor";
import {
  IClinicAppointment,
  IVideoAppointment,
} from "../../models/ApiRequestResponse/doctor";
import { IRoleType } from "../../models/role";
import { IFilterType } from "../../models/patient";
import {
  IRolePermissionAccess,
  IScheduleSlotsPermissionEnums,
  IModalKeyEnums,
} from "../../models/permission";
import { IButtonVariant } from "../../models/button";

import { storage } from "../../utils/Storage";
import {
  getNextSixDates,
  getPermissionAccess,
  openClinicMapLocation,
  timeInAmPmFormat,
} from "../../utils";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useIsTablet from "../../utils/hooks/useIsTablet";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  appointmentDetails,
  fetchScheduleSlots,
  makePatientAppointment,
} from "../../store/slices/doctor/appointment";
import { checkoutDetail } from "../../store/slices/patient/findDoctor";
import { setShowPermissionAlert } from "../../store/slices/permission";

interface IProps {
  doctorId?: string;
  closeScheduleModal: () => void;
  openScheduleModalHandler: (type: IAppointmetEnums) => void;
  isOpen: boolean;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  platform?: IDoctorAvailabilityPlatform;
  isReschedule?: boolean;
}

const MakeAppointment: React.FC<IProps> = (props) => {
  const {
    doctorId,
    closeScheduleModal,
    openScheduleModalHandler,
    isOpen,
    selectedTab,
    setSelectedTab,
    platform = IDoctorAvailabilityPlatform.BOTH,
    isReschedule = false,
  } = props;

  const [selectedDateTab, setSelectedDateTab] = useState<number>(0);
  const [appointmentType, setAppointmentType] =
    useState<IDoctorAvailabilityPlatform>();
  const [nextSixDays, setNextSixDays] = useState<
    { date: string; day: string }[]
  >([]);
  const [slotsDetail, setSlotsDetail] = useState<{
    videoAppointment: IVideoAppointment[];
    clinicAppointment: IClinicAppointment[];
  }>({ videoAppointment: [], clinicAppointment: [] });
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] =
    useState<string>("");
  const [isCheckoutShowDesktop, setIsCheckoutShowDesktop] =
    useState<boolean>(false);

  const roleType = storage.getSessionToken("roleType");
  const isPatient = roleType === IRoleType.PATIENT;
  const isMobileCheck = !isPatient && !isReschedule;
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const dispatch = useAppDispatch();
  const checkoutDetailInfo = useAppSelector(
    (state) => state.findDoctor.checkoutPatientDetail
  );
  const makePatientAppointmentInfo = useAppSelector(
    (state) => state.appointment.makePatientAppointment
  );
  const scheduleSlots = useAppSelector((state) => state.appointment);
  const { permission } = useAppSelector((state) => state.rolePermission);
  const appointmentDetail = useAppSelector(appointmentDetails);

  const filterScheduleSlotsByDate = (type: IDoctorAvailabilityPlatform) => {
    if (Object.keys(scheduleSlots.slot).length === 0) return [];

    const filteredSlotsDateWise: any = scheduleSlots?.slot[
      type === IDoctorAvailabilityPlatform.VIDEO_CALL
        ? "videoAppointment"
        : "clinicAppointment"
    ].filter((slots: any, index: number) => {
      const [month, date, _] = slots.date.split("-");
      const dateMonth = dayjs(`${date}-${month}`, "MM-DD").format("DD MMM");
      if (dateMonth === nextSixDays[selectedDateTab]?.date) {
        return slots;
      }
    });
    setSlotsDetail({
      ...slotsDetail,
      ...(type === IDoctorAvailabilityPlatform.VIDEO_CALL
        ? { videoAppointment: filteredSlotsDateWise }
        : { clinicAppointment: filteredSlotsDateWise }),
    });
  };

  const dateSelectionHandler = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setSelectedDateTab(newValue);
    },
    [appointmentType, selectedDateTab]
  );

  const handleAppointmentTab = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setAppointmentType(
      newValue === IMakeAppointmentEvent.VIDEO_CALL
        ? IDoctorAvailabilityPlatform.VIDEO_CALL
        : IDoctorAvailabilityPlatform.IN_CLINIC
    );
    setSelectedTab(newValue);
  };

  const getAppointmentTimeFromSlots = (
    slotId: string,
    type: IDoctorAvailabilityPlatform
  ) => {
    const slotDetail = slotsDetail[
      type === IDoctorAvailabilityPlatform.VIDEO_CALL
        ? "videoAppointment"
        : "clinicAppointment"
    ][0]?.slots?.filter((info, index) => info.slotId === slotId)[0];
    return `${timeInAmPmFormat(
      slotDetail?.slotStartTime ?? ""
    )} - ${timeInAmPmFormat(slotDetail?.slotEndTime ?? "")}, ${
      nextSixDays[selectedDateTab]?.date ?? ""
    }`;
  };

  const dispatchActionSlotSelection = () => {
    let dateSlotTime, slotInfo: any;
    dateSlotTime = getAppointmentTimeFromSlots(
      selectedAppointmentSlot,
      selectedTab === IMakeAppointmentEvent.VIDEO_CALL
        ? IDoctorAvailabilityPlatform.VIDEO_CALL
        : IDoctorAvailabilityPlatform.IN_CLINIC
    );
    slotInfo = slotsDetail[
      selectedTab === IMakeAppointmentEvent.VIDEO_CALL
        ? "videoAppointment"
        : "clinicAppointment"
    ][0]?.slots?.filter(
      (slot, index) => slot.slotId === selectedAppointmentSlot
    )[0];

    if (roleType === IRoleType.PATIENT) {
      dispatch(
        checkoutDetail({
          ...checkoutDetailInfo,
          dateSlotTime,
          type:
            selectedTab === IMakeAppointmentEvent.VIDEO_CALL
              ? IDoctorAvailabilityPlatform.VIDEO_CALL
              : IDoctorAvailabilityPlatform.IN_CLINIC,
          slotId: selectedAppointmentSlot,
          ...(selectedTab === IMakeAppointmentEvent.IN_CLINIC && {
            clinicId: slotInfo?.clinicId,
            clinicName: `${slotInfo?.clinicName},${slotInfo?.clinicAddress}`,
          }),
          fee: slotInfo?.consultationFee ?? 0,
        })
      );
    } else {
      dispatch(
        makePatientAppointment({
          ...makePatientAppointmentInfo,
          dateSlotTime,
          type:
            selectedTab === IMakeAppointmentEvent.VIDEO_CALL
              ? IDoctorAvailabilityPlatform.VIDEO_CALL
              : IDoctorAvailabilityPlatform.IN_CLINIC,
          slotId: selectedAppointmentSlot,
          ...(selectedTab === IMakeAppointmentEvent.IN_CLINIC && {
            clinicId: slotInfo?.clinicId,
            clinicName: `${slotInfo?.clinicName},${slotInfo?.clinicAddress}`,
          }),
          fee: slotInfo?.consultationFee ?? 0,
        })
      );
    }
  };

  const MakeAppointmentScreen = () => (
    <Grid container className={style.container}>
      <Grid item xs={12}>
        <AppointmentTab value={selectedTab} index={0}>
          <Typography component={"p"} className={style.scheduleTitle}>
            <FormattedMessage
              id={isReschedule ? "select_slot_reschedule" : "book_appointment"}
              defaultMessage={
                isReschedule
                  ? "Select Slot to Reschedule"
                  : "Book an appointment"
              }
            />
          </Typography>
        </AppointmentTab>
      </Grid>
      <Grid item xs={12}>
        <AppointmentTab value={selectedTab} index={1}>
          <Typography component={"p"} className={style.scheduleTitle}>
            <FormattedMessage
              id={isReschedule ? "select_slot_reschedule" : "make_appointment"}
              defaultMessage={
                isReschedule
                  ? "Select Slot to Reschedule"
                  : "Make an appointment"
              }
            />
          </Typography>
        </AppointmentTab>
      </Grid>
      <Grid item xs={12}>
        {platform === IDoctorAvailabilityPlatform.BOTH && (
          <AppointmentTabsPanel
            value={selectedTab}
            onChange={handleAppointmentTab}
            aria-label="basic tabs example"
            className={style.tabBtnContainer}
          >
            <AppointmentTabPanel
              label={
                <Grid item className={style.tabInnerContainer}>
                  <GetImages
                    name={selectedTab ? "VideoIcon" : "VideoWhiteIcon"}
                    width="20"
                    height="20"
                  />
                  <Typography component={"p"}>
                    <FormattedMessage
                      id="video_call"
                      defaultMessage="Video Call"
                    />
                  </Typography>
                </Grid>
              }
            />
            <AppointmentTabPanel
              label={
                <Grid item className={style.tabInnerContainer}>
                  <GetImages
                    name={selectedTab ? "ClinicWhiteIcon" : "ClinicIcon"}
                    width="20"
                    height="20"
                  />
                  <Typography component={"p"}>
                    <FormattedMessage
                      id="in_clinic"
                      defaultMessage="In-Clinic"
                    />
                  </Typography>
                </Grid>
              }
            />
          </AppointmentTabsPanel>
        )}
        <AppointmentTab value={selectedTab} index={0}>
          <Grid item className={style.dateSelectionContainer}>
            <DateSelectionTab
              data={nextSixDays}
              selectedDateTab={selectedDateTab}
              dateSelectionHandler={dateSelectionHandler}
            />
          </Grid>
          {slotsDetail.videoAppointment.length > 0 ? (
            <Grid item className={style.videoCallAppointInnerContainer}>
              <Typography component={"p"} className={style.slotFeeText}>
                <FormattedMessage id="slot_fee" defaultMessage="Slot fee" />
                <Typography component={"span"} className={style.slotPrice}>
                  ₹{slotsDetail.videoAppointment[0]?.slots[0]?.consultationFee}
                </Typography>
              </Typography>
              <Grid item className={style.slotsContainer}>
                <ChipSelect
                  data={slotsDetail.videoAppointment[0].slots.map(
                    (slot, index) => ({
                      label: timeInAmPmFormat(slot.slotStartTime),
                      value: slot.slotId,
                      isBooked: slot.isBooked,
                    })
                  )}
                  selectedValue={selectedAppointmentSlot}
                  setSelectedValue={setSelectedAppointmentSlot}
                  showSlots={true}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography component={"h1"} align="center" padding={"4rem 0rem"}>
              <FormattedMessage
                id="no_slot_available"
                defaultMessage="No Slot Available"
              />
            </Typography>
          )}
        </AppointmentTab>
        <AppointmentTab value={selectedTab} index={1}>
          <Grid item className={style.dateSelectionContainer}>
            <DateSelectionTab
              data={nextSixDays}
              selectedDateTab={selectedDateTab}
              dateSelectionHandler={dateSelectionHandler}
            />
          </Grid>
          {slotsDetail?.clinicAppointment.length > 0 ? (
            <Grid item className={style.inClinicContainer}>
              {slotsDetail?.clinicAppointment[0].slots.map((info, index) => (
                <Grid
                  item
                  key={`${info.clinicName}-${index}`}
                  className={style.clinicInfoWrapper}
                >
                  <Grid item className={style.clinicDetailContainer}>
                    <Grid item>
                      <Typography component={"h6"} className={style.clinicName}>
                        {info.clinicName}
                      </Typography>
                      <Typography component={"p"} className={style.priceBranch}>
                        ₹{info.consultationFee} • {info.clinicAddress}
                      </Typography>
                    </Grid>
                    {roleType === IRoleType.PATIENT && (
                      <Grid
                        item
                        className={style.directionIcon}
                        onClick={() =>
                          openClinicMapLocation(info.latitude, info.longitude)
                        }
                      >
                        <GetImages
                          name="LocationAccessSmallIcon"
                          width="20"
                          height="20"
                        />
                        <Typography component={"p"} className={style.mapText}>
                          <FormattedMessage id="maps" defaultMessage="Maps" />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <ChipSelect
                    data={[
                      {
                        label: `${timeInAmPmFormat(
                          info.slotStartTime
                        )} - ${timeInAmPmFormat(info.slotEndTime)}`,
                        value: info.slotId,
                        isBooked: info.isBooked,
                      },
                    ]}
                    selectedValue={selectedAppointmentSlot}
                    setSelectedValue={setSelectedAppointmentSlot}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography component={"h1"} align="center" padding={"4rem 0rem"}>
              <FormattedMessage
                id="no_slot_available"
                defaultMessage="No Slot Available"
              />
            </Typography>
          )}
        </AppointmentTab>
      </Grid>
      <Grid item xs={12} className={style.actionBtnsContainer}>
        <Button
          disabled={!selectedAppointmentSlot}
          btnTrigger={
            isPatient
              ? () => setIsCheckoutShowDesktop(!isCheckoutShowDesktop)
              : isMobileCheck
              ? () => openScheduleModalHandler(IAppointmetEnums.MOBILE_CHECK)
              : isReschedule
              ? () => openScheduleModalHandler(IAppointmetEnums.SUCCESS)
              : undefined
          }
        >
          {isPatient ? (
            <>
              <GetImages name="ChekoutBtnIcon" width="28" height="20" />
              <FormattedMessage
                id="goto_checkout"
                defaultMessage="Go to Checkout"
              />
            </>
          ) : isReschedule ? (
            <FormattedMessage id="reserve" defaultMessage="Reserve" />
          ) : (
            <FormattedMessage id="next" defaultMessage="Next" />
          )}
        </Button>
      </Grid>
    </Grid>
  );

  const MakeAppointmentScreenWeb = () => (
    <MUIDialog
      open={isOpen}
      onClose={closeScheduleModal}
      maxWidth="md"
      fullWidth
    >
      {scheduleSlots.isLoading ? (
        <Loader />
      ) : (
        <Stack justifyContent={"center"} alignItems={"center"} p={10}>
          <Typography variant="h5" fontWeight={500}>
            <FormattedMessage
              id={isReschedule ? "select_slot_reschedule" : "book_appointment"}
              defaultMessage={
                isReschedule
                  ? "Select Slot to Reschedule"
                  : "Book an appointment"
              }
            />
          </Typography>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
            py={2}
            px={1}
          >
            {platform === IDoctorAvailabilityPlatform.BOTH && (
              <Grid item xs={8}>
                <AppointmentTabsPanel
                  value={selectedTab}
                  onChange={handleAppointmentTab}
                  aria-label="basic tabs example"
                  className={style.tabBtnContainer}
                >
                  <AppointmentTabPanel
                    label={
                      <Grid item className={style.tabInnerContainer}>
                        <GetImages
                          name={selectedTab ? "VideoIcon" : "VideoWhiteIcon"}
                          width="20"
                          height="20"
                        />
                        <Typography component={"p"}>
                          <FormattedMessage
                            id="video_call"
                            defaultMessage="Video Call"
                          />
                        </Typography>
                      </Grid>
                    }
                  />
                  <AppointmentTabPanel
                    label={
                      <Grid item className={style.tabInnerContainer}>
                        <GetImages
                          name={selectedTab ? "ClinicWhiteIcon" : "ClinicIcon"}
                          width="20"
                          height="20"
                        />
                        <Typography component={"p"}>
                          <FormattedMessage
                            id="in_clinic"
                            defaultMessage="In-Clinic"
                          />
                        </Typography>
                      </Grid>
                    }
                  />
                </AppointmentTabsPanel>
              </Grid>
            )}
            <Grid item xs={12} bgcolor={"white"} borderRadius={4}>
              <DateSelectionTab
                data={nextSixDays}
                selectedDateTab={selectedDateTab}
                dateSelectionHandler={dateSelectionHandler}
              />
              <AppointmentTab value={selectedTab} index={0}>
                <Typography
                  textAlign={"center"}
                  component={"p"}
                  className={style.slotFeeText}
                >
                  <FormattedMessage id="slot_fee" defaultMessage="Slot fee" />
                  <Typography component={"span"} className={style.slotPrice}>
                    ₹
                    {slotsDetail.videoAppointment[0]?.slots[0]?.consultationFee}
                  </Typography>
                </Typography>
                <Box
                  maxHeight={"40vh"}
                  px={4}
                  pb={2}
                  sx={{ overflowY: "scroll" }}
                >
                  <ChipSelect
                    data={slotsDetail?.videoAppointment[0]?.slots.map(
                      (slot, index) => ({
                        label: timeInAmPmFormat(slot.slotStartTime),
                        value: slot.slotId,
                        isBooked: slot.isBooked,
                      })
                    )}
                    selectedValue={selectedAppointmentSlot}
                    setSelectedValue={setSelectedAppointmentSlot}
                    showSlots={true}
                  />
                </Box>
              </AppointmentTab>
              <AppointmentTab value={selectedTab} index={1}>
                {slotsDetail?.clinicAppointment.length > 0 ? (
                  <Grid item className={style.inClinicContainer}>
                    {slotsDetail?.clinicAppointment[0].slots.map(
                      (info, index) => (
                        <Grid
                          item
                          key={`${info.clinicName}-${index}`}
                          className={style.clinicInfoWrapper}
                        >
                          <Grid item className={style.clinicDetailContainer}>
                            <Grid item>
                              <Typography
                                component={"h6"}
                                className={style.clinicName}
                              >
                                {info.clinicName}
                              </Typography>
                              <Typography
                                component={"p"}
                                className={style.priceBranch}
                              >
                                ₹{info.consultationFee} • {info.clinicAddress}
                              </Typography>
                            </Grid>
                            {roleType === IRoleType.PATIENT && (
                              <Grid
                                item
                                className={style.directionIcon}
                                onClick={() =>
                                  openClinicMapLocation(
                                    info.latitude,
                                    info.longitude
                                  )
                                }
                              >
                                <GetImages
                                  name="LocationAccessSmallIcon"
                                  width="20"
                                  height="20"
                                />
                                <Typography
                                  component={"p"}
                                  className={style.mapText}
                                >
                                  <FormattedMessage
                                    id="maps"
                                    defaultMessage="Maps"
                                  />
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                          <ChipSelect
                            data={[
                              {
                                label: `${timeInAmPmFormat(
                                  info.slotStartTime
                                )} - ${timeInAmPmFormat(info.slotEndTime)}`,
                                value: info.slotId,
                                isBooked: info.isBooked,
                              },
                            ]}
                            selectedValue={selectedAppointmentSlot}
                            setSelectedValue={setSelectedAppointmentSlot}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                ) : (
                  <Typography
                    component={"h1"}
                    align="center"
                    padding={"4rem 0rem"}
                  >
                    <FormattedMessage
                      id="no_slot_available"
                      defaultMessage="No Slot Available"
                    />
                  </Typography>
                )}
              </AppointmentTab>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"} gap={4}>
                <Button
                  btnTrigger={closeScheduleModal}
                  variant={IButtonVariant.WHITE}
                >
                  <FormattedMessage id="cancel" defaultMessage={"Cancel"} />
                </Button>
                <Button
                  disabled={!selectedAppointmentSlot}
                  btnTrigger={
                    isPatient
                      ? () => setIsCheckoutShowDesktop(!isCheckoutShowDesktop)
                      : isMobileCheck
                      ? () =>
                          openScheduleModalHandler(
                            IAppointmetEnums.MOBILE_CHECK
                          )
                      : isReschedule
                      ? () => openScheduleModalHandler(IAppointmetEnums.SUCCESS)
                      : undefined
                  }
                >
                  {isPatient ? (
                    <>
                      <GetImages name="ChekoutBtnIcon" width="28" height="20" />
                      <FormattedMessage
                        id="goto_checkout"
                        defaultMessage="Go to Checkout"
                      />
                    </>
                  ) : isReschedule ? (
                    <FormattedMessage id="reserve" defaultMessage="Reserve" />
                  ) : (
                    <FormattedMessage id="next" defaultMessage="Next" />
                  )}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}
    </MUIDialog>
  );

  useEffect(() => {
    if (
      roleType === IRoleType.DOCTOR ||
      roleType === IRoleType.JR_DOCTOR ||
      roleType === IRoleType.PHARMACY
    ) {
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.SCHEDULE_SLOTS,
        IScheduleSlotsPermissionEnums.VIEW_SCHEDULE_SLOT_BY_DOCTOR,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      if (appointmentDetail?.status === IPatientAppointmentStatus.CANCELLED) {
        dispatch(
          fetchScheduleSlots({ clinicId: appointmentDetail?.clinic_id! })
        );
      } else {
        dispatch(fetchScheduleSlots({}));
      }
    } else if (roleType === IRoleType.PATIENT) {
      dispatch(fetchScheduleSlots({ doctorId }));
    }
    setNextSixDays(getNextSixDates(6));
  }, []);

  useEffect(() => {
    if (
      appointmentType ||
      checkoutDetailInfo?.type ||
      makePatientAppointmentInfo?.type
    )
      filterScheduleSlotsByDate(
        appointmentType ||
          checkoutDetailInfo?.type ||
          makePatientAppointmentInfo?.type
      );
  }, [
    appointmentType,
    selectedTab,
    selectedDateTab,
    scheduleSlots,
    nextSixDays,
  ]);

  useEffect(() => {
    setSelectedDateTab(IMakeAppointmentEvent.VIDEO_CALL);
  }, [selectedTab]);

  useEffect(() => {
    dispatchActionSlotSelection();
  }, [selectedAppointmentSlot, selectedDateTab, selectedTab]);

  return (
    <>
      {isMobile || isTablet ? (
        <Modal isOpen={isOpen} closeHandler={closeScheduleModal}>
          {MakeAppointmentScreen()}
        </Modal>
      ) : (
        MakeAppointmentScreenWeb()
      )}
      {isCheckoutShowDesktop && (
        <Checkout
          isOpen={isCheckoutShowDesktop}
          closeHandler={() => setIsCheckoutShowDesktop(!isCheckoutShowDesktop)}
          type={
            selectedTab === 0 ? IFilterType.VIDEO_CALL : IFilterType.IN_CLINIC
          }
        />
      )}
    </>
  );
};

export default MakeAppointment;
