import React, { useState } from "react";
import { DateRangePicker as ReactDateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

import Button from "../Button";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { INotifyEnum, notify } from "../../utils/toaster";
import { convertDateYYYYMMDD } from "../../utils";

import { IButtonVariant } from "../../models/button";

interface IProps {
  submitDateRangeValues: (startDate: string, endDate: string) => void;
  daySelected?: number;
  startDate?: string;
  endDate?: string;
}
const DateRangePicker: React.FC<IProps> = (props) => {
  const { submitDateRangeValues, daySelected = 7, startDate, endDate } = props;

  const [dateSelection, setDateSelection] = useState<any>({
    startDate: startDate ? new Date(startDate) : new Date(),
    endDate: endDate ? new Date(endDate) : addDays(new Date(), daySelected),
    key: "selection",
  });

  const isFutureDate = dayjs(dateSelection?.endDate).isAfter(dayjs(), "day");

  const intl = useIntl();

  const handleSelect = (ranges: any) => {
    const { selection } = ranges;
    const startDate = selection.startDate;
    const endDate = selection.endDate;

    if (endDate && (endDate - startDate) / (1000 * 60 * 60 * 24) > 7) {
      selection.endDate = new Date(
        startDate?.getTime() + 7 * 24 * 60 * 60 * 1000
      );
      notify(
        INotifyEnum.ERROR,
        "The date range between start and end must not exceed 7 days"
      );
    }
    setDateSelection(selection);
  };

  const submitDateRange = () => {
    const { startDate, endDate } = dateSelection;
    if (startDate && endDate) {
      submitDateRangeValues(
        convertDateYYYYMMDD(startDate),
        convertDateYYYYMMDD(endDate)
      );
    }
  };

  const handleClear = () => {
    setDateSelection({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
  };

  return (
    <Grid item className={"dateRangePickerContainer"}>
      <ReactDateRangePicker
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={[dateSelection]}
        staticRanges={[]}
        inputRanges={[]}
        direction="vertical"
      />
      <Grid item className="applyBtn">
        {isFutureDate && (
          <Grid item className={"clearBtn"}>
            <Button variant={IButtonVariant.WHITE} btnTrigger={handleClear}>
              {intl.formatMessage({
                id: "clear",
                defaultMessage: "Clear",
              })}
            </Button>
          </Grid>
        )}
        <Button btnTrigger={submitDateRange}>
          {intl.formatMessage({
            id: "apply",
            defaultMessage: "Apply",
          })}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateRangePicker;
