import React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import Modal from "../../Modal";
import Button from "../../Button";
import Section from "../Section";
import ClinicTeamsCard from "../../AddClinicTeamPatientCard";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setEditAdmitPatient } from "../../../store/slices/doctor/ipDashboard";

import {
  calculateDayFromTargetDate,
  getDay,
  transformDateToDDMMYYYY,
} from "../../../utils";

import { IAddClinicTeamMemberEnums } from "../../../models";

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
}
const DischargedPatientDetail: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler } = props;

  const navigate = useNavigate();

  const { patientDetail } = useAppSelector((state) => state.ipDashboard);
  const {
    id,
    name,
    case_history,
    discharged_date,
    sponsor_name,
    admission_date,
  } = patientDetail;
  const dispatch = useAppDispatch();

  const reAdmitPatientHandler = async () => {
    dispatch(setEditAdmitPatient(true));
    navigate("/admit-patient");
    closeHandler();
  };
  return (
    <Modal
      isOpen={isOpen}
      closeHandler={closeHandler}
      isCloseIcon
      backgroundColor="white"
      lineBottomBackground="white"
    >
      <Grid item className={style.container}>
        <Grid item className={style.patientDetail}>
          <Typography component={"p"}>{name}</Typography>
          <Typography component={"p"} className={style.admittedDateDuration}>
            {getDay(admission_date)} -{" "}
            {transformDateToDDMMYYYY(discharged_date)}
            {`(${calculateDayFromTargetDate(admission_date, discharged_date)})`}
          </Typography>
        </Grid>
        <Grid item className={style.caseHistory}>
          <Typography component={"p"}>{case_history}</Typography>
        </Grid>
        <Grid item className={style.dischargedDate}>
          <Typography component={"p"}>
            Discharged on {transformDateToDDMMYYYY(discharged_date)}
          </Typography>
        </Grid>
        <Grid item className={style.sponsors}>
          <Typography component={"p"}>{sponsor_name}</Typography>
        </Grid>
        <Grid item className={style.clinicTeams}>
          <Grid item className={style.clinicTeamTitle}>
            <Section title="clinical_team" />
          </Grid>
          <ClinicTeamsCard type={IAddClinicTeamMemberEnums.STATIC} />
        </Grid>
        <Button btnTrigger={reAdmitPatientHandler}>
          Mark Patient as Active
        </Button>
      </Grid>
    </Modal>
  );
};

export default DischargedPatientDetail;
