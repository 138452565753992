import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getConfigDoctorPatientApi } from "../../utils/apis/config";

import { IConfigDetail } from "../../models/ApiRequestResponse/doctor";
import { IFilterType } from "../../models/patient";

export const fetchConfiguration = createAsyncThunk(
  "doctor-patient/configuration",
  async (filterKey?: IFilterType) => {
    const response = await getConfigDoctorPatientApi(filterKey);
    return response;
  }
);

const configurationSlice = createSlice({
  name: "config",
  initialState: {
    config: {} as IConfigDetail,
    isShowUniversalSearch: false as boolean,
    isSettingPreferencesOpenClose: false as boolean,
    isRequestCallBackOpen: false as boolean,
    isLoading: true as boolean,
  },
  reducers: {
    setIsShowUniversalSearch: (state, action) => {
      state.isShowUniversalSearch = action.payload;
    },
    setIsSettingPreferencesOpenClose: (state) => {
      state.isSettingPreferencesOpenClose =
        !state.isSettingPreferencesOpenClose;
    },
    setIsRequestCallBackOpen: (state) => {
      state.isRequestCallBackOpen = !state.isRequestCallBackOpen;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfiguration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchConfiguration.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.config = { ...state.config, ...action.payload.result };
        }
      })
      .addCase(fetchConfiguration.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const {
  setIsShowUniversalSearch,
  setIsSettingPreferencesOpenClose,
  setIsRequestCallBackOpen,
} = configurationSlice.actions;
export default configurationSlice.reducer;
