import { IStatusCodeMessage } from "..";

export enum IPharmacyDiagnosticOrderFilterEnum {
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  ACCEPTED = "CONFIRMED",
  HOME_DELIVERY = "HOME_DELIVERY",
  PICKUP = "PICKUP",
  DATE = "DATE",
  UPLOADED = "UPLOADED",
  CONFIRMED = "CONFIRMED",
}

export enum IOrderStatusEnum {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  UPLOADED = "UPLOADED",
}

export interface ISalesCount {
  status: IOrderStatusEnum;
  statusCount: number;
}

export interface ISalesDetail {
  salesCount: ISalesCount[];
  salesAmount: number;
}

export interface ISalesInfo {
  result: ISalesDetail;
}

export type IProfileSalesResponse = IStatusCodeMessage & ISalesInfo;

export interface IPharmacySalesExport {
  result: string;
}
export type IPharmacySalesExportResponse = IStatusCodeMessage &
  IPharmacySalesExport;

export interface IPharmacyOrderDetailUpdate {
  deliveryPartner: string;
  trackingId: string;
  totalBill: null | number;
  discount?: null | number;
  totalPayable?: null | number;
}
