import { AsyncThunk } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useAppDispatch } from "../../store/hooks";

interface PaginationParams<TFilters = void> {
  fetchFn: AsyncThunk<any, TFilters, any>; // Function to fetch data
  fetchNextFn: AsyncThunk<any, TFilters, any>;
}

const usePagination = <TFilters extends Record<string, any>>(
  params: PaginationParams<TFilters>
) => {
  const { fetchFn, fetchNextFn } = params;
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState<number>(0);

  const handleFetch = (filters: TFilters) => {
    dispatch(
      fetchFn({
        offset: 0,
        ...filters,
      })
    );
    setOffset(0);
  };

  const handleFetchNext = (filters: TFilters) => {
    const newOffset = offset + 1;
    dispatch(
      fetchNextFn({
        offset: newOffset,
        ...filters,
      })
    );
    setOffset(newOffset);
  };

  const handleResetOffset = () => {
    setOffset(0);
  };

  return {
    handleFetchNext,
    handleFetch,
    handleResetOffset,
    offset,
  };
};

export default usePagination;
